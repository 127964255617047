/*
 * method permettant d'appeler des functions distantes hébergées sur GCP FUNCTION
 */

import { httpsCallable } from 'firebase/functions'
import { functions } from '../..'
import { Agence, Entreprise, Equipement, Utilisateur } from '../../../model'

const function_admin_create_user = httpsCallable(functions, 'adminCreateUser')

export const CallFunctionAdminCreateUser = (newUser: Utilisateur) => {
    return function_admin_create_user(newUser)
}

const function_declaration_kilometrique = httpsCallable(
    functions,
    'declarationKilometrique'
)

export const CallFunctionDeclarationKilometrique = (
    equipementId: string,
    kilometrage: number
) => {
    return function_declaration_kilometrique({
        equipementId: equipementId,
        kilometrage: kilometrage,
    })
}

const function_declaration_maintenance = httpsCallable(
    functions,
    'declarationMaintenance'
)

export const CallFunctionDeclarationMaintenance = (
    equipementId: string,
    date: number,
    message: string,
    lieu: string
) => {
    return function_declaration_maintenance({
        equipementId: equipementId,
        date: date,
        message: message,
        lieu: lieu,
    })
}

const function_admin_changement_appartenance_equipement = httpsCallable(
    functions,
    'adminChangementAppartenanceEquipement'
)
export const CallFunctionAdminChangementAppartenanceEquipement = (
    equipementId: string,
    agenceId: string,
    entrepriseId: string,
    utilisateurId?: string
) => {
    return function_admin_changement_appartenance_equipement({
        equipementId: equipementId,
        utilisateurId: utilisateurId,
        agenceId: agenceId,
        entrepriseId: entrepriseId,
    })
}

const function_admin_changement_appartenance_utilisateur = httpsCallable(
    functions,
    'adminChangementAppartenanceUtilisateur'
)
export const CallFunctionAdminChangementAppartenanceUtilisateur = (
    utilisateurId: string,
    agenceId: string,
    entrepriseId: string
) => {
    return function_admin_changement_appartenance_utilisateur({
        utilisateurId: utilisateurId,
        agenceId: agenceId,
        entrepriseId: entrepriseId,
    })
}

const function_admin_changement_mail_utilisateur = httpsCallable(
    functions,
    'adminChangementMailUtilisateur'
)
export const CallFunctionAdminChangementMailUtilisateur = (
    utilisateurId: string,
    newMail: string
) => {
    return function_admin_changement_mail_utilisateur({
        utilisateurId: utilisateurId,
        newMail: newMail,
    })
}

const function_admin_delete_utilisateur = httpsCallable(
    functions,
    'adminDeleteUtilisateur'
)
export const CallFunctionAdminDeleteUtilisateur = (utilisateurId: string) => {
    return function_admin_delete_utilisateur({
        utilisateurId: utilisateurId,
    })
}

const function_admin_delete_agence = httpsCallable(
    functions,
    'adminDeleteAgence'
)
export const CallFunctionAdminDeleteAgence = (agenceId: string) => {
    return function_admin_delete_agence({
        agenceId: agenceId,
    })
}

const function_admin_delete_entreprise = httpsCallable(
    functions,
    'adminDeleteEntreprise'
)
export const CallFunctionAdminDeleteEntreprise = (entrepriseId: string) => {
    return function_admin_delete_entreprise({
        entrepriseId: entrepriseId,
    })
}

const function_admin_export_velo_entreprise_data = httpsCallable(
    functions,
    'adminExportVeloEntrepriseData'
)
export const CallFunctionAdminExportVeloEntrepriseData = (
    entrepriseId: string
) => {
    return function_admin_export_velo_entreprise_data({
        entrepriseId: entrepriseId,
    })
}

const function_admin_export_velo_all_entreprises_data = httpsCallable(
    functions,
    'adminExportVeloAllEntreprisesData'
)
export const CallFunctionAdminExportVeloAllEntreprisesData = () => {
    return function_admin_export_velo_all_entreprises_data({})
}

const function_admin_export_all_equipement_data = httpsCallable(
    functions,
    'adminExportAllEquipementData'
)
export const CallFunctionAdminExportAllEquipementData = () => {
    return function_admin_export_all_equipement_data()
}

const function_admin_export_all_users_data = httpsCallable(
    functions,
    'adminExportAllUsersData'
)
export const CallFunctionAdminExportAllUsersData = () => {
    return function_admin_export_all_users_data()
}

const function_message_de_contact = httpsCallable(
    functions,
    'ClientMessageDeContact'
)
export const CallFunctionMessageDeContact = (
    entreprise: Entreprise,
    agence: Agence | undefined,
    equipement: Equipement,
    message: string,
    objet: string
) => {
    return function_message_de_contact({
        entreprise: entreprise,
        agence: agence,
        equipement: equipement,
        message: message,
        objet: objet,
    })
}

const function_enregistrer_calendrier = httpsCallable(
    functions,
    'EnregistrerCalendrier'
)
export const CallFunctionEnregistrerCalendrier = (
    targetUser: string,
    usage: boolean[],
    month: number,
    year: number
) => {
    return function_enregistrer_calendrier({
        targetUser: targetUser,
        usage: usage,
        month: month,
        year: year,
    })
}

const function_aggregate_calendrier_stats = httpsCallable(
    functions,
    'AggregateCalendrierStats'
)
export const CallFunctionAggregateCalendrierStats = () => {
    return function_aggregate_calendrier_stats()
}
