/*
 * bouton ouvrant une modal d'intérraction dédié pour déclarer et resoudre un problème
 */

import {
    Text,
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
    Center,
    Wrap,
    Image,
    Box,
    Spinner,
} from '@chakra-ui/react'
import { Entreprise, Equipement } from '../../../model'
import { CustomBox } from '../../CustomBox'
import { Dispatch, SetStateAction, useState } from 'react'
import { ModalPDF } from '../../ModalPDF'
import { FormulaireContact } from '../../Formulaire/FormulaireContact'
import { CheckIcon } from '@chakra-ui/icons'

interface Props {
    equipement: Equipement
    entreprise: Entreprise
}

export const TrouverUneSolution = ({ equipement, entreprise }: Props) => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [state, setState] = useState<number>(0)
    return (
        <>
            <Button
                onClick={onOpen}
                backgroundColor={'white'}
                border={'solid 1px red'}
            >
                <Text fontSize="xs">Trouver une solution</Text>
            </Button>
            <Modal isOpen={isOpen} onClose={onClose} size={'lg'}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>
                        <Center>Déclarer un problème</Center>
                    </ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        {state === 0 && (
                            <TrouverUneSolutionMain
                                setState={setState}
                                entreprise={entreprise}
                                equipement={equipement}
                            />
                        )}
                        {state === 1 && (
                            <EcranVol
                                setState={setState}
                                entreprise={entreprise}
                                equipement={equipement}
                            />
                        )}
                        {state === 2 && (
                            <EcranCasse
                                setState={setState}
                                entreprise={entreprise}
                                equipement={equipement}
                            />
                        )}
                        {state === 3 && (
                            <EcranAccident
                                setState={setState}
                                entreprise={entreprise}
                                equipement={equipement}
                            />
                        )}
                        {state === 4 && (
                            <EcranAutre
                                setState={setState}
                                entreprise={entreprise}
                                equipement={equipement}
                            />
                        )}
                    </ModalBody>

                    <ModalFooter></ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}

interface PropsEcran {
    setState?: Dispatch<SetStateAction<number>>
    entreprise: Entreprise
    equipement: Equipement
}

const EcranVol = (props: PropsEcran) => {
    return (
        <>
            <Button onClick={(e) => props.setState!(0)}> Retour </Button>
            <Text as="span" ml="10px">
                <b>Déclaration de vol</b>
            </Text>
            {props.entreprise.telAssurance &&
                props.entreprise.telAssurance !== '' && (
                    <BoxNotice
                        entreprise={props.entreprise}
                        equipement={props.equipement}
                    />
                )}
            {(!props.entreprise.telAssurance ||
                props.entreprise.telAssurance === '') && (
                <NoAssurance
                    entreprise={props.entreprise}
                    equipement={props.equipement}
                />
            )}
        </>
    )
}
const EcranCasse = (props: PropsEcran) => {
    return (
        <>
            <Button onClick={(e) => props.setState!(0)}> Retour </Button>
            <Text as="span" ml="10px">
                <b>Déclaration de casse</b>
            </Text>
            {props.entreprise.telAssurance &&
                props.entreprise.telAssurance !== '' && (
                    <BoxNotice
                        entreprise={props.entreprise}
                        equipement={props.equipement}
                    />
                )}
            {(!props.entreprise.telAssurance ||
                props.entreprise.telAssurance === '') && (
                <NoAssurance
                    entreprise={props.entreprise}
                    equipement={props.equipement}
                />
            )}
        </>
    )
}
const EcranAccident = (props: PropsEcran) => {
    return (
        <>
            <Button onClick={(e) => props.setState!(0)}> Retour </Button>
            <Text as="span" ml="10px">
                <b>Déclaration d'accident</b>
            </Text>
            {props.entreprise.telAssurance &&
                props.entreprise.telAssurance !== '' && (
                    <BoxNotice
                        entreprise={props.entreprise}
                        equipement={props.equipement}
                    />
                )}
            {(!props.entreprise.telAssurance ||
                props.entreprise.telAssurance === '') && (
                <NoAssurance
                    entreprise={props.entreprise}
                    equipement={props.equipement}
                />
            )}
        </>
    )
}

const EcranAutre = (props: PropsEcran) => {
    const [envoye, setEnvoye] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(false)

    return (
        <>
            <Button onClick={(e) => props.setState!(0)}> Retour </Button>
            <Text as="span" ml="10px">
                <b>Déclaration autre</b>
            </Text>
            <Box p={'20px'}>
                <Text>
                    Les problèmes mineurs peuvent étre réglés facilement, nous
                    vous invitons à consulter la section "tutoriel" disponible
                    en dessous de votre liste d'équipements.
                </Text>
                {props.entreprise.contratEntretien &&
                    props.entreprise.contratEntretien !== '' && (
                        <Text mt="10px">
                            Avant toute demande d'assistance, nous vous invitons
                            à consulter votre contrat d'entretien :{' '}
                            <ModalPDF
                                lien
                                url={props.entreprise.contratEntretien}
                                titre="Contrat d'entretien de votre entreprise"
                            />
                        </Text>
                    )}
                {props.entreprise.contratAssurance &&
                    props.entreprise.contratAssurance !== '' && (
                        <Text mt="10px">
                            Vous pouvez également consulter votre contrat
                            d'assurance (assistance rapratriement etc...) :{' '}
                            <ModalPDF
                                lien
                                url={props.entreprise.contratAssurance}
                                titre="Contrat d'assurance de votre entreprise"
                            />
                        </Text>
                    )}
                <Box mt="70px">
                    {!loading && (
                        <>
                            {envoye && (
                                <>
                                    <CheckIcon
                                        fontSize={'40px'}
                                        color={'green'}
                                        float={'left'}
                                        mx={'20px'}
                                    />
                                    <Text color={'green'} ml={'50px'}>
                                        Votre message a bien été envoyé. Nous
                                        allons revenir vers vous dans les
                                        meilleurs délais.
                                    </Text>
                                </>
                            )}
                            {!envoye && (
                                <FormulaireContact
                                    entreprise={props.entreprise}
                                    equipement={props.equipement}
                                    onSucess={() => setEnvoye(true)}
                                    onLoadingStart={() => setLoading(true)}
                                    onLoadingEnd={() => setLoading(false)}
                                />
                            )}
                        </>
                    )}
                    {loading && (
                        <>
                            <Spinner
                                thickness="5px"
                                speed="0.65s"
                                emptyColor="white"
                                color="black"
                                size="md"
                                float={'left'}
                                mx={'20px'}
                            />
                            <Text ml={'50px'}>Envoi en cours ...</Text>
                        </>
                    )}
                </Box>
            </Box>
        </>
    )
}

const TrouverUneSolutionMain = (props: PropsEcran) => {
    return (
        <Wrap>
            <CustomBox
                h="110px"
                w="calc(50% - 5px)"
                _hover={{
                    background: 'gray.100',
                }}
                onClick={(e) => props.setState!(1)}
            >
                <Center mt={'10px'}>
                    <Image src={'/icon/vol.svg'} h={'45px'} />
                </Center>
                <Center mt={'10px'}>
                    <Text as={'b'}>VOL</Text>
                </Center>
            </CustomBox>
            <CustomBox
                h="110px"
                w="calc(50% - 5px)"
                _hover={{
                    background: 'gray.100',
                }}
                onClick={(e) => props.setState!(2)}
            >
                <Center mt={'10px'}>
                    <Image src={'/icon/broken.svg'} h={'45px'} />
                </Center>
                <Center mt={'10px'}>
                    <Text as={'b'}>CASSE</Text>
                </Center>
            </CustomBox>
            <CustomBox
                h="110px"
                w="calc(50% - 5px)"
                _hover={{
                    background: 'gray.100',
                }}
                onClick={(e) => props.setState!(3)}
            >
                <Center mt={'10px'}>
                    <Image src={'/icon/accident.svg'} h={'45px'} />
                </Center>
                <Center mt={'10px'}>
                    <Text as={'b'}>ACCIDENT</Text>
                </Center>
            </CustomBox>
            <CustomBox
                h="110px"
                w="calc(50% - 5px)"
                _hover={{
                    background: 'gray.100',
                }}
                onClick={(e) => props.setState!(4)}
            >
                <Center mt={'30px'}>
                    <Text as={'b'} fontSize={'20px'}>
                        AUTRE
                    </Text>
                </Center>
            </CustomBox>
        </Wrap>
    )
}

interface PropsEcranNotice {
    entreprise: Entreprise
    equipement: Equipement
}
const BoxNotice = (props: PropsEcranNotice) => {
    return (
        <Box padding={'20px'}>
            <Text>
                Nous sommes navrés de l'incident que vous venez de subir. Pas de
                panique, nous sommes là pour vous accompagner. Vous avez{' '}
                <b>3 jours ouvrés</b> pour déclarer votre vol et <b>5 jours</b>{' '}
                pour tous les autres cas.
            </Text>

            <Text mt={'20px'}>
                Avant toute démarche, veuillez consulter les modalités
                d'assurance :{' '}
                <b>
                    <ModalPDF
                        lien
                        url={props.entreprise.contratAssurance}
                        titre="Contrat d'assurance de votre entreprise"
                    />
                </b>
            </Text>
            <Text mt={'10px'}>
                Votre formule : <b>{props.entreprise.formuleAssurance}</b>
            </Text>
            <Text mt={'20px'}>Vous pouvez contacter l'assurance au : </Text>
            <Text textAlign={'center'}>
                <b>{props.entreprise.telAssurance}</b>
            </Text>
            <Text>ou par mail : </Text>
            <Text textAlign={'center'}>
                <b>{props.entreprise.mailAssurance}</b>
            </Text>
            <Text mt={'20px'}>
                Pour simplifier la prise en charge, vous aurez besoin de fournir
                les informations suivantes:
            </Text>
            <Text mt="10px">
                Nom de votre entreprise:{' '}
                {props.entreprise.nom ? (
                    <b>{props.entreprise.nom} </b>
                ) : (
                    'Non renseigné'
                )}
            </Text>
            <Text>
                Marque du vélo :{' '}
                {props.equipement.marque ? (
                    <b>{props.equipement.marque} </b>
                ) : (
                    'Non renseigné'
                )}
            </Text>
            <Text>
                Modèle du vélo :{' '}
                {props.equipement.modele ? (
                    <b>{props.equipement.modele}</b>
                ) : (
                    'Non renseigné'
                )}
            </Text>
            <Text>
                Numéro de série :{' '}
                {props.equipement.numeroSerie ? (
                    <b>{props.equipement.numeroSerie} </b>
                ) : (
                    'Non renseigné'
                )}
            </Text>
            <Text>
                Numéro de marquage :{' '}
                {props.equipement.numeroMarquage ? (
                    <b>{props.equipement.numeroMarquage} </b>
                ) : (
                    'Non renseigné'
                )}
            </Text>
        </Box>
    )
}

const NoAssurance = (props: PropsEcranNotice) => {
    return (
        <Box padding={'20px'}>
            <Text textAlign={'center'} mt="10px">
                Aucune assurance n'a été contractée via <b>FenX mobility</b>
            </Text>

            <Text textAlign={'center'} mt="10px">
                Merci de bien vouloir vous rapprocher de votre entreprise pour
                connaitre les démarches à entreprendre.
            </Text>
        </Box>
    )
}
