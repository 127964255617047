/**
 * Plusieurs élément distinct composé d'un bouton ouvrant une modal.
 * chaque composant est dédié a une action dédié agissant dans le journal d'un equipement
 */
import {
    Box,
    Center,
    Input,
    InputGroup,
    InputLeftAddon,
    InputRightAddon,
    Modal,
    useToast,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    RadioGroup,
    Stack,
    Radio,
    Textarea,
    useDisclosure,
} from '@chakra-ui/react'
import { Action, Journal, Utilisateur } from '../../model'
import { collection, doc, setDoc } from 'firebase/firestore'
import { db } from '../../firebaseManager'
import { UtilisateurContext } from '../../auth/UtilisateurContextProvider'
import { useContext, useState } from 'react'
import { Button } from '../Button'
import {
    CallFunctionDeclarationKilometrique,
    CallFunctionDeclarationMaintenance,
} from '../../firebaseManager/Communicator/FunctionCallManager'
import { SingleDatepicker } from 'chakra-dayzed-datepicker'

function BuildJournalObj(
    action: Action,
    actualUser?: Utilisateur,
    props?: Props,
    valueNumber?: number,
    valueString?: string,
    lieu?: string
) {
    let journal: Journal = {
        dateAction: Date.now(),
        action: action,
    }

    if (actualUser) {
        if (actualUser.id) {
            journal.utilisateurInitiateurID = actualUser.id
        }
        if (actualUser?.nom || actualUser?.prenom) {
            journal.utilisateurInitiateurNomPrenom =
                actualUser?.nom + ' ' + actualUser?.prenom
        }
    }
    if (props) {
        if (props.equipementCibleID) {
            journal.equipementCibleID = props.equipementCibleID
        }
    }

    if (valueNumber) {
        journal.valueNumber = valueNumber
    }
    if (valueString) {
        journal.valueString = valueString
    }
    if (lieu) {
        journal.lieu = lieu
    }
    return journal
}

function PushToJournalBDD(
    obj: any,
    onSucess?: () => void,
    onFailure?: () => void
) {
    setDoc(doc(collection(db, 'journal')), obj)
        .then((result) => {
            console.log('OK')
            if (onSucess) onSucess()
        })
        .catch((e) => {
            console.log('error on add' + e)
            if (onFailure) onFailure()
        })
}

export type Props = {
    equipementCibleID: string
    oldKilometrage?: number
    OnSuccesAddToJournal?: () => void
}

export const DeclarationKilometriqueJournal = ({
    oldKilometrage = 0,
    ...props
}: Props) => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const {
        isOpen: isLoading,
        onOpen: onOpenLoading,
        onClose: onCloseLoading,
    } = useDisclosure()
    const [kilometrage, setKilometrage] = useState<number>(0)
    const toast = useToast()

    return (
        <>
            <Box>
                <Button
                    size={'sm'}
                    p="10px"
                    colorScheme="gray-dark"
                    onClick={onOpen}
                >
                    Kilométrage
                </Button>
            </Box>
            <Modal size={'xl'} isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Déclarer Kilométrage du vélo</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <InputGroup>
                            <InputLeftAddon>Kilométrage actuel</InputLeftAddon>
                            <Input
                                type="number"
                                onChange={(e) =>
                                    setKilometrage(Number(e.target.value))
                                }
                            ></Input>
                            <InputRightAddon>Kms</InputRightAddon>
                        </InputGroup>
                        {kilometrage <= oldKilometrage ? (
                            <Center mt={'5px'}>
                                L'ancienne déclaration kilométrique était de{' '}
                                {oldKilometrage} kilométres
                            </Center>
                        ) : (
                            <></>
                        )}
                    </ModalBody>

                    <Center mt={'10px'} mb={'10px'}>
                        <Button
                            colorScheme={'blue'}
                            isDisabled={kilometrage <= oldKilometrage}
                            isLoading={isLoading}
                            onClick={(e) => {
                                if (kilometrage) {
                                    const kilometrageNumber: number =
                                        Number(kilometrage)
                                    onOpenLoading()
                                    CallFunctionDeclarationKilometrique(
                                        props.equipementCibleID,
                                        kilometrageNumber
                                    )
                                        .then((result) => {
                                            oldKilometrage = kilometrage
                                            onClose()
                                            toast({
                                                title: 'Déclaration effectuée',
                                                status: 'success',
                                                duration: 2000,
                                                isClosable: true,
                                            })
                                            onCloseLoading()
                                            setKilometrage(0)
                                            if (props.OnSuccesAddToJournal) {
                                                props.OnSuccesAddToJournal()
                                            }
                                        })
                                        .catch((e) => {
                                            console.log(
                                                'error on declaration kilométrique : ' +
                                                    e.message
                                            )
                                            onCloseLoading()
                                        })
                                }
                            }}
                        >
                            Déclarer
                        </Button>
                    </Center>
                </ModalContent>
            </Modal>
        </>
    )
}

export const AjoutMessageJournal = (props: Props) => {
    const actualUser = useContext(UtilisateurContext)
    const { isOpen, onOpen, onClose } = useDisclosure()
    const {
        isOpen: isLoading,
        onOpen: onOpenLoading,
        onClose: onCloseLoading,
    } = useDisclosure()
    const [message, setMessage] = useState<string | null | undefined>()
    const toast = useToast()

    return (
        <>
            <Box>
                <Button
                    size={'sm'}
                    p="10px"
                    colorScheme="gray-dark"
                    onClick={onOpen}
                >
                    Message
                </Button>
            </Box>
            <Modal size={'xl'} isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Ajouter un message</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <InputGroup>
                            <InputLeftAddon>Message</InputLeftAddon>
                            <Textarea
                                onChange={(e) => setMessage(e.target.value)}
                            ></Textarea>
                        </InputGroup>
                    </ModalBody>

                    <Center mt={'10px'} mb={'10px'}>
                        <Button
                            colorScheme={'blue'}
                            isDisabled={!message}
                            isLoading={isLoading}
                            onClick={(e) => {
                                if (message) {
                                    onOpenLoading()
                                    const obj = BuildJournalObj(
                                        Action.MessageTextuel,
                                        actualUser!,
                                        props,
                                        undefined,
                                        message,
                                        undefined
                                    )
                                    PushToJournalBDD(
                                        obj,
                                        () => {
                                            onClose()
                                            toast({
                                                title: 'Message déposé',
                                                status: 'success',
                                                duration: 2000,
                                                isClosable: true,
                                            })
                                            onCloseLoading()
                                            setMessage(null)
                                        },
                                        () => {
                                            onCloseLoading()
                                        }
                                    )
                                }
                            }}
                        >
                            Ajouter
                        </Button>
                    </Center>
                </ModalContent>
            </Modal>
        </>
    )
}

export const DeclarerPBJournal = (props: Props) => {
    const actualUser = useContext(UtilisateurContext)
    const { isOpen, onOpen, onClose } = useDisclosure()
    const {
        isOpen: isLoading,
        onOpen: onOpenLoading,
        onClose: onCloseLoading,
    } = useDisclosure()
    const [message, setMessage] = useState<string | null | undefined>()
    const toast = useToast()

    return (
        <>
            <Box>
                <Button
                    size={'sm'}
                    p="10px"
                    colorScheme="gray-dark"
                    onClick={onOpen}
                >
                    Problème
                </Button>
            </Box>
            <Modal size={'xl'} isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Déclaration de problème</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <InputGroup>
                            <InputLeftAddon>Message</InputLeftAddon>
                            <Textarea
                                onChange={(e) => setMessage(e.target.value)}
                            ></Textarea>
                        </InputGroup>
                    </ModalBody>

                    <Center mt={'10px'} mb={'10px'}>
                        <Button
                            colorScheme={'blue'}
                            isDisabled={!message}
                            isLoading={isLoading}
                            onClick={(e) => {
                                if (message) {
                                    onOpenLoading()
                                    const obj = BuildJournalObj(
                                        Action.DeclarationPb,
                                        actualUser!,
                                        props,
                                        undefined,
                                        message,
                                        undefined
                                    )
                                    PushToJournalBDD(
                                        obj,
                                        () => {
                                            onClose()
                                            toast({
                                                title: 'Problème déposé',
                                                status: 'success',
                                                duration: 2000,
                                                isClosable: true,
                                            })
                                            onCloseLoading()
                                            setMessage(null)
                                        },
                                        () => {
                                            onCloseLoading()
                                        }
                                    )
                                }
                            }}
                        >
                            Ajouter
                        </Button>
                    </Center>
                </ModalContent>
            </Modal>
        </>
    )
}

export const DeclarerRepJournal = (props: Props) => {
    const actualUser = useContext(UtilisateurContext)
    const { isOpen, onOpen, onClose } = useDisclosure()

    const {
        isOpen: isLoading,
        onOpen: onOpenLoading,
        onClose: onCloseLoading,
    } = useDisclosure()
    const [message, setMessage] = useState<string | null | undefined>()
    const [reparationLocalisation, setReparationLocalisation] = useState<
        string | null | undefined
    >()
    const toast = useToast()

    return (
        <>
            <Box>
                <Button
                    size={'sm'}
                    p="10px"
                    colorScheme="gray-dark"
                    onClick={onOpen}
                >
                    Réparation
                </Button>
            </Box>
            <Modal size={'xl'} isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Déclaration d'une Réparation</ModalHeader>
                    <ModalCloseButton />

                    <ModalBody>
                        <InputGroup mb={'5px'}>
                            <InputLeftAddon>Lieu de réparation</InputLeftAddon>
                            <RadioGroup
                                ml={'10px'}
                                mt={'5px'}
                                onChange={setReparationLocalisation}
                                value={
                                    reparationLocalisation
                                        ? reparationLocalisation
                                        : ''
                                }
                            >
                                <Stack spacing={4} direction="row">
                                    <Radio value="SITE">Sur site</Radio>
                                    <Radio value="ATELIER">À l'atelier</Radio>
                                </Stack>
                            </RadioGroup>
                        </InputGroup>

                        <InputGroup>
                            <InputLeftAddon>Message</InputLeftAddon>
                            <Textarea
                                onChange={(e) => setMessage(e.target.value)}
                            ></Textarea>
                        </InputGroup>
                    </ModalBody>

                    <Center mt={'10px'} mb={'10px'}>
                        <Button
                            colorScheme={'blue'}
                            isDisabled={!message}
                            isLoading={isLoading}
                            onClick={(e) => {
                                if (!reparationLocalisation) {
                                    toast({
                                        title: 'Lieu de réparation non selectionné',
                                        status: 'error',
                                        duration: 3000,
                                        isClosable: true,
                                    })
                                    return
                                }
                                if (message) {
                                    onOpenLoading()
                                    const obj = BuildJournalObj(
                                        Action.DeclarationRep,
                                        actualUser!,
                                        props,
                                        undefined,
                                        message,
                                        reparationLocalisation
                                    )
                                    PushToJournalBDD(
                                        obj,
                                        () => {
                                            onClose()
                                            toast({
                                                title: 'Réparation déposé',
                                                status: 'success',
                                                duration: 2000,
                                                isClosable: true,
                                            })
                                            onCloseLoading()
                                            setMessage(null)
                                        },
                                        () => {
                                            onCloseLoading()
                                        }
                                    )
                                }
                            }}
                        >
                            Ajouter
                        </Button>
                    </Center>
                </ModalContent>
            </Modal>
        </>
    )
}

export const DeclarerMaintenanceJournal = (props: Props) => {
    const actualUser = useContext(UtilisateurContext)
    const { isOpen, onOpen, onClose } = useDisclosure()
    const {
        isOpen: isOpen2,
        onOpen: onOpen2,
        onClose: onClose2,
    } = useDisclosure()
    const {
        isOpen: isLoading,
        onOpen: onOpenLoading,
        onClose: onCloseLoading,
    } = useDisclosure()
    const [message, setMessage] = useState<string | null | undefined>()
    const [maintenanceLocalisation, setMaintenanceLocalisation] = useState<
        string | null | undefined
    >()
    const [date, setDate] = useState<number>(new Date().getTime())
    const toast = useToast()

    return (
        <>
            <Box>
                <Button
                    size={'sm'}
                    p="10px"
                    colorScheme="gray-dark"
                    onClick={onOpen}
                >
                    Maintenance
                </Button>
            </Box>
            <Modal size={'xl'} isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Déclaration d'une Maintenance</ModalHeader>
                    <ModalCloseButton />

                    <ModalBody>
                        <InputGroup mb={'5px'}>
                            <InputLeftAddon>
                                Lieu de la maintenance
                            </InputLeftAddon>
                            <RadioGroup
                                ml={'10px'}
                                mt={'5px'}
                                onChange={setMaintenanceLocalisation}
                                value={
                                    maintenanceLocalisation
                                        ? maintenanceLocalisation
                                        : ''
                                }
                            >
                                <Stack spacing={4} direction="row">
                                    <Radio value="SITE">Sur site</Radio>
                                    <Radio value="ATELIER">À l'atelier</Radio>
                                </Stack>
                            </RadioGroup>
                        </InputGroup>
                        <InputGroup mb={'5px'} zIndex={'100'}>
                            <InputLeftAddon>
                                Date de la maintenance
                            </InputLeftAddon>
                            <SingleDatepicker
                                configs={{
                                    dateFormat: 'dd/MM/yyyy',
                                    dayNames:
                                        'Dim Lun Mar Mer Jeu Ven Sam'.split(
                                            ' '
                                        ), // length of 7
                                    monthNames:
                                        'Janvier Février Mars Avril Mai Juin Juillet Août Septembre Octobre Novembre Décembre'.split(
                                            ' '
                                        ),
                                    firstDayOfWeek: 1,
                                }}
                                date={new Date(date)}
                                onDateChange={(value) =>
                                    setDate(value.getTime())
                                }
                            />
                        </InputGroup>
                        <InputGroup>
                            <InputLeftAddon>Message</InputLeftAddon>
                            <Textarea
                                onChange={(e) => setMessage(e.target.value)}
                            ></Textarea>
                        </InputGroup>
                    </ModalBody>

                    <Center mt={'10px'} mb={'10px'}>
                        <Button
                            colorScheme={'blue'}
                            isDisabled={!message}
                            isLoading={isLoading}
                            onClick={(e) => {
                                if (!maintenanceLocalisation) {
                                    toast({
                                        title: 'Lieu de maintenance non selectionné',
                                        status: 'error',
                                        duration: 3000,
                                        isClosable: true,
                                    })
                                    return
                                }
                                onOpen2()
                            }}
                        >
                            Ajouter
                        </Button>
                    </Center>
                </ModalContent>
            </Modal>

            <Modal size={'xs'} isOpen={isOpen2} onClose={onClose2}>
                <ModalOverlay />
                <ModalContent mt="100px">
                    <ModalHeader>Valider la maintenance</ModalHeader>
                    <ModalCloseButton />

                    <ModalBody>
                        <Center>
                            <Button
                                width={'40%'}
                                colorScheme={'blue'}
                                isDisabled={!message}
                                isLoading={isLoading}
                                onClick={(e) => {
                                    onOpenLoading()
                                    CallFunctionDeclarationMaintenance(
                                        props.equipementCibleID,
                                        date,
                                        message!,
                                        maintenanceLocalisation!
                                    )
                                        .then((result) => {
                                            onClose()
                                            onClose2()
                                            toast({
                                                title: 'Maintenance déposé',
                                                status: 'success',
                                                duration: 2000,
                                                isClosable: true,
                                            })
                                            onCloseLoading()
                                            setMessage(null)
                                            if (props.OnSuccesAddToJournal) {
                                                props.OnSuccesAddToJournal()
                                            }
                                        })
                                        .catch((e) => {
                                            onCloseLoading()
                                            toast({
                                                title: 'Échec lors de la déclaration de maintenance',
                                                description: '',
                                                status: 'error',
                                                duration: 3000,
                                                isClosable: true,
                                            })
                                        })
                                }}
                            >
                                Oui
                            </Button>
                            <Button
                                ml={'10%'}
                                width={'40%'}
                                colorScheme={'red-light'}
                                onClick={(e) => {
                                    onClose2()
                                }}
                            >
                                non
                            </Button>
                        </Center>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    )
}
