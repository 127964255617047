/*
 * Class d'intération avec firestore pour les éléments : Agence
 */
import { Agence } from '../../../model'
import { db } from '../..'
import {
    collection,
    query,
    doc,
    getDocs,
    deleteDoc,
    where,
    documentId,
    orderBy,
    limit,
} from 'firebase/firestore'

export async function GetTargetAgence(
    agenceId: string
): Promise<Agence | undefined> {
    const q = query(
        collection(db, 'agences'),
        where(documentId(), '==', agenceId),
        limit(1)
    )
    const querySnapshot = await getDocs(q)
    if (!querySnapshot.empty) {
        return {
            id: querySnapshot.docs[0].id,
            ...querySnapshot.docs[0].data(),
        } as Agence
    }
    return undefined
}

export async function GetAllAgencesForTargetEntreprise(
    targetEntrepriseId: string
): Promise<Agence[]> {
    const q = query(
        collection(db, 'agences'),
        where('entrepriseAppartenance', '==', targetEntrepriseId),
        orderBy('nom', 'asc')
    )
    const querySnapshot = await getDocs(q)
    const allAgences: Agence[] = querySnapshot.docs.map((doc) => {
        return { id: doc.id, ...doc.data() } as Agence
    })
    return allAgences
}

export async function GetAllAgences(): Promise<Agence[]> {
    const q = query(collection(db, 'agences'), orderBy('nom', 'asc'))
    const querySnapshot = await getDocs(q)
    const allAgences: Agence[] = querySnapshot.docs.map((doc) => {
        return { id: doc.id, ...doc.data() } as Agence
    })
    return allAgences
}

export async function DeleteOneAgence(agenceId: string): Promise<void> {
    await deleteDoc(doc(db, 'agences', agenceId))
}
