/*
 * bouton ouvrant une modal et permettant de modifier l'assignation d'un équipement ou d'un utilisateur.
 * Dans le cas Equipement, il est possible de modifier l'entreprise et l'agence, et optionnelement ajouter un utilisateur assigné
 * Dans le cas Utilsiateur, il est possible de choisir l'entreprise et l'agence.
 * La modification de l'assignation d'un utilisateur va automatiquement modifier l'assignation de ses équipements
 */

import {
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    Select,
    InputGroup,
    InputLeftAddon,
    Box,
    ModalOverlay,
    useDisclosure,
    useToast,
} from '@chakra-ui/react'
import {
    CallFunctionAdminChangementAppartenanceEquipement,
    CallFunctionAdminChangementAppartenanceUtilisateur,
} from '../../firebaseManager/Communicator/FunctionCallManager'
import { Button } from '../Button'
import { useContext, useEffect, useState } from 'react'
import { Utilisateur, Entreprise, Agence } from '../../model'
import { GetAllEntreprises } from '../../firebaseManager/Communicator/EntrepriseCommunicator'
import { GetAllAgencesForTargetEntreprise } from '../../firebaseManager/Communicator/AgenceCommunicator'
import { GetAllUtilisateursTargetEntrepriseAgence } from '../../firebaseManager/Communicator/UtilisateurCommunicator'
import { AuthContext } from '../../auth/AuthContextProvider'

export type Props = {
    mode: 'utilisateur' | 'equipement'
    equipementId?: string
    utilisateurId?: string
    onSuccess?: () => void
}

export const ModalChangementAssignation = (props: Props) => {
    const authentification = useContext(AuthContext)
    const toast = useToast()
    const {
        isOpen: isOpenModal,
        onOpen: onOpenModal,
        onClose: onCloseModal,
    } = useDisclosure()

    const [isLoading, setisLoading] = useState<boolean>(false)

    const [allEntreprises, setAllEntreprises] = useState<Entreprise[] | null>()
    const [targetEntreprise, setTargetEntreprise] = useState<string>('')

    const [
        allAgencesForSelectedEntreprise,
        setAllAgencesForSelectedEntreprise,
    ] = useState<Agence[] | null>()
    const [targetAgence, setTargetAgence] = useState<string>('')

    const [allUserForTargetAgence, setAllUserForTargetAgence] = useState<
        Utilisateur[] | null
    >()
    const [targetUser, setTargetUser] = useState<string>('')

    function RefreshAllEntreprises() {
        GetAllEntreprises()
            .then((result) => {
                setAllEntreprises(result)
                setTargetUser('')
                setTargetEntreprise('')
                setTargetAgence('')
            })
            .catch((e) => {
                setAllEntreprises(null)
                setAllAgencesForSelectedEntreprise(null)
                setAllUserForTargetAgence(null)
                setTargetUser('')
                setTargetEntreprise('')
                setTargetAgence('')
            })
    }

    function RefreshAllAgencesForTargetEntreprise(entrepriseId: string) {
        GetAllAgencesForTargetEntreprise(entrepriseId)
            .then((result) => {
                setAllAgencesForSelectedEntreprise(result)
                setTargetUser('')
                setTargetAgence('')
            })
            .catch((e) => {
                setAllAgencesForSelectedEntreprise(null)
                setAllUserForTargetAgence(null)
                setTargetUser('')
                setTargetAgence('')
            })
    }

    function RefreshAllUsersForTargetAgence(
        entrepriseId: string,
        agenceId: string
    ) {
        if (props.mode === 'equipement') {
            GetAllUtilisateursTargetEntrepriseAgence(entrepriseId, agenceId)
                .then((result) => {
                    setAllUserForTargetAgence(result)
                    setTargetUser('')
                })
                .catch((e) => {
                    setAllUserForTargetAgence(null)
                    setTargetUser('')
                })
        }
    }

    useEffect(() => {
        if (authentification.firebaseUser != null) {
            RefreshAllEntreprises()
        }
    }, [authentification])

    return (
        <>
            <Button
                colorScheme="blue"
                p={5}
                onClick={(e) => {
                    onOpenModal()
                }}
            >
                Déplacer
            </Button>
            <Modal isOpen={isOpenModal} onClose={onCloseModal}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Changement d'assignation</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody pb={6}>
                        <Box width={'100%'}>
                            <InputGroup>
                                <InputLeftAddon children="Entreprise" />{' '}
                                <Select
                                    placeholder="choisir une entreprise"
                                    onChange={(e) => {
                                        setTargetEntreprise(
                                            e.currentTarget.value
                                        )
                                        RefreshAllAgencesForTargetEntreprise(
                                            e.currentTarget.value
                                        )
                                        setTargetAgence('')
                                        setTargetUser('')
                                    }}
                                >
                                    {allEntreprises?.map(function (
                                        entreprise,
                                        idx
                                    ) {
                                        return (
                                            <option
                                                key={idx}
                                                value={entreprise.id}
                                            >
                                                {entreprise.nom}
                                            </option>
                                        )
                                    })}
                                </Select>
                            </InputGroup>
                        </Box>

                        <Box
                            width={'100%'}
                            hidden={targetEntreprise === ''}
                            mt={'5px'}
                        >
                            <InputGroup>
                                <InputLeftAddon children="Agence" />{' '}
                                <Select
                                    placeholder="choisir une agence"
                                    onChange={(e) => {
                                        setTargetAgence(e.currentTarget.value)
                                        RefreshAllUsersForTargetAgence(
                                            targetEntreprise!,
                                            e.currentTarget.value
                                        )
                                        setTargetUser('')
                                    }}
                                >
                                    {allAgencesForSelectedEntreprise?.map(
                                        function (agence, idx) {
                                            return (
                                                <option
                                                    key={idx}
                                                    value={agence.id}
                                                >
                                                    {agence.nom}
                                                </option>
                                            )
                                        }
                                    )}
                                </Select>
                            </InputGroup>
                        </Box>
                        <Box
                            width={'100%'}
                            hidden={
                                targetAgence === '' ||
                                props.mode === 'utilisateur'
                            }
                            mt={'5px'}
                        >
                            <InputGroup>
                                <InputLeftAddon children="Utilisateur" />{' '}
                                <Select
                                    placeholder="choisir un Utilisateur"
                                    onChange={(e) => {
                                        setTargetUser(e.currentTarget.value)
                                    }}
                                >
                                    {allUserForTargetAgence?.map(function (
                                        utilisateur,
                                        idx
                                    ) {
                                        return (
                                            <option
                                                key={idx}
                                                value={utilisateur.id}
                                            >
                                                {utilisateur.nom +
                                                    ' ' +
                                                    utilisateur.prenom}
                                            </option>
                                        )
                                    })}
                                </Select>
                            </InputGroup>
                        </Box>
                    </ModalBody>

                    <ModalFooter>
                        <Button
                            mr={3}
                            isLoading={isLoading}
                            isDisabled={!targetEntreprise || !targetAgence}
                            colorScheme={'blue'}
                            onClick={(e) => {
                                setisLoading(true)
                                if (props.mode === 'equipement') {
                                    CallFunctionAdminChangementAppartenanceEquipement(
                                        props.equipementId!,
                                        targetAgence,
                                        targetEntreprise,
                                        targetUser
                                    )
                                        .then((result) => {
                                            toast({
                                                title: "Changement d'assignation de l'équipement réussi",
                                                description: '',
                                                status: 'success',
                                                duration: 3000,
                                                isClosable: true,
                                            })
                                            setisLoading(false)
                                            onCloseModal()
                                            if (props.onSuccess)
                                                props.onSuccess()
                                        })
                                        .catch((e) => {
                                            setisLoading(false)
                                            toast({
                                                title: "Échec du changement d'assignation de l'équipement",
                                                description: e.message,
                                                status: 'error',
                                                duration: 5000,
                                                isClosable: true,
                                            })
                                        })
                                }
                                if (props.mode === 'utilisateur') {
                                    CallFunctionAdminChangementAppartenanceUtilisateur(
                                        props.utilisateurId!,
                                        targetAgence,
                                        targetEntreprise
                                    )
                                        .then((result) => {
                                            toast({
                                                title: "Changement d'assignation de l'utilisateur réussi",
                                                description: '',
                                                status: 'success',
                                                duration: 3000,
                                                isClosable: true,
                                            })
                                            setisLoading(false)
                                            onCloseModal()
                                            if (props.onSuccess)
                                                props.onSuccess()
                                        })
                                        .catch((e) => {
                                            setisLoading(false)
                                            toast({
                                                title: "Échec du changement d'assignation de l'utilisateur",
                                                description: e.message,
                                                status: 'error',
                                                duration: 5000,
                                                isClosable: true,
                                            })
                                        })
                                }
                            }}
                        >
                            valider
                        </Button>
                        <Button
                            mr={3}
                            colorScheme={'orange-light'}
                            onClick={(e) => {
                                onCloseModal()
                            }}
                        >
                            Annuler
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}
