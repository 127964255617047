/**
 * Bouton custom
 */

import {
    Button as ChakraButton,
    ButtonProps,
    ComponentWithAs,
    HStack,
    IconProps,
    forwardRef,
} from '@chakra-ui/react'

export type ColorScheme =
    | 'blue'
    | 'gray'
    | 'gray-dark'
    | 'orange-light'
    | 'red-light'
export type Props = {
    children?: React.ReactNode
    colorScheme?: ColorScheme
    icon?: ComponentWithAs<'svg', IconProps>
} & Omit<ButtonProps, 'colorScheme'>

export const Button = forwardRef<Props, 'button'>(
    ({ children, colorScheme = 'blue', icon: Icon, ...props }, ref) => {
        const styles = getStyles(colorScheme, children)

        return (
            <ChakraButton
                colorScheme={colorScheme}
                ref={ref}
                variant={'solid'}
                {...styles}
                {...props}
            >
                <HStack spacing={4}>
                    {Icon && <Icon boxSize="18px" />}
                    {children && <span>{children}</span>}
                </HStack>
            </ChakraButton>
        )
    }
)

const getStyles = (
    colorScheme: ColorScheme,
    children?: React.ReactNode
): ButtonProps => {
    const sharedStyles = {
        borderRadius: 'xl',
        minH: 45,
        px: !children ? 7 : 10,
    }

    switch (colorScheme) {
        case 'blue':
            return {
                ...sharedStyles,
                background: '#2d7bb6',
                color: 'white',
                _active: {
                    background: '#31a1d7',
                },
                _hover: { background: '#31a1d7' },
                _focus: { boxShadow: '#2d7bb6' },
            }
        case 'gray':
            return {
                ...sharedStyles,
                background: 'gray.100',
                color: 'blue.900',
                _active: { background: 'gray.50' },
                _hover: { background: 'gray.50' },
                _focus: { boxShadow: 'gray' },
            }
        case 'gray-dark':
            return {
                ...sharedStyles,
                background: 'gray.300',
                color: 'blue.900',
                _active: { background: 'gray.100' },
                _hover: { background: 'gray.100' },
                _focus: { boxShadow: 'gray' },
            }
        case 'orange-light':
            return {
                ...sharedStyles,
                background: 'orange.50',
                color: 'orange.500',
                _active: { background: 'orange.500', color: 'white' },
                _hover: { background: 'orange.500', color: 'white' },
                _focus: { boxShadow: 'orange' },
            }
        case 'red-light':
            return {
                ...sharedStyles,
                background: 'red.50',
                color: 'red.500',
                _active: { background: 'red.500', color: 'white' },
                _hover: { background: 'red.500', color: 'white' },
                _focus: { boxShadow: 'red' },
            }
        default:
            return {}
    }
}
