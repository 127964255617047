/*
 * Page NOT FOUND
 */
import { Box, Heading, Text, Button } from '@chakra-ui/react'

export const NotFound = () => {
    return (
        <Box textAlign="center" py={10} px={6}>
            <Heading
                display="inline-block"
                as="h2"
                size="2xl"
                bgGradient="linear(to-r, blue.400, blue.600)"
                backgroundClip="text"
            >
                404
            </Heading>
            <Text fontSize="18px" mt={3} mb={2}>
                Vous étes perdu ?
            </Text>
            <Text color={'gray.500'} mb={6}>
                Cette page n'existe pas ...
            </Text>

            <Button
                colorScheme="blue"
                bgGradient="linear(to-r, blue.400, blue.500, blue.600)"
                color="white"
                variant="solid"
            >
                Retour a l'acceuil
            </Button>
        </Box>
    )
}
