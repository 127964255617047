/**
 * Context provider pour fournir les informations de l'utilisateur au sens Firebase.
 * Utilisateur fournis par le système d'authentification (possèdant l'UID).
 */

import React, { ReactNode, useEffect, useState } from 'react'
import { getAuth, Auth, User as FirebaseUser } from 'firebase/auth'
import { useNavigate } from 'react-router-dom'
import { app } from '../firebaseManager'

//appel de l'app firebaseManager pour forcer l'initialisation
const forcedInit = app

interface authentification {
    auth: Auth | undefined
    firebaseUser: FirebaseUser | undefined
}

export const AuthContext = React.createContext<authentification>({
    auth: getAuth(),
    firebaseUser: undefined,
})

interface AuthProps {
    children: ReactNode
}

export const AuthContextProvider = (props: AuthProps) => {
    const navigate = useNavigate()

    const [authentification, setAuthentification] = useState<authentification>({
        auth: getAuth(),
        firebaseUser: undefined,
    })

    useEffect(() => {
        getAuth().onAuthStateChanged((firebaseUser) => {
            if (firebaseUser) {
                console.log('connexion Ok via Firebase')
                setAuthentification({
                    auth: getAuth(),
                    firebaseUser: firebaseUser,
                })
            } else {
                console.log('connexion requise')
                console.log(window.location.href)
                if (
                    !window.location.href.includes('/action_fb') &&
                    !window.location.href.includes('/reset_password')
                )
                    navigate('/login')
                setAuthentification({
                    auth: getAuth(),
                    firebaseUser: undefined,
                })
            }
        })
    }, [])

    return (
        <AuthContext.Provider value={authentification}>
            {props.children}
        </AuthContext.Provider>
    )
}
