/*
 * bloc pré fait permettatn d'afficher les informations d'un vélo sur l'interface des clients
 */

import {
    WrapItem,
    Wrap,
    Text,
    Center,
    Spinner,
    Image,
    Button as Chakrabutton,
    Box,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Input,
    InputGroup,
    InputRightAddon,
    Flex,
    useToast,
    useDisclosure,
    ModalFooter,
} from '@chakra-ui/react'
import { Equipement } from '../../model'
import { CustomBox } from '../CustomBox'
import { KlmToCO2AutoText } from '../../utils/helpers'
import moment from 'moment'
import { CallFunctionDeclarationKilometrique } from '../../firebaseManager/Communicator/FunctionCallManager'
import { useContext, useState } from 'react'
import { GraphiqueKilometrageVelo } from '../GraphiqueKilometrageVelo'
import { TrouverUneSolution } from './TrouverUneSolution'
import { Button } from '../Button'
import { EntrepriseContext } from '../../auth/EntrepriseContextProvider'

interface Props {
    equipement?: Equipement
    withGraph?: boolean
}

export const VeloPanel = (props: Props) => {
    const toast = useToast()
    const { isOpen, onOpen, onClose } = useDisclosure()

    const entreprise = useContext(EntrepriseContext)
    const [kilometrageCible, setKilometrageCible] = useState<number>(0)
    const [kilometrage, setKilometrage] = useState<number | undefined>(
        props.equipement?.kilometrage
    )
    const [updateKilometrageLoading, setUpdateKilometrageLoading] =
        useState<boolean>(false)

    const [dateKilometrage, setDateKilometrage] = useState<number | undefined>(
        props.equipement?.dateKilometrage
    )

    function declarationKilometrique() {
        setUpdateKilometrageLoading(true)

        CallFunctionDeclarationKilometrique(
            props.equipement?.id!,
            kilometrageCible
        )
            .then((result) => {
                setDateKilometrage(Date.now())
                setKilometrage(kilometrageCible)
                setUpdateKilometrageLoading(false)
                onClose()
                toast({
                    title: 'Kilométrage mis à jour',
                    description: '',
                    status: 'success',
                    duration: 2000,
                    isClosable: true,
                })
            })
            .catch((e) => {
                console.log('error on declaration kilométrique : ' + e.message)
                setUpdateKilometrageLoading(false)

                toast({
                    title: e.message,
                    description: '',
                    status: 'warning',
                    duration: 2000,
                    isClosable: true,
                })
            })
    }
    return (
        <>
            <Wrap mt={'20px'} mb={'20px'}>
                <CustomBox
                    w={{ base: '100%', md: '260px' }}
                    h={{ base: '230px', md: '230px' }}
                    borderRadius={'15px'}
                    padding={'10px'}
                    bg={'#2d7bb6'}
                    display={'block'}
                    color={'white'}
                >
                    <Text fontSize={'sm'}>Marque :</Text>
                    <Center>
                        <Text as="b" fontSize={'lg'}>
                            {props.equipement?.marque}
                        </Text>
                    </Center>
                    <Text fontSize={'sm'}>Modèle :</Text>
                    <Center>
                        <Text as="b" fontSize={'lg'}>
                            {props.equipement?.modele}
                        </Text>
                    </Center>
                    <Text fontSize={'sm'}>No Série :</Text>
                    <Center>
                        <Text as="b" fontSize={'lg'}>
                            {props.equipement?.numeroSerie}
                        </Text>
                    </Center>
                    <Text fontSize={'sm'}>No Marquage :</Text>
                    <Center>
                        <Text as="b" fontSize={'lg'}>
                            {props.equipement?.numeroMarquage}
                        </Text>
                    </Center>
                </CustomBox>
                <WrapItem w={{ base: '100%', md: '468px' }}>
                    <Wrap w={{ base: '100%', md: '468px' }}>
                        <CustomBox
                            w={{ base: '100%', md: '468px' }}
                            h="120px"
                            borderRadius={'15px'}
                            padding={'10px'}
                            bg={'#27292c'}
                            color={'white'}
                        >
                            <Image
                                src={'icon/path.svg'}
                                float={'right'}
                                h={'70px'}
                                mt={'8px'}
                            />
                            <Center>
                                <Text as={'b'} fontSize="md">
                                    Déclaration kilométrique
                                </Text>
                            </Center>
                            <Center mt={'12px'}>
                                <Flex>
                                    <InputGroup>
                                        <Input
                                            w={'120px'}
                                            bg={'white'}
                                            color={'black'}
                                            onChange={(e) =>
                                                setKilometrageCible(
                                                    Number(e.target.value)
                                                )
                                            }
                                        ></Input>
                                        <InputRightAddon color={'black'}>
                                            Kms
                                        </InputRightAddon>
                                    </InputGroup>
                                    <Chakrabutton
                                        onClick={onOpen}
                                        isDisabled={
                                            kilometrageCible <= kilometrage!
                                        }
                                    >
                                        <Text fontSize="xs">Envoyer</Text>
                                    </Chakrabutton>
                                </Flex>
                            </Center>
                            <Center mt={'10px'}>
                                <Text fontSize="xs">
                                    La derniére déclaration était de{' '}
                                    <b>{kilometrage}</b> kms{' '}
                                    {dateKilometrage ? (
                                        <>
                                            le{' '}
                                            <b>
                                                {moment(dateKilometrage).format(
                                                    'DD/MM/YYYY'
                                                )}
                                            </b>
                                        </>
                                    ) : (
                                        <></>
                                    )}
                                </Text>
                            </Center>
                        </CustomBox>
                        <CustomBox
                            w={{
                                base: '100%',
                                sm: 'calc(50% - 5px)',
                                md: '230px',
                            }}
                            h="100px"
                            bg="white"
                            borderRadius={'15px'}
                            padding={'10px'}
                            color={'green'}
                        >
                            <Image
                                src={'icon/leaf2.svg'}
                                float={'right'}
                                h={'50px'}
                                mt={'10px'}
                                mr={'4px'}
                            />

                            {props.equipement?.kilometrage && (
                                <>
                                    <Box mt={'8px'}>
                                        <Center>
                                            <Text fontSize="xl" as="b">
                                                {KlmToCO2AutoText(kilometrage)}
                                            </Text>
                                        </Center>
                                    </Box>
                                    <Box mt={'5px'}>
                                        <Center>
                                            <Text fontSize="sm">
                                                CO2 économisé
                                            </Text>
                                        </Center>
                                    </Box>
                                </>
                            )}
                            {!props.equipement?.kilometrage && (
                                <Spinner
                                    thickness="4px"
                                    speed="0.65s"
                                    emptyColor="white"
                                    color="black"
                                    size="xl"
                                />
                            )}
                        </CustomBox>
                        <CustomBox
                            w={{
                                base: '100%',
                                sm: 'calc(50% - 5px)',
                                md: '230px',
                            }}
                            h="100px"
                            borderRadius={'15px'}
                            padding={'10px'}
                            bg="red.100"
                        >
                            <Center>
                                <Text as="b" fontSize="sm">
                                    Un problème ?
                                </Text>
                            </Center>
                            <Center mt={'10px'}>
                                {entreprise && props.equipement && (
                                    <TrouverUneSolution
                                        equipement={props.equipement}
                                        entreprise={entreprise}
                                    ></TrouverUneSolution>
                                )}
                            </Center>
                        </CustomBox>
                    </Wrap>
                </WrapItem>
                {props.withGraph && (
                    <CustomBox
                        w={{ base: '100%', md: '738px' }}
                        h="240px"
                        borderRadius={'15px'}
                        padding={'10px'}
                    >
                        <GraphiqueKilometrageVelo
                            equipement={props.equipement!}
                        />
                    </CustomBox>
                )}
            </Wrap>

            <Modal size={'sm'} isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Déclaration kilométrique</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Center>
                            <Text fontSize="sm" as="b">
                                Ancien Kilométrage :
                            </Text>
                        </Center>
                        <Center>
                            <Text fontSize="md" as="b">
                                {kilometrage} kms
                            </Text>
                        </Center>
                        <Center mt="20px">
                            <Text fontSize="sm" as="b">
                                Nouveau Kilométrage :
                            </Text>
                        </Center>
                        <Center>
                            <Text fontSize="lg" as="b">
                                {kilometrageCible} kms (+{' '}
                                {kilometrageCible - kilometrage!} kms)
                            </Text>
                        </Center>
                    </ModalBody>

                    <ModalFooter>
                        <Button
                            mr={3}
                            colorScheme={'blue'}
                            onClick={(e) => {
                                declarationKilometrique()
                            }}
                            isLoading={updateKilometrageLoading}
                        >
                            Envoyer
                        </Button>
                        <Button onClick={onClose} colorScheme={'orange-light'}>
                            Annuler
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}

export const VeloPanelLoading = () => {
    return (
        <Center>
            <CustomBox
                w={'80%'}
                h={'140px'}
                borderRadius={'15px'}
                padding={'20px'}
                mb={'20px'}
            >
                <Center>
                    <Spinner
                        thickness="4px"
                        speed="0.65s"
                        emptyColor="gray.200"
                        color="blue.500"
                        size="xl"
                        mt={'12px'}
                        mb={'10px'}
                    />
                </Center>
                <Center>
                    <Text>Chargement de vos vélos...</Text>
                </Center>
            </CustomBox>
        </Center>
    )
}

export const VeloPanelEmpty = () => {
    return (
        <Center mb={'20px'}>
            <Text>Aucun vélo a afficher</Text>
        </Center>
    )
}
