/*
 * Page dédiée a l'édition d'un utilisateur en particulier
 */
import {
    Box,
    Text,
    Container,
    Center,
    Spinner,
    Wrap,
    useDisclosure,
    Heading,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    Spacer,
} from '@chakra-ui/react'
import { Utilisateur, Entreprise, Agence, Equipement, Role } from '../../model'
import { GetTargetAgence } from '../../firebaseManager/Communicator/AgenceCommunicator'
import { GetTargetEntreprise } from '../../firebaseManager/Communicator/EntrepriseCommunicator'
import { GetTargetUtilisateur } from '../../firebaseManager/Communicator/UtilisateurCommunicator'
import { useContext, useState, useEffect } from 'react'
import { AuthContext } from '../../auth/AuthContextProvider'
import { useSearchParams } from 'react-router-dom'
import { CustomBox } from '../../components/CustomBox'
import { BreadCrumbBuilder } from '../../components/BreadCrumbBuilder'
import { ConditionalConnectAdminComplex } from '../../auth/ConditionalConnectAdminComplex'
import { AddIcon, CloseIcon, EditIcon, ViewIcon } from '@chakra-ui/icons'
import { CreationEditionUtilisateur } from '../../components/Formulaire/CreationEditionUtilisateur'
import { Button } from '../../components/Button'
import { Link as ReactRouterLink } from 'react-router-dom'
import { GetAllEquipementsForUser } from '../../firebaseManager/Communicator/EquipementCommunicator'
import { CreationEditionEquipement } from '../../components/Formulaire/CreationEditionEquipement'
import { ShowRole } from '../../utils/helpers'
import { ModalChangementAssignation } from '../../components/ModalChangementAssignation'
import { ModalChangementMailUtilisateur } from '../../components/ModalChangementMailUtilisateur'
import { ModalCalendrier } from '../../components/CalendrierUsage/ModalCalendrier'

export const ManageEditUtilisateurs = () => {
    const [targetUtilisateur, setTargetUtilisateur] =
        useState<Utilisateur | null>()
    const [targetEntreprise, setTargetEntreprise] =
        useState<Entreprise | null>()
    const [targetEntrepriseLoaded, setTargetEntrepriseLoaded] =
        useState<boolean>(false)
    const [targetAgence, setTargetAgence] = useState<Agence | null>()
    const [targetAgenceLoaded, setTargetAgenceLoaded] = useState<boolean>(false)

    const [allEquipementsForUser, setAllEquipementsForUser] = useState<
        Equipement[] | null
    >()

    const [searchParams] = useSearchParams()
    const id_utilisateur: string = searchParams.get('id_utilisateur')!

    const authentification = useContext(AuthContext)

    const AgenceLoading: boolean =
        targetUtilisateur?.agenceAppartenance != null &&
        targetUtilisateur?.agenceAppartenance !== '' &&
        !targetAgenceLoaded
    const AgenceNotAssigned: boolean =
        targetUtilisateur?.agenceAppartenance == null ||
        targetUtilisateur?.agenceAppartenance === '' ||
        (targetAgenceLoaded && !targetAgence)
    const AgenceLoaded: boolean = targetAgenceLoaded && targetAgence != null

    const EntrepriseLoading: boolean =
        targetUtilisateur?.entrepriseAppartenance != null &&
        targetUtilisateur?.entrepriseAppartenance !== '' &&
        !targetEntrepriseLoaded
    const EntrepriseNotAssigned: boolean =
        targetUtilisateur?.entrepriseAppartenance == null ||
        targetUtilisateur?.entrepriseAppartenance === '' ||
        (targetEntrepriseLoaded && !targetEntreprise)
    const EntrepriseLoaded: boolean =
        targetEntrepriseLoaded && targetEntreprise != null

    const {
        isOpen: isOpenEditUtilisateur,
        onOpen: onOpenEditUtilisateur,
        onClose: onCloseEditUtilisateur,
    } = useDisclosure()

    const {
        isOpen: isOpenModalAddEquipement,
        onOpen: onOpenModalAddEquipement,
        onClose: onCloseModalEquipement,
    } = useDisclosure()

    function refreshTargetUser(forcer?: boolean) {
        GetTargetUtilisateur(id_utilisateur)
            .then((result) => {
                setTargetUtilisateur(result)
                refreshAllEquipementsForUser()
                if (
                    result?.entrepriseAppartenance &&
                    result?.entrepriseAppartenance !== ''
                ) {
                    refreshTagetEntreprise(result?.entrepriseAppartenance)
                    if (
                        result?.agenceAppartenance &&
                        result?.agenceAppartenance !== ''
                    ) {
                        refreshTagetAgence(result?.agenceAppartenance)
                    }
                }
            })
            .catch((e) => setTargetUtilisateur(null))
    }

    function refreshAllEquipementsForUser() {
        GetAllEquipementsForUser(id_utilisateur)
            .then((result) => {
                setAllEquipementsForUser(result)
            })
            .catch((e) => {
                setAllEquipementsForUser([])
            })
    }
    function refreshTagetEntreprise(id_entreprise: string) {
        GetTargetEntreprise(id_entreprise)
            .then((result) => {
                setTargetEntreprise(result)
                setTargetEntrepriseLoaded(true)
            })
            .catch((e) => {
                setTargetEntreprise(null)
                setTargetEntrepriseLoaded(true)
            })
    }

    function refreshTagetAgence(id_agence: string) {
        GetTargetAgence(id_agence)
            .then((result) => {
                setTargetAgence(result)
                setTargetAgenceLoaded(true)
            })
            .catch((e) => {
                setTargetAgence(null)
                setTargetAgenceLoaded(true)
            })
    }
    useEffect(() => {
        if (authentification.firebaseUser != null) {
            refreshTargetUser()
        }
    }, [authentification])

    return (
        <ConditionalConnectAdminComplex>
            <BreadCrumbBuilder
                items={[
                    {
                        name: 'Utilisateurs',
                        url: '/manage_utilisateurs',
                    },
                    {
                        name:
                            targetUtilisateur?.nom +
                            ' ' +
                            targetUtilisateur?.prenom,
                        url: '',
                    },
                ]}
            />
            <Container maxW="container.lg">
                {targetUtilisateur ? (
                    <>
                        <Center>
                            <Heading>
                                {targetUtilisateur?.nom}{' '}
                                {targetUtilisateur?.prenom}
                            </Heading>
                        </Center>
                        {!isOpenEditUtilisateur && (
                            <CustomBox mt={'30px'}>
                                {targetUtilisateur.role !== 'admin' && (
                                    <EditIcon
                                        boxSize={6}
                                        float={'right'}
                                        onClick={onOpenEditUtilisateur}
                                    />
                                )}

                                <Text as="b" ml={'10px'}>
                                    Information de l'utilisateur{' '}
                                </Text>
                                <Box m={'30px'}>
                                    <Text>
                                        <b>Nom</b> : {targetUtilisateur?.nom}
                                    </Text>
                                    <Text>
                                        <b>Prénom</b> :{' '}
                                        {targetUtilisateur?.prenom}
                                    </Text>
                                    <Text>
                                        <b>Email</b> :{' '}
                                        {targetUtilisateur?.mailContact}
                                    </Text>
                                    <Text>
                                        <b>Téléphone</b> :{' '}
                                        {targetUtilisateur?.telContact}
                                    </Text>
                                    <Text>
                                        <b>Rôle</b> :{' '}
                                        {ShowRole(targetUtilisateur?.role)}
                                    </Text>
                                    {targetUtilisateur?.role !== undefined &&
                                        targetUtilisateur?.role ===
                                            Role.ClientFinal && (
                                            <Text>
                                                <b>Accès vélo Agence ?</b> :{' '}
                                                {targetUtilisateur?.accessVeloAgence
                                                    ? 'Oui'
                                                    : 'Non'}
                                            </Text>
                                        )}

                                    <Wrap mt={'20px'}>
                                        <Spacer />
                                        <Text pt={'10px'} as="b">
                                            Action :
                                        </Text>

                                        <ModalChangementAssignation
                                            mode="utilisateur"
                                            utilisateurId={id_utilisateur}
                                            onSuccess={() => {
                                                refreshTargetUser(true)
                                            }}
                                        />
                                        {targetUtilisateur.role !== 'admin' && (
                                            <ModalChangementMailUtilisateur
                                                utilisateur={targetUtilisateur}
                                                onSuccess={() => {
                                                    refreshTargetUser(true)
                                                }}
                                            />
                                        )}
                                        <ModalCalendrier
                                            userId={targetUtilisateur.id}
                                            titre="Calendrier Usage"
                                            modifiable={true}
                                        />

                                        <Spacer />
                                    </Wrap>
                                </Box>
                            </CustomBox>
                        )}
                        {isOpenEditUtilisateur && (
                            <CustomBox mt={'30px'}>
                                <CloseIcon
                                    color="red"
                                    boxSize={6}
                                    float={'right'}
                                    onClick={onCloseEditUtilisateur}
                                />
                                <Text ml={'10px'} as="b">
                                    Édition de l'utilisateur
                                </Text>
                                <CreationEditionUtilisateur
                                    utilisateur={targetUtilisateur!}
                                    utilisateurId={id_utilisateur}
                                    entrepriseEditable={false}
                                    agenceEditable={false}
                                    roleEditable={true}
                                    onSucess={() => {
                                        refreshTargetUser()
                                        onCloseEditUtilisateur()
                                    }}
                                />
                            </CustomBox>
                        )}
                    </>
                ) : (
                    <CustomBox p={'40px'} ml={'50px'} mr={'50px'} mt={'30px'}>
                        <Center>
                            <Spinner
                                thickness="4px"
                                speed="0.65s"
                                emptyColor="gray.200"
                                color="blue.500"
                                size="xl"
                            />
                        </Center>
                        <Center> Chargement de l'agence</Center>
                    </CustomBox>
                )}
                <Wrap mt={'30px'}>
                    <CustomBox w={'calc(50% - 18px)'} mr={'12px'}>
                        {EntrepriseLoading && (
                            <>
                                <Center>
                                    <Spinner
                                        thickness="4px"
                                        speed="0.65s"
                                        emptyColor="gray.200"
                                        color="blue.500"
                                        size="xl"
                                    />
                                </Center>
                                <Center>Chargement de l'entreprise</Center>
                            </>
                        )}
                        {EntrepriseNotAssigned && (
                            <>
                                <Center>
                                    L'utilisateur n'est pas assigné a une
                                    entreprise
                                </Center>
                            </>
                        )}
                        {EntrepriseLoaded && (
                            <>
                                <Box
                                    float={'right'}
                                    as={ReactRouterLink}
                                    to={
                                        '/edit_enterprise?id_entreprise=' +
                                        targetUtilisateur?.entrepriseAppartenance
                                    }
                                >
                                    <ViewIcon boxSize={6} />
                                </Box>
                                <Text ml={'10px'} as="b">
                                    Information de l'entreprise{' '}
                                </Text>
                                <Box m={'30px'}>
                                    <Text>
                                        <b>Nom</b> : {targetEntreprise?.nom}
                                    </Text>
                                    <Text>
                                        <b>Email</b> :{' '}
                                        {targetEntreprise?.mailContact}
                                    </Text>
                                    <Text>
                                        <b>Téléphone</b> :{' '}
                                        {targetEntreprise?.telContact}
                                    </Text>
                                    <Text>
                                        <b>Type du contrat</b> :{' '}
                                        {targetEntreprise?.typeContrat}
                                    </Text>
                                    <Text>
                                        <b>Numéro de contrat</b> :{' '}
                                        {targetEntreprise?.noContrat}
                                    </Text>
                                </Box>
                            </>
                        )}
                    </CustomBox>

                    <CustomBox w={'calc(50% - 18px)'} ml={'12px'}>
                        {AgenceLoading && (
                            <>
                                <Center>
                                    <Spinner
                                        thickness="4px"
                                        speed="0.65s"
                                        emptyColor="gray.200"
                                        color="blue.500"
                                        size="xl"
                                    />
                                </Center>
                                <Center> Chargement de l'agence</Center>
                            </>
                        )}
                        {AgenceNotAssigned && (
                            <>
                                <Center>
                                    L'utilisateur n'est pas assigné a une agence
                                </Center>
                            </>
                        )}
                        {AgenceLoaded && (
                            <>
                                <Box
                                    float={'right'}
                                    as={ReactRouterLink}
                                    to={
                                        '/edit_agence?id_entreprise=' +
                                        targetUtilisateur?.entrepriseAppartenance +
                                        '&id_agence=' +
                                        targetUtilisateur?.agenceAppartenance
                                    }
                                >
                                    <ViewIcon boxSize={6} />
                                </Box>
                                <Text ml={'10px'} as="b">
                                    Information de l'agence
                                </Text>
                                <Box m={'30px'}>
                                    <Text>
                                        <b>Nom</b>: {targetAgence?.nom}
                                    </Text>
                                    <Text>
                                        <b>Email</b> :{' '}
                                        {targetAgence?.mailContact}
                                    </Text>
                                    <Text>
                                        <b>Téléphone</b> :{' '}
                                        {targetAgence?.telContact}
                                    </Text>
                                </Box>
                            </>
                        )}
                    </CustomBox>
                </Wrap>

                <Center>
                    <Heading mt={'30px'}>Équipements</Heading>
                </Center>
                <Button onClick={onOpenModalAddEquipement} p={4} mt={'30px'}>
                    <AddIcon /> Créer un équipement
                </Button>

                <Box mt={'30px'}>
                    {allEquipementsForUser == null && (
                        <CustomBox p={'40px'} ml={'50px'} mr={'50px'}>
                            <Center>
                                <Spinner
                                    thickness="4px"
                                    speed="0.65s"
                                    emptyColor="gray.200"
                                    color="blue.500"
                                    size="xl"
                                />
                            </Center>
                            <Center>Chargement des équipements</Center>
                        </CustomBox>
                    )}
                    {allEquipementsForUser &&
                        allEquipementsForUser?.length === 0 && (
                            <CustomBox p={'40px'} ml={'50px'} mr={'50px'}>
                                <Center>Aucun équipement à afficher</Center>
                            </CustomBox>
                        )}
                    {allEquipementsForUser &&
                        allEquipementsForUser?.length > 0 && (
                            <Wrap>
                                {allEquipementsForUser?.map(function (
                                    equipement,
                                    idx
                                ) {
                                    return (
                                        <CustomBox
                                            pl={'25px'}
                                            pr={'25px'}
                                            pt={'10px'}
                                            pb={'10px'}
                                            ml={'5px'}
                                            mr={'5px'}
                                            mb={'15px'}
                                            w={'calc(33% - 15px)'}
                                            key={idx}
                                        >
                                            <Box
                                                float={'right'}
                                                as={ReactRouterLink}
                                                to={
                                                    '/edit_equipement?id_equipement=' +
                                                    equipement.id
                                                }
                                            >
                                                <ViewIcon boxSize={6} />
                                            </Box>
                                            <Text as="b" fontSize="lg">
                                                {equipement.categorie}
                                            </Text>

                                            <Text fontSize="sm">
                                                <b>Marque :</b>{' '}
                                                {equipement.marque}
                                            </Text>
                                            <Text fontSize="sm">
                                                <b>Modèle :</b>{' '}
                                                {equipement.modele}
                                            </Text>
                                            <Text fontSize="sm">
                                                <b>N° Série :</b>{' '}
                                                {equipement.numeroSerie}
                                            </Text>
                                            <Text fontSize="sm">
                                                <b>N° Marquage :</b>{' '}
                                                {equipement.numeroMarquage}
                                            </Text>
                                        </CustomBox>
                                    )
                                })}
                            </Wrap>
                        )}
                </Box>
            </Container>

            <Modal
                isOpen={isOpenModalAddEquipement}
                onClose={onCloseModalEquipement}
                size={'xl'}
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Créer une agence</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <CreationEditionEquipement
                            utilisateurId={id_utilisateur}
                            utilisateurEditable={false}
                            entrepriseId={
                                targetUtilisateur?.entrepriseAppartenance
                            }
                            entrepriseEditable={false}
                            agenceId={targetUtilisateur?.agenceAppartenance}
                            agenceEditable={false}
                            onSucess={() => {
                                refreshAllEquipementsForUser()
                                onCloseModalEquipement()
                            }}
                        />
                    </ModalBody>
                </ModalContent>
            </Modal>
        </ConditionalConnectAdminComplex>
    )
}
