/*
Classe utilitaire pour gerer des affichages en fonction de certaine conditions.
Affichage si Connecté / utilisateur récupéré / role de l'utilisateur
Permet de gérer l'affache d'un élément mais ne garantie pas la sécurité des datas! 
*/

import { useContext } from 'react'
import { AuthContext } from './AuthContextProvider'
import { Role } from '../model'
import { UtilisateurContext } from './UtilisateurContextProvider'

interface Props {
    children: React.ReactNode
    showIfNotConnected?: Boolean
    hideIfNotConnected?: Boolean
    showIfConnected?: Boolean
    hideIfConnected?: Boolean
    showIfUserInformationCollected?: Boolean
    hideIfUserInformationCollected?: Boolean
    showIfUserInformationNotCollected?: Boolean
    hideIfUserInformationNotCollected?: Boolean
    showIfAdmin?: Boolean
    hideIfAdmin?: Boolean
    showIfNotAdmin?: Boolean
    hideIfNotAdmin?: Boolean
    showIfUserCanAccessParc?: Boolean
    showIfReparateur?: Boolean
    showIfNotReparateur?: Boolean
}

export const ConditionalConnect = (props: Props) => {
    const authentification = useContext(AuthContext)
    const utilisateur = useContext(UtilisateurContext)

    // Condition lié a la connexion avec firebase
    if (authentification.firebaseUser == null && props.showIfNotConnected) {
        return <>{props.children}</>
    } else if (
        authentification.firebaseUser == null &&
        props.hideIfNotConnected
    ) {
        return <></>
    } else if (authentification.firebaseUser && props.showIfConnected) {
        return <>{props.children}</>
    } else if (authentification.firebaseUser && props.hideIfConnected) {
        return <></>
    }
    // Condition lié a la récupération du profil utilisateur
    else if (utilisateur && props.showIfUserInformationCollected) {
        return <>{props.children}</>
    } else if (utilisateur && props.hideIfUserInformationCollected) {
        return <></>
    } else if (!utilisateur && props.showIfUserInformationNotCollected) {
        return <>{props.children}</>
    } else if (!utilisateur && props.hideIfUserInformationNotCollected) {
        return <></>
    }
    // Condition lié a la possession théorique de role
    else if (
        authentification.firebaseUser &&
        utilisateur?.role === Role.Admin &&
        props.showIfAdmin
    ) {
        return <>{props.children}</>
    } else if (
        authentification.firebaseUser &&
        utilisateur?.role === Role.Admin &&
        props.hideIfAdmin
    ) {
        return <></>
    } else if (
        authentification.firebaseUser &&
        utilisateur?.role !== Role.Admin &&
        props.showIfNotAdmin
    ) {
        return <>{props.children}</>
    } else if (
        authentification.firebaseUser &&
        utilisateur?.role !== Role.Admin &&
        props.hideIfNotAdmin
    ) {
        return <></>
    } else if (
        props.showIfUserCanAccessParc &&
        authentification.firebaseUser &&
        (utilisateur?.role === Role.ManagerAgence ||
            utilisateur?.role === Role.ManagerEntreprise ||
            utilisateur?.accessVeloAgence)
    ) {
        return <>{props.children}</>
    } else if (
        props.showIfReparateur &&
        utilisateur?.role === Role.Reparateur
    ) {
        return <>{props.children}</>
    } else if (
        props.showIfNotReparateur &&
        utilisateur?.role !== Role.Reparateur
    ) {
        return <>{props.children}</>
    } else {
        return <></>
    }
}
