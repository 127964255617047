/*
 * bouton ouvrant une modal permettant de consulter l'enssemble des lignes journal pour un équipement donné
 */

import {
    Box,
    Center,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Spinner,
    Text,
    useDisclosure,
} from '@chakra-ui/react'
import { Button } from '../Button'
import { GetAllJournalForTargetEquipement } from '../../firebaseManager/Communicator/JournalCommunicator'
import { useContext, useEffect, useState } from 'react'
import { AuthContext } from '../../auth/AuthContextProvider'
import { Action, Journal } from '../../model'
import { Link as ReactRouterLink } from 'react-router-dom'
import { CustomBox } from '../CustomBox'
import moment from 'moment'
import 'moment/locale/fr' // without this line it didn't work
moment.locale('fr')
export type Props = {
    equipementId: string
    modal?: boolean
    modeReparateur?: boolean
}

export const VoirJournal = (props: Props) => {
    const authentification = useContext(AuthContext)
    const [allJournal, setAllJournal] = useState<Journal[] | null>()
    const [allJournalLoaded, setAllJournalLoaded] = useState<boolean>(false)
    const { isOpen, onOpen, onClose } = useDisclosure()

    function RefreshAllJournalForTargetEquipement() {
        GetAllJournalForTargetEquipement(props.equipementId)
            .then((result) => {
                setAllJournal(result)
                setAllJournalLoaded(true)
            })
            .catch((e) => {
                setAllJournal(null)
                setAllJournalLoaded(true)
            })
    }

    useEffect(() => {
        if (authentification.firebaseUser != null && (isOpen || !props.modal)) {
            RefreshAllJournalForTargetEquipement()
        }
    }, [authentification, isOpen])

    let componentToShow = (
        <>
            {!allJournalLoaded && (
                <>
                    <Center mb={'15px'}>
                        <Spinner
                            thickness="4px"
                            speed="0.65s"
                            emptyColor="gray.200"
                            color="blue.500"
                            size="xl"
                        />
                    </Center>
                    <Center>
                        <Text>Chargement de l'historique...</Text>
                    </Center>
                </>
            )}
            {allJournalLoaded && allJournal?.length === 0 && (
                <>Aucun historique disponible pour le moment</>
            )}
            {allJournalLoaded && allJournal?.length !== 0 && (
                <>
                    {allJournal?.map(function (journal, idx) {
                        return (
                            <TuileJournal
                                key={idx}
                                journal={journal}
                                avecLien={!props.modeReparateur}
                            />
                        )
                    })}
                </>
            )}
        </>
    )

    return (
        <>
            {props.modal && (
                <>
                    <Button onClick={onOpen}>
                        Voir l'historique en détail
                    </Button>
                    <Modal size={'xl'} isOpen={isOpen} onClose={onClose}>
                        <ModalOverlay />
                        <ModalContent>
                            <ModalHeader>Journal d'activité</ModalHeader>
                            <ModalCloseButton />
                            <ModalBody>{componentToShow}</ModalBody>
                            <Center mb={'10px'}>
                                <Button
                                    colorScheme="blue"
                                    mr={3}
                                    onClick={onClose}
                                >
                                    Fermer
                                </Button>
                            </Center>
                        </ModalContent>
                    </Modal>
                </>
            )}
            {!props.modal && <>{componentToShow}</>}
        </>
    )
}

export type PropsTuileJournal = {
    journal: Journal
    avecLien?: boolean
}

const TuileJournal = (props: PropsTuileJournal) => {
    let type = ''
    switch (props.journal.action) {
        case Action.Assignation:
            type = 'Assignation'
            break
        case Action.DeclarationKLM:
            type = 'Déclaration Kilométrique'
            break
        case Action.DeclarationPb:
            type = 'Déclaration Problème'
            break
        case Action.DeclarationRep:
            type = 'Déclaration Réparation'
            break
        case Action.MessageTextuel:
            type = 'Message'
            break
        case Action.Contact:
            type = 'Contact du client'
            break
        case Action.DeclarationMaint:
            type = 'Déclaration Maintenance'
            break
        default:
            type = 'Inconnue'
    }

    return (
        <>
            <CustomBox mb={'10px'}>
                {props.journal.utilisateurInitiateurNomPrenom && (
                    <Box float={'right'}>
                        {props.avecLien && (
                            <Text
                                fontSize="sm"
                                as={ReactRouterLink}
                                to={
                                    '/edit_utilisateur?id_utilisateur=' +
                                    props.journal.utilisateurInitiateurID
                                }
                            >
                                Par{' '}
                                {props.journal.utilisateurInitiateurNomPrenom}
                            </Text>
                        )}
                        {!props.avecLien && (
                            <Text fontSize="sm">
                                Par{' '}
                                {props.journal.utilisateurInitiateurNomPrenom}
                            </Text>
                        )}
                    </Box>
                )}
                <Text as="b">{type}</Text>
                <br />
                <Text as="i" fontSize="sm">
                    {moment(props.journal.dateAction).format('DD MMMM YYYY')}
                </Text>
                <Text as="i" fontSize="sm" ml={'7px'}>
                    {moment(props.journal.dateAction).format('HH:mm:ss')}
                </Text>
                {props.journal.lieu && (
                    <>
                        <Text as="i" fontSize="sm">
                            {' | '}
                            <b>{props.journal.lieu}</b>
                        </Text>
                    </>
                )}

                {props.journal.valueDate &&
                    props.journal.action === Action.DeclarationMaint && (
                        <Text>
                            Maintenance réalisé le :{' '}
                            {moment(props.journal.valueDate).format(
                                'DD/MM/YYYY'
                            )}
                        </Text>
                    )}
                <Text mt={'5px'}>
                    <b>{props.journal.valueNumber}</b>{' '}
                    {props.journal.action === Action.DeclarationKLM && (
                        <>Kilomètres</>
                    )}
                </Text>
                <Text>{props.journal.valueString}</Text>
            </CustomBox>
        </>
    )
}
