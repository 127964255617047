/*
 * Page racine regrouppant les Context provider et le système de routage
 */

import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import { AuthContextProvider } from './auth/AuthContextProvider'
import { UtilisateurContextProvider } from './auth/UtilisateurContextProvider'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { ChakraProvider } from '@chakra-ui/react'

import { ResetPassword } from './pages/Authentification/ResetPassword'
import { NotFound } from './pages/Erreur/NotFound'

import { Login } from './pages/Authentification/Login'
import { Logout } from './pages/Authentification/Logout'

import { ManageEquipements } from './pages/ManageEquipements'
import { ManageEnterprises } from './pages/ManageEnterprises'
import { ManageUtilisateurs } from './pages/ManageUtilisateurs'

import { MonCompte } from './pages/MonCompte'
import { MyEquipments } from './pages/MyEquipments'
import { MyEnterpriseEquipments } from './pages/MyEnterpriseEquipments'

import NavBar from './components/NavBar'
import Footer from './components/Footer'
import { EntrepriseContextProvider } from './auth/EntrepriseContextProvider'
import { AgenceContextProvider } from './auth/AgenceContextProvider'
import { ManageEditEntreprise } from './pages/ManageEditEntreprise'
import { ManageEditAgence } from './pages/ManageEditAgence'
import { ManageEditUtilisateurs } from './pages/ManageEditUtilisateurs'
import { ManageEditEquipement } from './pages/ManageEditEquipement'
import { ActionFirebase } from './pages/Authentification/ActionFirebase'
import { ReparateurView } from './pages/ReperateurView'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
    <React.StrictMode>
        <ChakraProvider>
            <Router>
                <AuthContextProvider>
                    <UtilisateurContextProvider>
                        <EntrepriseContextProvider>
                            <AgenceContextProvider>
                                <App />
                            </AgenceContextProvider>
                        </EntrepriseContextProvider>
                    </UtilisateurContextProvider>
                </AuthContextProvider>
            </Router>
        </ChakraProvider>
    </React.StrictMode>
)

function App() {
    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                minHeight: '100vh',
                backgroundColor: '#f5f5f5',
            }}
        >
            <NavBar />
            <Routes>
                <Route
                    path="/manage_equipements"
                    Component={ManageEquipements}
                />
                <Route
                    path="/manage_enterprises"
                    Component={ManageEnterprises}
                />
                <Route
                    path="/edit_enterprise"
                    Component={ManageEditEntreprise}
                />
                <Route
                    path="/edit_utilisateur"
                    Component={ManageEditUtilisateurs}
                />
                <Route
                    path="/edit_equipement"
                    Component={ManageEditEquipement}
                />

                <Route path="/edit_agence" Component={ManageEditAgence} />
                <Route
                    path="/manage_utilisateurs"
                    Component={ManageUtilisateurs}
                />
                <Route path="/" Component={Login} />

                <Route path="/mes_equipements" Component={MyEquipments} />
                <Route
                    path="/mes_equipements_entreprise"
                    Component={MyEnterpriseEquipments}
                />

                <Route path="/vue_reparateur" Component={ReparateurView} />

                <Route path="/mon_compte" Component={MonCompte} />
                <Route path="/login" Component={Login} />
                {/*<Route path="/create_account" Component={CreateAccount} />*/}
                <Route path="/reset_password" Component={ResetPassword} />
                <Route path="/action_fb" Component={ActionFirebase} />
                <Route path="/logout" Component={Logout} />
                <Route path="*" Component={NotFound} />
            </Routes>
            <div
                style={{
                    marginTop: 'auto',
                }}
            >
                <Footer />
            </div>
        </div>
    )
}
