/**
 * Context provider pour fournir les informations de l'entreprise de l'utilisateur connecté
 */

import React, { ReactNode, useState, useEffect, useContext } from 'react'
import { GetMyEntreprise } from '../firebaseManager/Communicator/EntrepriseCommunicator'
import { Entreprise } from '../model'
import { UtilisateurContext } from './UtilisateurContextProvider'

export const EntrepriseContext = React.createContext<Entreprise | undefined>(
    undefined
)

interface Props {
    children: ReactNode
}

export const EntrepriseContextProvider = (props: Props) => {
    const utilisateur = useContext(UtilisateurContext)
    const [entreprise, setEntreprise] = useState<Entreprise | undefined>()

    useEffect(() => {
        if (utilisateur?.entrepriseAppartenance != null) {
            GetMyEntreprise(utilisateur?.entrepriseAppartenance)
                .then((result) => {
                    console.log('OK Entreprise : ')
                    console.log(result)
                    setEntreprise(result)
                })
                .catch((e) => {
                    console.log('ERR Entreprise ' + e)
                    setEntreprise(undefined)
                })
        }
    }, [utilisateur])

    return (
        <EntrepriseContext.Provider value={entreprise}>
            {props.children}
        </EntrepriseContext.Provider>
    )
}
