/*
Classe utilitaire pour gerer des affichages en fonction de certaine conditions de role.

*/

import { useContext } from 'react'
import { Role } from '../model'
import { UtilisateurContext } from './UtilisateurContextProvider'

interface Props {
    children: React.ReactNode
    roleAlowed?: Role[]
    forReparator?: boolean
    forUser?: boolean
    forAdmin?: boolean
}

export const ShowRoleFilter = (props: Props) => {
    const utilisateur = useContext(UtilisateurContext)
    let roleAlowed: Role[] = props.roleAlowed ? props.roleAlowed : []

    if (props.forReparator) {
        roleAlowed.push(Role.Reparateur)
    } else if (props.forUser) {
        roleAlowed.push(Role.ClientFinal)
        roleAlowed.push(Role.ManagerAgence)
        roleAlowed.push(Role.ManagerEntreprise)
    } else if (props.forAdmin) {
        roleAlowed.push(Role.Admin)
    }

    if (utilisateur?.role && roleAlowed.includes(utilisateur.role)) {
        return <>{props.children}</>
    }

    return <></>
}
