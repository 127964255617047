/*
 * Class d'intération avec firestore pour les éléments : Utilisateur
 */
import { Utilisateur } from '../../../model'
import { db } from '../..'
import {
    collection,
    query,
    doc,
    getDocs,
    deleteDoc,
    where,
    documentId,
    orderBy,
    limit,
    QueryDocumentSnapshot,
    DocumentData,
    startAfter,
} from 'firebase/firestore'

export async function GetTargetUtilisateur(
    myUserUid: string
): Promise<Utilisateur | undefined> {
    const q = query(
        collection(db, 'utilisateurs'),
        where(documentId(), '==', myUserUid),
        limit(1)
    )
    const querySnapshot = await getDocs(q)
    if (!querySnapshot.empty) {
        return {
            id: querySnapshot.docs[0].id,
            ...querySnapshot.docs[0].data(),
        } as Utilisateur
    }
    return undefined
}

export async function GetAllFilteredUtilisateurs(
    name: string,
    prenom: string,
    mailContact: string,
    entreprise: string,
    agence: string,
    last: QueryDocumentSnapshot<DocumentData, DocumentData> | null
): Promise<{
    list: Utilisateur[]
    last: QueryDocumentSnapshot<DocumentData, DocumentData>
}> {
    let q = query(collection(db, 'utilisateurs'), limit(10))
    if (name) {
        q = query(
            q,
            where('nom', '>=', name),
            where('nom', '<=', name + '\uf8ff')
        )
    }
    if (prenom) {
        q = query(
            q,
            where('prenom', '>=', prenom),
            where('prenom', '<=', prenom + '\uf8ff')
        )
    }
    if (mailContact) {
        q = query(
            q,
            where('mailContact', '>=', mailContact),
            where('mailContact', '<=', mailContact + '\uf8ff')
        )
    }
    if (entreprise) {
        q = query(q, where('entrepriseAppartenance', '==', entreprise))

        if (agence) {
            q = query(q, where('agenceAppartenance', '==', agence))
        }
    }
    if (!mailContact && !prenom) {
        q = query(q, orderBy('nom', 'asc'))
    }

    if (last) {
        q = query(q, startAfter(last))
    }
    const querySnapshot = await getDocs(q)
    const allUtilisateurs: Utilisateur[] = querySnapshot.docs.map((doc) => {
        return { id: doc.id, ...doc.data() } as Utilisateur
    })
    return {
        list: allUtilisateurs,
        last: querySnapshot.docs[querySnapshot.docs.length - 1],
    }
}

export async function GetAllUtilisateurs(): Promise<Utilisateur[]> {
    const q = query(collection(db, 'utilisateurs'), orderBy('nom', 'asc'))
    const querySnapshot = await getDocs(q)
    const allUtilisateurs: Utilisateur[] = querySnapshot.docs.map((doc) => {
        return { id: doc.id, ...doc.data() } as Utilisateur
    })
    return allUtilisateurs
}

export async function GetAllUtilisateursTargetEntrepriseAgence(
    entrepriseId: string,
    agenceId: string
): Promise<Utilisateur[]> {
    let q = query(collection(db, 'utilisateurs'), orderBy('nom', 'asc'))
    if (entrepriseId) {
        q = query(q, where('entrepriseAppartenance', '==', entrepriseId))

        if (agenceId) {
            q = query(q, where('agenceAppartenance', '==', agenceId))
        }
    }
    const querySnapshot = await getDocs(q)
    const allUtilisateurs: Utilisateur[] = querySnapshot.docs.map((doc) => {
        return { id: doc.id, ...doc.data() } as Utilisateur
    })
    return allUtilisateurs
}

export async function GetAllUtilisateursTargetAgence(
    agenceId: string
): Promise<Utilisateur[]> {
    let q = query(collection(db, 'utilisateurs'), orderBy('nom', 'asc'))
    if (agenceId) {
        q = query(q, where('agenceAppartenance', '==', agenceId))
    }
    const querySnapshot = await getDocs(q)
    const allUtilisateurs: Utilisateur[] = querySnapshot.docs.map((doc) => {
        return { id: doc.id, ...doc.data() } as Utilisateur
    })
    return allUtilisateurs
}

export async function DeleteOneUtilisateur(
    utilisateurId: string
): Promise<void> {
    await deleteDoc(doc(db, 'utilisateurs', utilisateurId))
}
