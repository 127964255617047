/*
 * Graphique de l'historique de kilometrage vélo
 * Récupére les (10) lignes de journal de déclaration kilométrique de l'équipement pour construire un graphique
 */

import { Box, Center, Spinner, Text } from '@chakra-ui/react'
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    Tooltip,
    ResponsiveContainer,
} from 'recharts'
import { Equipement, Journal } from '../../model'
import { useContext, useEffect, useState } from 'react'
import { AuthContext } from '../../auth/AuthContextProvider'
import { GetAllJournalForHistoriqueKilometrageForTargetEquipement } from '../../firebaseManager/Communicator/JournalCommunicator'
import moment from 'moment'

export type Props = {
    equipement: Equipement
}

export const GraphiqueKilometrageVelo = (props: Props) => {
    const authentification = useContext(AuthContext)
    const [historiqueKilometrage, setHistoriqueKilometrage] = useState<
        Journal[] | null
    >()
    function refreshHistoriqueKilometrage() {
        GetAllJournalForHistoriqueKilometrageForTargetEquipement(
            props.equipement.id
        )
            .then((result) => {
                setHistoriqueKilometrage(
                    result.sort((a, b) =>
                        a.dateAction < b.dateAction ? -1 : 1
                    )
                )
            })
            .catch((e) => {
                console.log(e)

                setHistoriqueKilometrage(null)
            })
    }

    useEffect(() => {
        refreshHistoriqueKilometrage()
    }, [authentification])

    function dateFormatter(date: number) {
        return moment(date).format('DD/MM/YY')
    }

    function CustomTooltip({ active, payload, label }: any) {
        if (active && payload && payload.length) {
            return (
                <Box
                    className="custom-tooltip"
                    backgroundColor={'#dcddde'}
                    padding={'10px'}
                    borderRadius={'10px'}
                >
                    <Center>{dateFormatter(label)}</Center>
                    <Center color={'#31a1d7'}>
                        {' '}
                        <b>{payload[0].value} Kms</b>
                    </Center>
                </Box>
            )
        }

        return null
    }

    return (
        <>
            {historiqueKilometrage !== null &&
                historiqueKilometrage !== undefined &&
                historiqueKilometrage.length > 0 && (
                    <ResponsiveContainer>
                        <LineChart data={historiqueKilometrage}>
                            <Line
                                type="monotone"
                                dataKey="valueNumber"
                                stroke="#31a1d7"
                                name="Distance totale"
                                strokeWidth={3}
                            />
                            {/**    XAxis ajouter scale="time" si on veux une adaptation par rapport au temps
                             */}
                            <XAxis
                                dataKey="dateAction"
                                tickFormatter={dateFormatter}
                            />
                            <YAxis
                                type="number"
                                dataKey="valueNumber"
                                domain={[
                                    historiqueKilometrage[0].valueNumber!,
                                    historiqueKilometrage[
                                        historiqueKilometrage.length - 1
                                    ].valueNumber!,
                                ]}
                            />
                            <Tooltip content={<CustomTooltip />} />
                            <Tooltip />
                        </LineChart>
                    </ResponsiveContainer>
                )}

            {historiqueKilometrage === undefined ||
                (historiqueKilometrage !== null &&
                    historiqueKilometrage !== undefined &&
                    historiqueKilometrage.length === 0 && (
                        <>
                            <Center mt="70px">
                                <Text as="b">
                                    Données indisponibles pour le graphique.
                                </Text>
                            </Center>
                        </>
                    ))}

            {historiqueKilometrage === null && (
                <>
                    <Center mt="40px">
                        <Spinner
                            thickness="4px"
                            speed="0.65s"
                            emptyColor="white"
                            color="black"
                            size="xl"
                        />{' '}
                    </Center>
                    <Center mt="20px">
                        <Text as="b">Chargement des données du graphique</Text>
                    </Center>
                </>
            )}
        </>
    )
}
