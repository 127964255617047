/*
 * Ensemble des modèles utilisés par l'application
 */

export enum ColorPalette {
    dark = '#27292c',
    gray_light = '#dcddde',
    gray_dark = '#7d7d7d',
    blue_light = '#31a1d7',
    blue_dark = '#2d7bb6',
}

export enum TypeContrat {
    Leasing = 'Leasing',
    Achat = 'Achat',
}
export enum Role {
    ClientFinal = 'ClientFinal',
    ManagerEntreprise = 'ManagerEntreprise',
    ManagerAgence = 'ManagerAgence',
    Reparateur = 'Reparateur',
    Admin = 'admin',
}

export enum CategorieEquipement {
    Velo = 'Velo',
    Casque = 'Casque',
    Saccoche = 'Saccoche',
    AirTag = 'AirTag',
}

export enum Action {
    Assignation = 'Assignation',
    DeclarationKLM = 'DeclarationKLM',
    DeclarationPb = 'DeclarationPb',
    DeclarationRep = 'DeclarationRep',
    MessageTextuel = 'MessageTextuel',
    Contact = 'Contact',
    DeclarationMaint = 'DeclarationMaint',
}

export type Entreprise = {
    id: string
    nom?: string
    mailContact?: string
    telContact?: string
    adresse?: string
    noContrat?: string
    typeContrat?: TypeContrat
    telAssurance?: string
    mailAssurance?: string
    formuleAssurance?: string
    contratAssurance?: string
    contratEntretien?: string
    interlocuteur?: string
}

export type Agence = {
    id: string
    nom?: string
    mailContact?: string
    telContact?: string
    adresse?: string
    entrepriseAppartenance?: string //Agence
    interlocuteur?: string
}

export type Utilisateur = {
    id: string
    nom?: string
    prenom?: string
    mailContact?: string
    telContact?: string
    entrepriseAppartenance?: string //Entreprise
    agenceAppartenance?: string //Agence
    accessVeloAgence?: boolean //Agence
    role?: Role
}

export type Equipement = {
    id: string
    categorie?: CategorieEquipement
    marque?: string
    modele?: string
    numeroSerie?: string
    numeroMarquage?: string
    entrepriseAppartenance?: string //Entreprise
    agenceAppartenance?: string //Agence
    utilisateurAppartenance?: string //Utilisateur
    kilometrage?: number
    dateKilometrage?: number
    hivernage?: boolean
    numeroDeCle?: string
    avecDateMiseEnService?: boolean
    dateMiseEnService?: number
    dateDerniereMaintenance?: number
    taille?: string
}

export type Journal = {
    id?: string
    dateAction: number
    utilisateurInitiateurID?: String
    utilisateurInitiateurNomPrenom?: String
    action: Action
    valueNumber?: number
    valueString?: String
    valueDate?: number
    utilisateurCibleID?: String
    utilisateurCibleNomPrenom?: String
    equipementCibleID?: String
    lieu?: string
}

export type CalendrierUsage = {
    id?: string
    year: number
    month: number
    entrepriseAppartenance?: string // Entreprise
    agenceAppartenance?: string // Agence
    utilisateurAppartenance?: string // Utilisateur
    usage: boolean[]
}

export type CalendrierUsageAggregate = {
    id?: string
    year: number
    month: number
    dateOfLastUpdate: number
    entrepriseAppartenance?: string // Entreprise
    agenceAppartenance?: string // Agence
    usage: number[]
    details?: CalendrierUsageAggregateDetail[]
}

export type CalendrierUsageAggregateDetail = {
    id: string
    nom: string
    usage: boolean[]
}
