/*
 * Formulaire autonome et réutilisable permettant de créer ou d'éditer un objet Utilisateur
 */

import { db } from '../../../firebaseManager'
import {
    FormControl,
    Input,
    InputGroup,
    InputLeftAddon,
    useToast,
    Center,
    Select,
    Box,
    Checkbox,
    Text,
    RadioGroup,
    Stack,
    Radio,
} from '@chakra-ui/react'
import { collection, doc, setDoc, updateDoc } from 'firebase/firestore'
import { Button } from '../../Button'
import { FormikProvider, useFormik } from 'formik'
import * as yup from 'yup'
import { CategorieEquipement, Equipement } from '../../../model'
import { SingleDatepicker } from 'chakra-dayzed-datepicker'

const validationSchema = yup.object().shape({
    categorie: yup.string().required(),
    marque: yup.string().required(),
    modele: yup.string().required(),
    taille: yup.string().optional(),
    numeroSerie: yup.string(),
    numeroMarquage: yup.string(),
    entrepriseAppartenance: yup.string().required(),
    agenceAppartenance: yup.string().required(),
    utilisateurAppartenance: yup.string(),
    hivernage: yup.boolean(),
    numeroDeCle: yup.string(),
    avecDateMiseEnService: yup.boolean(),
    dateMiseEnService: yup.number(),
})

export type AddEquipementValue = {
    categorie: string
    marque: string
    modele: string
    taille?: string
    numeroSerie: string
    numeroMarquage: string
    entrepriseAppartenance: string
    agenceAppartenance: string
    utilisateurAppartenance: string
    kilometrage: number
    dateKilometrage: number
    hivernage: boolean
    numeroDeCle: string
    avecDateMiseEnService: boolean
    dateMiseEnService?: number
}

export type Props = {
    equipement?: Equipement
    equipementId?: string

    utilisateurEditable?: boolean
    utilisateurId?: string

    entrepriseEditable: boolean
    entrepriseId?: string

    agenceEditable: boolean
    agenceId?: string

    onSucess?: () => void
}

export const CreationEditionEquipement = (props: Props) => {
    const editMode =
        props.equipement != null &&
        props.equipement !== undefined &&
        props.equipementId != null

    const toast = useToast()

    const formikAddEquipement = useFormik({
        validationSchema,
        initialValues: {
            categorie:
                editMode && props.equipement!.categorie
                    ? props.equipement!.categorie
                    : '',
            marque:
                editMode && props.equipement!.marque
                    ? props.equipement!.marque
                    : '',
            modele:
                editMode && props.equipement!.modele
                    ? props.equipement!.modele
                    : '',
            taille:
                editMode && props.equipement!.taille
                    ? props.equipement!.taille
                    : '',
            numeroSerie:
                editMode && props.equipement!.numeroSerie
                    ? props.equipement!.numeroSerie
                    : '',
            numeroMarquage:
                editMode && props.equipement!.numeroMarquage
                    ? props.equipement!.numeroMarquage
                    : '',
            entrepriseAppartenance: props.entrepriseId
                ? props.entrepriseId
                : editMode && props.equipement!.entrepriseAppartenance
                ? props.equipement!.entrepriseAppartenance
                : '',
            agenceAppartenance: props.agenceId
                ? props.agenceId
                : editMode && props.equipement!.agenceAppartenance
                ? props.equipement!.agenceAppartenance
                : '',
            utilisateurAppartenance: props.utilisateurId
                ? props.utilisateurId
                : editMode && props.equipement!.utilisateurAppartenance
                ? props.equipement!.utilisateurAppartenance
                : '',
            kilometrage:
                editMode && props.equipement!.kilometrage
                    ? props.equipement!.kilometrage
                    : 1,
            dateKilometrage:
                editMode && props.equipement!.dateKilometrage
                    ? props.equipement!.dateKilometrage
                    : Date.now(),
            hivernage:
                editMode && props.equipement!.hivernage
                    ? props.equipement!.hivernage
                    : false,
            numeroDeCle:
                editMode && props.equipement!.numeroDeCle
                    ? props.equipement!.numeroDeCle
                    : '',

            avecDateMiseEnService:
                editMode && props.equipement!.avecDateMiseEnService
                    ? props.equipement!.avecDateMiseEnService
                    : false,
            dateMiseEnService:
                editMode && props.equipement!.dateMiseEnService
                    ? props.equipement!.dateMiseEnService
                    : new Date().getTime(),
        },
        onSubmit: (values: AddEquipementValue, actions) => {
            if (editMode) {
                updateDoc(doc(db, 'equipements/' + props.equipementId), {
                    ...values,
                })
                    .then((result) => {
                        actions.setSubmitting(false)
                        actions.resetForm()
                        if (props.onSucess) props.onSucess()
                        toast({
                            title: 'Équipement mis à jour!',
                            description: '',
                            status: 'success',
                            duration: 2000,
                            isClosable: true,
                        })
                    })
                    .catch((e) => {
                        console.log('error on edit ' + e)
                        actions.setSubmitting(false)
                    })
            } else {
                setDoc(doc(collection(db, 'equipements')), values)
                    .then((result) => {
                        actions.setSubmitting(false)
                        actions.resetForm()
                        if (props.onSucess) props.onSucess()
                        toast({
                            title: 'Équipement créé!',
                            description: '',
                            status: 'success',
                            duration: 2000,
                            isClosable: true,
                        })
                    })
                    .catch((e) => {
                        console.log('error on add' + e)
                        actions.setSubmitting(false)
                    })
            }
        },
    })
    return (
        <FormikProvider value={formikAddEquipement}>
            <FormControl id="categorie" mt={4}>
                <InputGroup>
                    <InputLeftAddon>
                        Catégorie
                        <Text color={'red'}>*</Text>
                    </InputLeftAddon>
                    <Select
                        placeholder="Selectionner la catégorie"
                        name="categorie"
                        onChange={formikAddEquipement.handleChange}
                        value={formikAddEquipement.values.categorie}
                    >
                        {Object.keys(CategorieEquipement).map(function (
                            cat,
                            idx
                        ) {
                            return (
                                <option key={idx} value={cat.toString()}>
                                    {cat.toString()}
                                </option>
                            )
                        })}
                    </Select>
                </InputGroup>
            </FormControl>
            <FormControl id="marque" mt={4}>
                <InputGroup>
                    <InputLeftAddon>
                        Marque
                        <Text color={'red'}>*</Text>
                    </InputLeftAddon>
                    <Input
                        name="marque"
                        type="text"
                        onChange={formikAddEquipement.handleChange}
                        value={formikAddEquipement.values.marque}
                    />
                </InputGroup>
            </FormControl>
            <FormControl id="modele" mt={4}>
                <InputGroup>
                    <InputLeftAddon>
                        Modèle
                        <Text color={'red'}>*</Text>
                    </InputLeftAddon>
                    <Input
                        name="modele"
                        type="text"
                        onChange={formikAddEquipement.handleChange}
                        value={formikAddEquipement.values.modele}
                    />
                </InputGroup>
            </FormControl>
            <FormControl id="taille" mt={4}>
                <InputGroup>
                    <InputLeftAddon>Taille</InputLeftAddon>
                    <RadioGroup
                        ml={'15px'}
                        mt={'5px'}
                        name="taille"
                        onChange={(value) =>
                            formikAddEquipement.setFieldValue('taille', value)
                        }
                        value={formikAddEquipement.values.taille}
                    >
                        <Stack direction="row" spacing={'20px'}>
                            <Radio value="TU">TU</Radio>
                            <Radio value="S">S</Radio>
                            <Radio value="M">M</Radio>
                            <Radio value="L">L</Radio>
                        </Stack>
                    </RadioGroup>
                </InputGroup>
            </FormControl>
            <FormControl id="numeroSerie" mt={4}>
                <InputGroup>
                    <InputLeftAddon children="Numéro de série" />
                    <Input
                        name="numeroSerie"
                        type="text"
                        onChange={formikAddEquipement.handleChange}
                        value={formikAddEquipement.values.numeroSerie}
                    />
                </InputGroup>
            </FormControl>
            <FormControl id="numeroMarquage" mt={4}>
                <InputGroup>
                    <InputLeftAddon children="Numéro de marquage" />{' '}
                    <Input
                        name="numeroMarquage"
                        type="text"
                        onChange={formikAddEquipement.handleChange}
                        value={formikAddEquipement.values.numeroMarquage}
                    />
                </InputGroup>
            </FormControl>
            <FormControl id="numeroDeCle" mt={4}>
                <InputGroup>
                    <InputLeftAddon children="Numéro de clé" />{' '}
                    <Input
                        name="numeroDeCle"
                        type="text"
                        onChange={formikAddEquipement.handleChange}
                        value={formikAddEquipement.values.numeroDeCle}
                    />
                </InputGroup>
            </FormControl>
            <FormControl id="hivernage" mt={4}>
                <InputGroup>
                    <Checkbox
                        size="lg"
                        colorScheme="blue"
                        onChange={formikAddEquipement.handleChange}
                        isChecked={formikAddEquipement.values.hivernage}
                    >
                        Hivernage ?
                    </Checkbox>
                </InputGroup>
            </FormControl>
            {formikAddEquipement.values.categorie === 'Velo' && (
                <>
                    <FormControl id="avecDateMiseEnService" mt={4}>
                        <InputGroup>
                            <Checkbox
                                size="lg"
                                colorScheme="blue"
                                onChange={formikAddEquipement.handleChange}
                                isChecked={
                                    formikAddEquipement.values
                                        .avecDateMiseEnService
                                }
                            >
                                Mis en service ?
                            </Checkbox>
                        </InputGroup>
                    </FormControl>
                    {formikAddEquipement.values.avecDateMiseEnService && (
                        <FormControl id="dateMiseEnService" mt={4}>
                            <InputGroup>
                                <SingleDatepicker
                                    configs={{
                                        dateFormat: 'dd/MM/yyyy',
                                        dayNames:
                                            'Dim Lun Mar Mer Jeu Ven Sam'.split(
                                                ' '
                                            ),
                                        monthNames:
                                            'Janvier Février Mars Avril Mai Juin Juillet Août Septembre Octobre Novembre Décembre'.split(
                                                ' '
                                            ),
                                        firstDayOfWeek: 1,
                                    }}
                                    name="dateMiseEnService"
                                    date={
                                        new Date(
                                            formikAddEquipement.values.dateMiseEnService!
                                        )
                                    }
                                    onDateChange={(value) =>
                                        formikAddEquipement.setFieldValue(
                                            'dateMiseEnService',
                                            value.getTime()
                                        )
                                    }
                                />
                            </InputGroup>
                        </FormControl>
                    )}
                </>
            )}
            <Box>
                {props.entrepriseEditable ? (
                    <FormControl id="entrepriseAppartenance" mt={4}>
                        <InputGroup>
                            <InputLeftAddon children="entrepriseAppartenance" />
                            <Input
                                name="entrepriseAppartenance"
                                type="text"
                                onChange={formikAddEquipement.handleChange}
                                value={
                                    formikAddEquipement.values
                                        .entrepriseAppartenance
                                }
                            />
                        </InputGroup>
                    </FormControl>
                ) : (
                    <></>
                )}
            </Box>
            <Box>
                {props.agenceEditable ? (
                    <FormControl id="agenceAppartenance" mt={4}>
                        <InputGroup>
                            <InputLeftAddon children="agenceAppartenance" />
                            <Input
                                name="agenceAppartenance"
                                type="text"
                                onChange={formikAddEquipement.handleChange}
                                value={
                                    formikAddEquipement.values
                                        .agenceAppartenance
                                }
                            />
                        </InputGroup>
                    </FormControl>
                ) : (
                    <></>
                )}
            </Box>
            <Box>
                {props.utilisateurEditable ? (
                    <FormControl id="utilisateurAppartenance" mt={4}>
                        <InputGroup>
                            <InputLeftAddon children="utilisateurAppartenance" />
                            <Input
                                name="utilisateurAppartenance"
                                type="text"
                                onChange={formikAddEquipement.handleChange}
                                value={
                                    formikAddEquipement.values
                                        .utilisateurAppartenance
                                }
                            />
                        </InputGroup>
                    </FormControl>
                ) : (
                    <></>
                )}
            </Box>
            <Center>
                <Button
                    mt={4}
                    mr={3}
                    onClick={formikAddEquipement.submitForm}
                    isDisabled={
                        !formikAddEquipement.isValid ||
                        !formikAddEquipement.dirty
                    }
                    isLoading={formikAddEquipement.isSubmitting}
                >
                    {editMode ? <>Editer</> : <>Ajouter</>}
                </Button>
            </Center>
        </FormikProvider>
    )
}
