/*
 * Page dédié a la demande de réinitialisation de password, cela envoi un mail au client
 */
import { getAuth, sendPasswordResetEmail } from 'firebase/auth'
import {
    Flex,
    FormControl,
    FormLabel,
    Heading,
    Input,
    Box,
    Center,
    Text,
    Stack,
    Link as ChakraLink,
    Image,
} from '@chakra-ui/react'
import { spaceRegExp } from '../../../utils/helpers'
import { FormikProvider, useFormik } from 'formik'
import { Button } from '../../../components/Button'
import { useToast } from '@chakra-ui/react'
import { Link } from 'react-router-dom'

import * as yup from 'yup'

const validationSchema = yup.object().shape({
    email: yup
        .string()
        .matches(spaceRegExp, "Le mail ne doit pas contenir d'espaces")
        .email('Format du mail invalide')
        .required('Mail requis'),
})

export type MailValue = {
    email: string
}

export const ResetPassword = () => {
    const toast = useToast()

    const formikResetPassword = useFormik({
        validationSchema,
        initialValues: {
            email: '',
        },
        onSubmit: (values: MailValue, actions) => {
            const auth = getAuth()
            sendPasswordResetEmail(auth, values.email)
                .then((result) => {
                    actions.setSubmitting(false)
                    actions.resetForm()
                    toast({
                        title: 'Demande de réinitialisation effectué',
                        description:
                            "Un mail a été envoyé a l'adresse " +
                            values.email +
                            ', Consultez le afin de finaliser la demande',
                        status: 'success',
                        duration: 5000,
                        isClosable: true,
                    })
                })
                .catch((error) => {
                    const errorCode = error.code
                    var messageError = 'Une erreur inconnue a eu lieu.'
                    switch (errorCode) {
                        default:
                            console.log(
                                'erreur non prise en charge par le toast :' +
                                    errorCode
                            )
                            break
                    }
                    toast({
                        title: 'Échec de la demande',
                        description: messageError,
                        status: 'error',
                        duration: 3000,
                        isClosable: true,
                    })
                    actions.setSubmitting(false)
                })
        },
    })

    return (
        <>
            <Stack minH={'80vh'} direction={{ base: 'column', md: 'row' }}>
                <Flex p={8} flex={1} align={'center'} justify={'center'}>
                    <Box
                        backgroundColor={'white'}
                        p={'70px'}
                        borderRadius={'40px'}
                    >
                        <Stack spacing={4} w={'full'} maxW={'md'}>
                            <Center>
                                <ChakraLink href="https://www.fenxmobility.com/">
                                    <Image src="logo.jpg" mb={'60px'} />
                                </ChakraLink>
                            </Center>
                            <Center>
                                <Heading fontSize={'2xl'}>
                                    Réinitialisation du mot de passe
                                </Heading>
                            </Center>

                            <FormikProvider value={formikResetPassword}>
                                <FormControl id="email">
                                    <FormLabel>
                                        Addresse eMail de votre compte
                                    </FormLabel>
                                    <Input
                                        name="email"
                                        type="email"
                                        onChange={
                                            formikResetPassword.handleChange
                                        }
                                        value={formikResetPassword.values.email}
                                    />
                                </FormControl>

                                <Stack spacing={6}>
                                    <Stack
                                        direction={{
                                            base: 'column',
                                            sm: 'row',
                                        }}
                                        align={'start'}
                                        justify={'space-between'}
                                    >
                                        <Link to="/login">
                                            <Text color={'blue.500'}>
                                                J'ai déja un compte
                                            </Text>
                                        </Link>
                                    </Stack>
                                    <Button
                                        onClick={formikResetPassword.submitForm}
                                        isDisabled={
                                            !formikResetPassword.isValid ||
                                            !formikResetPassword.dirty
                                        }
                                        isLoading={
                                            formikResetPassword.isSubmitting
                                        }
                                    >
                                        Demander un nouveau mot de passe
                                    </Button>
                                </Stack>
                            </FormikProvider>
                        </Stack>
                    </Box>
                </Flex>
            </Stack>
        </>
    )
}
