/**
 * Classe utilitaire pour gerer des affichages en fonction de certaine conditions.
 * version préimplémenté possèdant toute les conditions pour un affichage cohérent d'une page d'administration
 */

import { Login } from '../pages/Authentification/Login'
import { NotFound } from '../pages/Erreur/NotFound'
import { RoleEnAttente } from '../pages/Erreur/RoleEnAttente'
import { ConditionalConnect } from './ConditionalConnect'

interface Props {
    children: React.ReactNode
}

export const ConditionalConnectAdminComplex = (props: Props) => {
    return (
        <>
            <ConditionalConnect showIfNotConnected>
                <Login />
            </ConditionalConnect>
            <ConditionalConnect showIfConnected>
                <ConditionalConnect showIfUserInformationNotCollected>
                    <RoleEnAttente />
                </ConditionalConnect>
                <ConditionalConnect showIfUserInformationCollected>
                    <ConditionalConnect showIfAdmin>
                        {props.children}
                    </ConditionalConnect>
                    <ConditionalConnect showIfNotAdmin>
                        <NotFound />
                    </ConditionalConnect>
                </ConditionalConnect>
            </ConditionalConnect>
        </>
    )
}
