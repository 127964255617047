/*
 * Page dédiée a l'affichages des statistique Entreprise ou agence de l'utilisateur client connecté (en fonction de son rôle)
 */
import { Container, Heading, Accordion, Wrap } from '@chakra-ui/react'
import { ConditionalConnect } from '../../auth/ConditionalConnect'
import { Login } from '../Authentification/Login'
import { useContext, useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { AuthContext } from '../../auth/AuthContextProvider'
import {
    BlocTroisStats,
    BlocTroisStatsAccordeon,
} from '../../components/BlocTroisStats'
import {
    Agence,
    CategorieEquipement,
    Equipement,
    Role,
    Utilisateur,
} from '../../model'
import { GetAllAgencesForTargetEntreprise } from '../../firebaseManager/Communicator/AgenceCommunicator'
import { UtilisateurContext } from '../../auth/UtilisateurContextProvider'
import { GetAllSharedEquipementsForAgence } from '../../firebaseManager/Communicator/EquipementCommunicator'
import {
    VeloPanel,
    VeloPanelEmpty,
    VeloPanelLoading,
} from '../../components/VeloPanel'
import { GetAllUtilisateursTargetAgence } from '../../firebaseManager/Communicator/UtilisateurCommunicator'

export const MyEnterpriseEquipments = () => {
    const authentification = useContext(AuthContext)
    const actualUser = useContext(UtilisateurContext)
    const [searchParams] = useSearchParams()

    const [targetEntreprise, setTargetEntreprise] = useState<string | null>()
    const [allTargetEntrepriseAgences, setAllTargetEntrepriseAgences] =
        useState<Agence[] | null>()
    const [canViewStat, setCanViewStat] = useState<boolean>(false)

    const [targetAgence, setTargetAgence] = useState<string | null>()
    const [mesVelos, setMesVelos] = useState<Equipement[] | null>()
    const [mesVelosLoaded, setMesVelosLoaded] = useState<boolean>(false)
    const [allTargetAgenceUsers, setAllTargetAgenceUsers] = useState<
        Utilisateur[] | null
    >()

    function RefreshAllTargetEntrepriseAgences(id_entreprise: string) {
        GetAllAgencesForTargetEntreprise(id_entreprise!)
            .then((result) => {
                setAllTargetEntrepriseAgences(result)
            })
            .catch((e) => setAllTargetEntrepriseAgences(null))
    }

    function RefreshAllSharedEquipementForAgence(id_agence: string) {
        GetAllSharedEquipementsForAgence(id_agence)
            .then((result) => {
                setMesVelos(
                    result.filter(
                        (equipement) =>
                            equipement.categorie === CategorieEquipement.Velo
                    )
                )
                setMesVelosLoaded(true)
            })
            .catch((e) => {
                setMesVelos(null)
                setMesVelosLoaded(true)
            })
    }

    function RefreshAllTargetAgenceUsers(id_agence: string) {
        GetAllUtilisateursTargetAgence(id_agence)
            .then((result) => {
                setAllTargetAgenceUsers(result)
            })
            .catch((e) => setAllTargetAgenceUsers(null))
    }

    useEffect(() => {
        if (authentification.firebaseUser != null) {
            if (actualUser != null) {
                if (actualUser.role === Role.Admin) {
                    setCanViewStat(true)
                    const id_entreprise: string =
                        searchParams.get('id_entreprise')!
                    const id_agence: string = searchParams.get('id_agence')!

                    if (id_entreprise) {
                        setTargetEntreprise(id_entreprise)
                        RefreshAllTargetEntrepriseAgences(id_entreprise)
                    }
                    if (id_agence) {
                        setTargetAgence(id_agence)
                        RefreshAllSharedEquipementForAgence(id_agence)
                        RefreshAllTargetAgenceUsers(id_agence)
                    }
                }
                if (actualUser.role === Role.ManagerEntreprise) {
                    setCanViewStat(true)
                    if (actualUser.entrepriseAppartenance) {
                        setTargetEntreprise(actualUser.entrepriseAppartenance)
                        RefreshAllTargetEntrepriseAgences(
                            actualUser.entrepriseAppartenance
                        )
                    }
                }
                if (actualUser.role === Role.ManagerAgence) {
                    setCanViewStat(true)
                    if (actualUser.agenceAppartenance) {
                        setTargetAgence(actualUser.agenceAppartenance)
                        RefreshAllSharedEquipementForAgence(
                            actualUser.agenceAppartenance
                        )
                        RefreshAllTargetAgenceUsers(
                            actualUser.agenceAppartenance
                        )
                    }
                }
                if (
                    actualUser.role === Role.ClientFinal &&
                    actualUser.accessVeloAgence
                ) {
                    setCanViewStat(false)
                    if (actualUser.agenceAppartenance) {
                        setTargetAgence(actualUser.agenceAppartenance)
                        RefreshAllSharedEquipementForAgence(
                            actualUser.agenceAppartenance
                        )
                    }
                }
            }
        }
    }, [authentification, actualUser])

    return (
        <>
            <ConditionalConnect showIfConnected>
                <Container maxW="container.md">
                    {targetEntreprise && canViewStat && (
                        <>
                            <Heading fontSize="3xl" mt={'20px'} mb={'10px'}>
                                Statistiques de mon entreprise
                            </Heading>
                            <BlocTroisStats entrepriseId={targetEntreprise} />
                            {allTargetEntrepriseAgences &&
                                allTargetEntrepriseAgences?.length > 0 && (
                                    <>
                                        <Heading
                                            fontSize="2xl"
                                            mt={'20px'}
                                            mb={'10px'}
                                        >
                                            Statistiques des agences
                                        </Heading>
                                        <Accordion allowMultiple mt={'30px'}>
                                            {allTargetEntrepriseAgences?.map(
                                                function (agence, idx) {
                                                    return (
                                                        <BlocTroisStatsAccordeon
                                                            key={idx}
                                                            agenceId={agence.id}
                                                            entrepriseId={
                                                                targetEntreprise
                                                            }
                                                            titre={agence.nom}
                                                        />
                                                    )
                                                }
                                            )}
                                        </Accordion>
                                    </>
                                )}
                        </>
                    )}
                    {targetAgence && (
                        <>
                            {canViewStat && (
                                <>
                                    <Heading
                                        fontSize="3xl"
                                        mt={'20px'}
                                        mb={'10px'}
                                    >
                                        Statistiques de mon Agence
                                    </Heading>
                                    <BlocTroisStats agenceId={targetAgence} />
                                </>
                            )}

                            <Heading fontSize="2xl" mt={'20px'} mb={'10px'}>
                                Les vélos partagés de mon Agence
                            </Heading>
                            {!mesVelosLoaded && <VeloPanelLoading />}
                            {mesVelosLoaded && mesVelos?.length === 0 && (
                                <VeloPanelEmpty />
                            )}
                            {mesVelosLoaded &&
                                mesVelos &&
                                mesVelos?.length > 0 && (
                                    <>
                                        {mesVelos?.map(function (
                                            equipment,
                                            idx
                                        ) {
                                            return (
                                                <VeloPanel
                                                    key={idx}
                                                    equipement={equipment}
                                                />
                                            )
                                        })}
                                    </>
                                )}

                            {canViewStat &&
                                allTargetAgenceUsers &&
                                allTargetAgenceUsers?.length > 0 && (
                                    <>
                                        <Heading
                                            fontSize="2xl"
                                            mt={'20px'}
                                            mb={'10px'}
                                        >
                                            Mes collaborateurs
                                        </Heading>
                                        <Accordion
                                            allowMultiple
                                            mt={'30px'}
                                            defaultIndex={allTargetAgenceUsers.map(
                                                (user, index) => {
                                                    return index
                                                }
                                            )}
                                        >
                                            {allTargetAgenceUsers?.map(
                                                function (user, idx) {
                                                    return (
                                                        <BlocTroisStatsAccordeon
                                                            key={idx}
                                                            utilisateurId={
                                                                user.id
                                                            }
                                                            titre={
                                                                user.nom +
                                                                ' ' +
                                                                user.prenom
                                                            }
                                                            defaultOpen={true}
                                                        />
                                                    )
                                                }
                                            )}
                                        </Accordion>
                                    </>
                                )}
                        </>
                    )}
                </Container>
            </ConditionalConnect>

            <ConditionalConnect showIfNotConnected>
                <Login />
            </ConditionalConnect>
        </>
    )
}
