/**
 *  Button avec un tooltip en overlay
 *  Un clic sur ce boutton permet de copier le contenu du tooltip
 */

import { Tooltip } from '@chakra-ui/react'
import { Button } from '../Button'

export type Props = {
    children?: React.ReactNode
    label: string
    textToCopy: string
    hideIfEmpty?: boolean
}

export const ButtonToolTip = (props: Props) => {
    if (props.hideIfEmpty && props.label) {
        return <></>
    }
    return (
        <Tooltip
            hasArrow
            label={props.label ? props.label : 'Non renseigné'}
            bg="gray.300"
            color="black"
        >
            <Button
                colorScheme="blue"
                p={0}
                mr={1}
                disabled
                onClick={() => {
                    if (props.textToCopy) {
                        navigator.clipboard.writeText(props.textToCopy)
                    }
                }}
            >
                {props.children}
            </Button>
        </Tooltip>
    )
}
