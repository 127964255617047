/*
 * bouton ouvrant une modal et permettant de modifier l'email d'un utilisateur.
 * La modification de mail va entrainer la modification de valeur dans l'objet utilisateur firebase, mais aussi la modification sur l'entité dans firebase AUTH
 */

import {
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    InputGroup,
    InputLeftAddon,
    Box,
    ModalOverlay,
    useDisclosure,
    useToast,
    Input,
    Center,
} from '@chakra-ui/react'

import { Button } from '../Button'
import { useState } from 'react'

import { Utilisateur } from '../../model'
import { CallFunctionAdminChangementMailUtilisateur } from '../../firebaseManager/Communicator/FunctionCallManager'
import { validateEmail } from '../../utils/helpers'

export type Props = {
    utilisateur: Utilisateur
    onSuccess?: () => void
}

export const ModalChangementMailUtilisateur = (props: Props) => {
    const toast = useToast()
    const {
        isOpen: isOpenModal,
        onOpen: onOpenModal,
        onClose: onCloseModal,
    } = useDisclosure()
    const [isLoading, setisLoading] = useState<boolean>(false)

    const [newMail, setNewMail] = useState<string>('')

    return (
        <>
            <Button
                colorScheme="blue"
                p={5}
                onClick={(e) => {
                    onOpenModal()
                }}
            >
                Changer Mail
            </Button>
            <Modal isOpen={isOpenModal} onClose={onCloseModal}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Changement d'email</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody pb={6}>
                        Définisez le nouveau mail qui sera associé au compte de{' '}
                        <b>
                            {props.utilisateur.prenom} {props.utilisateur.nom}{' '}
                        </b>
                        ({props.utilisateur.mailContact}) :
                        <Box width={'100%'} mt={'15px'}>
                            <InputGroup>
                                <InputLeftAddon>Mail de contact</InputLeftAddon>
                                <Input
                                    name="mailContact"
                                    type="text"
                                    onChange={(e) =>
                                        setNewMail(e.target.value.toLowerCase())
                                    }
                                    value={newMail}
                                />
                            </InputGroup>
                        </Box>
                        {!validateEmail.test(newMail) && (
                            <Center color="red" mt="10px">
                                Mail invalide
                            </Center>
                        )}
                    </ModalBody>

                    <ModalFooter>
                        <Button
                            isLoading={isLoading}
                            mr={3}
                            isDisabled={!validateEmail.test(newMail)}
                            colorScheme={'blue'}
                            onClick={(e) => {
                                if (newMail !== null && newMail !== '') {
                                    CallFunctionAdminChangementMailUtilisateur(
                                        props.utilisateur.id,
                                        newMail
                                    )
                                        .then((result) => {
                                            toast({
                                                title: "Changement de mail de l'utilisateur réussi",
                                                description:
                                                    'le nouveau mail est : ' +
                                                    newMail,
                                                status: 'success',
                                                duration: 3000,
                                                isClosable: true,
                                            })
                                            setisLoading(false)
                                            onCloseModal()
                                            if (props.onSuccess)
                                                props.onSuccess()
                                        })
                                        .catch((e) => {
                                            setisLoading(false)
                                            toast({
                                                title: "Échec du changement de mail de l'utilisateur",
                                                description: e.message,
                                                status: 'error',
                                                duration: 5000,
                                                isClosable: true,
                                            })
                                        })
                                }
                            }}
                        >
                            Modifier
                        </Button>
                        <Button
                            mr={3}
                            colorScheme={'orange-light'}
                            onClick={(e) => {
                                onCloseModal()
                            }}
                        >
                            Annuler
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}
