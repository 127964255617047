/*
 * Pied de page général du site
 */
import {
    Box,
    Center,
    Accordion,
    AccordionItem,
    AccordionIcon,
    AccordionPanel,
    AccordionButton,
} from '@chakra-ui/react'
import { CustomBox } from '../../CustomBox'
import moment from 'moment'
import { Calendrier } from '../Calendrier'
import { ModalCalendrier } from '../ModalCalendrier'

// affichage via BOX
export type PropsBoxCalendrier = {}

export const BoxCalendrier = ({}: PropsBoxCalendrier) => {
    const targetDay = moment() //.set({year: 2024, month: 4, date: 9}) //.set({ year: 2024, month: 4 })
    const targetDayLastmonth = moment().subtract(1, 'months')
    //.set({ year: 2024, month: 4, date: 9 })

    const isBeginOfMonth = targetDay.date() <= 10

    const targetMonthString =
        targetDay.format('MMMM YYYY').charAt(0).toUpperCase() +
        targetDay.format('MMMM YYYY').slice(1)

    const targetLastMonthString =
        targetDayLastmonth.format('MMMM YYYY').charAt(0).toUpperCase() +
        targetDayLastmonth.format('MMMM YYYY').slice(1)
    return (
        <CustomBox>
            <Accordion defaultIndex={[0]} allowMultiple>
                <AccordionItem>
                    <AccordionButton>
                        <Box as="span" flex="1" textAlign="left">
                            <b>{targetMonthString}</b>
                        </Box>
                        <AccordionIcon />
                    </AccordionButton>
                    <AccordionPanel pb={4}>
                        <Calendrier targetDay={targetDay} modifiable={true} />
                    </AccordionPanel>
                </AccordionItem>
                {isBeginOfMonth && (
                    <AccordionItem>
                        <AccordionButton>
                            <Box as="span" flex="1" textAlign="left">
                                <b>{targetLastMonthString}</b> (Modifiable
                                jusqu'au 10 {targetMonthString})
                            </Box>
                            <AccordionIcon />
                        </AccordionButton>
                        <AccordionPanel pb={4}>
                            <Calendrier
                                targetDay={targetDayLastmonth}
                                modifiable={true}
                            />
                        </AccordionPanel>
                    </AccordionItem>
                )}
            </Accordion>

            <Center mt={'20px'}>
                <ModalCalendrier />
            </Center>
        </CustomBox>
    )
}
