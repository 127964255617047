/**
 * Context provider pour fournir les informations de l'utilisateur, au sens métier du terme,
 * informations stocké dans le firestore (et non les informations du système d'authentification)
 */

import React, { ReactNode, useState, useEffect, useContext } from 'react'
import { GetTargetUtilisateur } from '../firebaseManager/Communicator/UtilisateurCommunicator'
import { Utilisateur, Role } from '../model'
import { AuthContext } from './AuthContextProvider'
import { useNavigate } from 'react-router-dom'

export const UtilisateurContext = React.createContext<Utilisateur | undefined>(
    undefined
)

interface Props {
    children: ReactNode
}

export const UtilisateurContextProvider = (props: Props) => {
    const navigate = useNavigate()

    const authentification = useContext(AuthContext)
    const [utilisateur, setUtilisateur] = useState<Utilisateur | undefined>()

    useEffect(() => {
        if (authentification.firebaseUser != null) {
            GetTargetUtilisateur(authentification.firebaseUser?.uid!)
                .then((result) => {
                    console.log('OK Utilisateur : ')
                    console.log(result)
                    setUtilisateur(result)

                    if (result?.role === Role.Admin) {
                        navigate('/manage_equipements')
                    } else if (result?.role === Role.Reparateur) {
                        navigate('/vue_reparateur')
                    } else {
                        navigate('/mes_equipements')
                    }
                })
                .catch((e) => {
                    console.log('ERR Utilisateur ' + e)
                    setUtilisateur(undefined)
                })
        }
    }, [authentification])

    return (
        <UtilisateurContext.Provider value={utilisateur}>
            {props.children}
        </UtilisateurContext.Provider>
    )
}
