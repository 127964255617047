/*
 * Box standard de l'interface, avec les arrondies et les ombrages
 */

import { Box, BoxProps, forwardRef } from '@chakra-ui/react'

export type Props = {
    children?: React.ReactNode
} & BoxProps

export const CustomBox = forwardRef<BoxProps, 'div'>(
    ({ children, ...props }, ref) => {
        return (
            <Box
                bg="white"
                borderRadius={'15px'}
                padding={'10px'}
                boxShadow={'rgba(17, 17, 26, 0.3) 0px 00px 10px'}
                {...props}
            >
                {children}
            </Box>
        )
    }
)
