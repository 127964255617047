/*
 * Ensemble de fonction annexes et utilitaires
 */
import { Agence, Entreprise, Role, Utilisateur } from '../model'

/**
 * Regex permetant de controler si il y a des espaces
 */
export const spaceRegExp = /^[\S]*$/
/**
 * Regex permetant de controler un numéro de telephone
 */
export const phoneRegExp = /^(0|(00|\+)33)[67][0-9]{8}$/

export const validateEmail =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

/**
 * Trie une liste d'entreprises par ordre alphabetique de leurs noms
 */
export function SortEntreprise(array: Entreprise[]) {
    return array.sort((a: Entreprise, b: Entreprise) => {
        if (a.nom && b.nom) {
            if (a.nom.toLocaleLowerCase() < b.nom.toLocaleLowerCase()) {
                return -1
            }
            if (a.nom.toLocaleLowerCase() > b.nom.toLocaleLowerCase()) {
                return 1
            }
        }
        return 0
    })
}

/**
 * Trie une liste d'agence par ordre alphabetique de leurs noms
 */
export function SortAgence(array: Agence[]) {
    return array.sort((a: Agence, b: Agence) => {
        if (a.nom && b.nom) {
            if (a.nom.toLocaleLowerCase() < b.nom.toLocaleLowerCase()) {
                return -1
            }
            if (a.nom.toLocaleLowerCase() > b.nom.toLocaleLowerCase()) {
                return 1
            }
        }
        return 0
    })
}

/**
 * Trie une liste d'utilisateur par ordre alphabetique de leurs noms
 */
export function SortUtilisateur(array: Utilisateur[]) {
    return array.sort((a: Utilisateur, b: Utilisateur) => {
        if (a.nom && b.nom) {
            if (a.nom.toLocaleLowerCase() < b.nom.toLocaleLowerCase()) {
                return -1
            }
            if (a.nom.toLocaleLowerCase() > b.nom.toLocaleLowerCase()) {
                return 1
            }
        }
        return 0
    })
}

/*
 * transformation de role en texte propre
 */

export function ShowRole(role: string | undefined | Role) {
    if (!role) {
        return 'Rôle inconnu'
    }
    switch (role) {
        case Role.Admin:
            return 'Administrateur'
        case Role.ClientFinal:
            return 'Client'
        case Role.ManagerAgence:
            return "Manager d'agence"
        case Role.ManagerEntreprise:
            return "Manager d'entreprise"
        case Role.Reparateur:
            return 'Réparateur'
        default:
            return role
    }
}

/*
La production  CO2 d'un vélo electrique au kilometre reviens a 22g /kms
https://greenly.earth/fr-fr/blog/actualites-ecologie/empreinte-carbone-velo-trottinette

la production de CO2 d'une voiture thermique au kilometre reviens a 250g /kms
https://www.squadeasy.com/blog/empreinte-carbone/lempreinte-carbone-des-differents-types-de-voitures

1klm en vélo economise donc 230gco2
*/
export function KlmToCO2G(kms: number | undefined | null): number {
    if (kms) {
        return kms * 230
    }
    return 0
}
export function KlmToCO2KG(kms: number | undefined | null): number {
    if (kms) {
        return kms * 0.23
    }
    return 0
}

export function KlmToCO2T(kms: number | undefined | null): number {
    if (kms) {
        return kms * 0.00023
    }
    return 0
}

export function KlmToCO2AutoText(kms: number | undefined | null): string {
    if (kms) {
        var total = KlmToCO2G(kms)
        if (total < 1000) {
            return total.toFixed(0) + ' G'
        }
        total = total / 1000
        if (total < 1000) {
            return total.toFixed(1) + ' KG'
        }
        total = total / 1000
        return total.toFixed(2) + ' T'
    }
    return '0 G'
}
