/*
 * Bloc d'attente. Utilisé lorsque le role n'est pas encore chargé et que l'on souhaite accèder a une page admin
 */

import { Box, Text, Spinner } from '@chakra-ui/react'

export const RoleEnAttente = () => {
    return (
        <Box textAlign="center" py={10} px={6}>
            <Spinner
                thickness="4px"
                speed="0.65s"
                emptyColor="gray.200"
                color="blue.500"
                size="xl"
            />
            <Text fontSize="18px" mt={3} mb={2}>
                Chargement de votre profil
            </Text>
            <Text color={'gray.500'} mb={6}>
                On essaye de faire au plus vite !
            </Text>
        </Box>
    )
}
