/*
 * Class d'intération avec firestore pour les éléments : Entreprise
 */
import { Entreprise } from '../../../model'
import { db } from '../..'
import {
    collection,
    query,
    doc,
    getDocs,
    deleteDoc,
    where,
    documentId,
    orderBy,
    limit,
} from 'firebase/firestore'

export async function GetMyEntreprise(
    myEntrepriseId: string
): Promise<Entreprise | undefined> {
    const q = query(
        collection(db, 'entreprises'),
        where(documentId(), '==', myEntrepriseId)
    )
    const querySnapshot = await getDocs(q)
    if (!querySnapshot.empty) {
        return {
            id: querySnapshot.docs[0].id,
            ...querySnapshot.docs[0].data(),
        } as Entreprise
    }
    return undefined
}

export async function GetTargetEntreprise(
    entrepriseId: string
): Promise<Entreprise | undefined> {
    const q = query(
        collection(db, 'entreprises'),
        where(documentId(), '==', entrepriseId),
        limit(1)
    )
    const querySnapshot = await getDocs(q)
    if (!querySnapshot.empty) {
        return {
            id: querySnapshot.docs[0].id,
            ...querySnapshot.docs[0].data(),
        } as Entreprise
    }
    return undefined
}

export async function GetAllEntreprises(): Promise<Entreprise[]> {
    const q = query(collection(db, 'entreprises'), orderBy('nom', 'asc'))
    const querySnapshot = await getDocs(q)
    const allEntreprises: Entreprise[] = querySnapshot.docs.map((doc) => {
        return { id: doc.id, ...doc.data() } as Entreprise
    })
    return allEntreprises
}

export async function DeleteOneEntreprise(entrepriseId: string): Promise<void> {
    await deleteDoc(doc(db, 'entreprises', entrepriseId))
}
