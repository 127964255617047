/*
 * bouton ouvrant une modal et permettant de supprimer une entreprise, une agences ou un utilisateur.
 * La suppression ne se réalise pas en cascade pour éviter des mauvaise manipulations :
 *  - La suppression d'un utilisateur necessite qu'il n'y ai plus d'équipement rattaché. La suppréssion entraine la supprésion de l'objet firestore Utilisateur et de l'utilisateur dans le système d'AUTH
 *  - La suppression d'une agence est possible si il n'y a plus d'utilisateurs affectés, n'y d'équipements afféctés (partagé)
 *  - La suppression d'une entreprise est possible si il n'y a plus d'agences affectées
 */

import { DeleteIcon } from '@chakra-ui/icons'
import {
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Spinner,
    useDisclosure,
    useToast,
} from '@chakra-ui/react'
import {
    CallFunctionAdminDeleteAgence,
    CallFunctionAdminDeleteEntreprise,
    CallFunctionAdminDeleteUtilisateur,
} from '../../firebaseManager/Communicator/FunctionCallManager'
import { Button } from '../Button'
import { useState } from 'react'

export type Props = {
    targetId: string
    nomEntite?: string
    onSuccess?: () => void
}

export const ModalSuppressionAgence = (props: Props) => {
    const toast = useToast()
    const {
        isOpen: isOpenModal,
        onOpen: onOpenModal,
        onClose: onCloseModal,
    } = useDisclosure()
    const [loading, setLoading] = useState<boolean>(false)
    return (
        <>
            <Button
                colorScheme="red-light"
                p={0}
                ml={5}
                onClick={(e) => {
                    onOpenModal()
                }}
            >
                <DeleteIcon />
            </Button>
            <Modal isOpen={isOpenModal} onClose={onCloseModal}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Valider suppression ?</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody pb={6}>
                        Voulez vous valider la suppression de l'Agence ?
                        <br></br> Nom : {props.nomEntite}
                    </ModalBody>

                    <ModalFooter>
                        <Button
                            isDisabled={loading}
                            mr={3}
                            colorScheme={'red-light'}
                            onClick={(e) => {
                                setLoading(true)
                                CallFunctionAdminDeleteAgence(props.targetId)
                                    .then((result) => {
                                        console.log(result)
                                        toast({
                                            title: "Suppression d'agence réussi",
                                            description: '',
                                            status: 'success',
                                            duration: 3000,
                                            isClosable: true,
                                        })
                                        onCloseModal()
                                        setLoading(false)
                                        if (props.onSuccess) props.onSuccess()
                                    })
                                    .catch((e) => {
                                        toast({
                                            title: "Échec de la suppression d'agence",
                                            description: e.message,
                                            status: 'error',
                                            duration: 5000,
                                            isClosable: true,
                                        })
                                        setLoading(false)
                                        onCloseModal()
                                    })
                            }}
                        >
                            Supprimer {loading && <Spinner ml={'3px'} />}
                        </Button>
                        <Button
                            onClick={onCloseModal}
                            colorScheme={'orange-light'}
                        >
                            Annuler
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}

export const ModalSuppressionEntreprise = (props: Props) => {
    const toast = useToast()
    const {
        isOpen: isOpenModal,
        onOpen: onOpenModal,
        onClose: onCloseModal,
    } = useDisclosure()
    const [loading, setLoading] = useState<boolean>(false)

    return (
        <>
            <Button
                colorScheme="red-light"
                p={0}
                ml={5}
                onClick={(e) => {
                    onOpenModal()
                }}
            >
                <DeleteIcon />
            </Button>
            <Modal isOpen={isOpenModal} onClose={onCloseModal}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Valider suppression ?</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody pb={6}>
                        Voulez vous valider la suppression de l'entreprise ?{' '}
                        <br></br>Nom : {props.nomEntite}
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            isDisabled={loading}
                            mr={3}
                            colorScheme={'red-light'}
                            onClick={(e) => {
                                setLoading(true)
                                CallFunctionAdminDeleteEntreprise(
                                    props.targetId
                                )
                                    .then((result) => {
                                        toast({
                                            title: "Suppression de l'entreprise réussi",
                                            description: '',
                                            status: 'success',
                                            duration: 3000,
                                            isClosable: true,
                                        })
                                        onCloseModal()
                                        setLoading(false)
                                        if (props.onSuccess) props.onSuccess()
                                    })
                                    .catch((e) => {
                                        toast({
                                            title: "Échec de la suppression de l'entreprise",
                                            description: e.message,
                                            status: 'error',
                                            duration: 5000,
                                            isClosable: true,
                                        })
                                        onCloseModal()
                                        setLoading(false)
                                    })
                            }}
                        >
                            Supprimer {loading && <Spinner ml={'3px'} />}
                        </Button>
                        <Button
                            onClick={onCloseModal}
                            colorScheme={'orange-light'}
                        >
                            Annuler
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}

export const ModalSuppressionUtilisateur = (props: Props) => {
    const toast = useToast()
    const {
        isOpen: isOpenModal,
        onOpen: onOpenModal,
        onClose: onCloseModal,
    } = useDisclosure()
    const [loading, setLoading] = useState<boolean>(false)

    return (
        <>
            <Button
                colorScheme="red-light"
                p={0}
                ml={5}
                onClick={(e) => {
                    onOpenModal()
                }}
            >
                <DeleteIcon />
            </Button>
            <Modal isOpen={isOpenModal} onClose={onCloseModal}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Valider suppression ?</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody pb={6}>
                        Voulez vous valider la suppression de l'utilisateur ?{' '}
                        <br></br>Nom : {props.nomEntite}
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            isDisabled={loading}
                            mr={3}
                            colorScheme={'red-light'}
                            onClick={(e) => {
                                setLoading(true)
                                CallFunctionAdminDeleteUtilisateur(
                                    props.targetId
                                )
                                    .then((result) => {
                                        toast({
                                            title: "Suppression de l'utilisateur réussi",
                                            description: '',
                                            status: 'success',
                                            duration: 3000,
                                            isClosable: true,
                                        })
                                        onCloseModal()
                                        setLoading(false)
                                        if (props.onSuccess) props.onSuccess()
                                    })
                                    .catch((e) => {
                                        toast({
                                            title: "Échec de la suppression de l'utilisateur",
                                            description: e.message,
                                            status: 'error',
                                            duration: 5000,
                                            isClosable: true,
                                        })
                                        onCloseModal()
                                        setLoading(false)
                                    })
                            }}
                        >
                            Supprimer {loading && <Spinner ml={'3px'} />}
                        </Button>
                        <Button
                            onClick={onCloseModal}
                            colorScheme={'orange-light'}
                        >
                            Annuler
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}
