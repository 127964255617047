/*
 * bouton ouvrant une modal et permettant de de consulter le contrat d'entretien fourni en paramétre (URL)
 * il existe 2 mode, le mode lien qui affiche un lien clickable qui ouvre la modal, ou le mode boutton qui réalisa la même action avec un boutton
 */

import {
    Box,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    useDisclosure,
} from '@chakra-ui/react'
import { useContext } from 'react'
import { EntrepriseContext } from '../../auth/EntrepriseContextProvider'

interface Props {
    url?: string
    titre?: string
    lien?: boolean
    button?: boolean
}

export const ModalPDF = (props: Props) => {
    const entreprise = useContext(EntrepriseContext)

    const {
        isOpen: isOpenModal,
        onOpen: onOpenModal,
        onClose: onCloseModal,
    } = useDisclosure()

    if (!props.url && (!entreprise || !entreprise.contratEntretien)) {
        return <></>
    }
    return (
        <>
            {props.lien && (
                <a onClick={onOpenModal} style={{ color: '#2d7bb6' }}>
                    Ouvrir Contrat
                </a>
            )}
            {props.button && (
                <Box
                    px={3}
                    py={2}
                    rounded={'md'}
                    _hover={{
                        textDecoration: 'none',
                        bg: '#31a1d7',
                        color: 'white',
                    }}
                    backgroundColor={'#2d7bb6'}
                    color={'white'}
                    onClick={onOpenModal}
                >
                    Contrat d'entretien
                </Box>
            )}

            <Modal isOpen={isOpenModal} onClose={onCloseModal} size="2xl">
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>{props.titre}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody pb={6}>
                        <iframe
                            title="Contrat d'entretien"
                            src={
                                props.url
                                    ? props.url
                                    : entreprise!.contratEntretien
                            }
                            width={'100%'}
                            height={'800px'}
                        />
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    )
}
