/*
 * Class d'intération avec firestore pour les éléments : Journal
 */
import { Journal } from '../../../model'
import { db } from '../..'
import {
    collection,
    query,
    getDocs,
    where,
    orderBy,
    limit,
} from 'firebase/firestore'

export async function GetAllJournalForTargetEquipement(
    equipementID: string
): Promise<Journal[]> {
    const q = query(
        collection(db, 'journal'),
        where('equipementCibleID', '==', equipementID),
        orderBy('dateAction', 'desc')
    )
    const querySnapshot = await getDocs(q)
    const allJournalForTargetEquipement: Journal[] = querySnapshot.docs.map(
        (doc) => {
            return { id: doc.id, ...doc.data() } as Journal
        }
    )
    return allJournalForTargetEquipement
}

export async function GetAllJournalForHistoriqueKilometrageForTargetEquipement(
    equipementID: string
): Promise<Journal[]> {
    const q = query(
        collection(db, 'journal'),
        where('equipementCibleID', '==', equipementID),
        where('action', '==', 'DeclarationKLM'),
        orderBy('dateAction', 'desc'),
        limit(10)
    )
    const querySnapshot = await getDocs(q)
    const allJournalForHistoriqueKilometrageForTargetEquipement: Journal[] =
        querySnapshot.docs.map((doc) => {
            return { id: doc.id, ...doc.data() } as Journal
        })
    return allJournalForHistoriqueKilometrageForTargetEquipement
}

//    id: string
//    dateAction?: Date
//    utilisateurInitiateurID?: String
//    utilisateurInitiateurNomPrenom?: String
//    action?: Action
//    valueNumber?: number
//    valueString?: String
//    utilisateurrCibleID?: String
//    utilisateurrCibleNomPrenom?: String
//    equipementCibleID?: String//
