/**
 * Construit un fil d'ariane simple a partir d'un tableau de duo NOM / URL
 */

import { Breadcrumb, BreadcrumbItem, BreadcrumbLink } from '@chakra-ui/react'
import { Link as ReactRouterLink } from 'react-router-dom'

export type BreadCrumbBuilderItem = {
    name: string
    url: string
    nolink?: boolean
}

export type Props = {
    items: BreadCrumbBuilderItem[]
}

export const BreadCrumbBuilder = (props: Props) => {
    return (
        <Breadcrumb m={'20px'}>
            {props.items.map((item, index) => {
                if (index === props.items.length - 1) {
                    return (
                        <BreadcrumbItem isCurrentPage key={index}>
                            <BreadcrumbLink href="#">
                                <b>{item.name}</b>
                            </BreadcrumbLink>
                        </BreadcrumbItem>
                    )
                } else if (item.nolink) {
                    return (
                        <BreadcrumbItem isCurrentPage key={index}>
                            <BreadcrumbLink href="#">
                                {item.name}
                            </BreadcrumbLink>
                        </BreadcrumbItem>
                    )
                } else {
                    return (
                        <BreadcrumbItem key={index}>
                            <BreadcrumbLink as={ReactRouterLink} to={item.url}>
                                {item.name}
                            </BreadcrumbLink>
                        </BreadcrumbItem>
                    )
                }
            })}
        </Breadcrumb>
    )
}
