/*
 * Formulaire autonome et réutilisable permettant de créer ou d'éditer un objet Utilisateur
 */

import { db } from '../../../firebaseManager'
import {
    FormControl,
    Input,
    InputGroup,
    InputLeftAddon,
    useToast,
    Center,
    Select,
    Box,
    Text,
    Checkbox,
} from '@chakra-ui/react'
import { doc, updateDoc } from 'firebase/firestore'
import { Button } from '../../Button'
import { FormikProvider, useFormik } from 'formik'
import * as yup from 'yup'
import { Role, Utilisateur } from '../../../model'
import { CallFunctionAdminCreateUser } from '../../../firebaseManager/Communicator/FunctionCallManager'
import { ShowRole } from '../../../utils/helpers'

const validationSchema = yup.object().shape({
    nom: yup.string().required(),
    prenom: yup.string().required(),
    mailContact: yup.string().required(),
    telContact: yup.string(),
    entrepriseAppartenance: yup.string().required(),
    agenceAppartenance: yup.string().required(),
    role: yup.string().required(),
})

export type AddUtilisateurValue = {
    nom: string
    prenom: string
    mailContact: string
    telContact: string
    entrepriseAppartenance: string
    agenceAppartenance: string
    role: string
    accessVeloAgence: boolean
}

export type Props = {
    utilisateur?: Utilisateur
    utilisateurId?: string

    entrepriseEditable: boolean
    entrepriseId?: string

    agenceEditable: boolean
    agenceId?: string

    roleEditable: boolean
    roleList?: Role[]
    onSucess?: () => void
}

export const CreationEditionUtilisateur = (props: Props) => {
    const editMode =
        props.utilisateur != null &&
        props.utilisateur !== undefined &&
        props.utilisateurId != null

    const toast = useToast()

    const formikAddUtilisateur = useFormik({
        validationSchema,
        initialValues: {
            nom:
                editMode && props.utilisateur!.nom
                    ? props.utilisateur!.nom
                    : '',
            prenom:
                editMode && props.utilisateur!.prenom
                    ? props.utilisateur!.prenom
                    : '',
            mailContact:
                editMode && props.utilisateur!.mailContact
                    ? props.utilisateur!.mailContact
                    : '',
            telContact:
                editMode && props.utilisateur!.telContact
                    ? props.utilisateur!.telContact
                    : '',
            entrepriseAppartenance: props.entrepriseId
                ? props.entrepriseId
                : editMode && props.utilisateur!.entrepriseAppartenance
                ? props.utilisateur!.entrepriseAppartenance
                : '',
            agenceAppartenance: props.agenceId
                ? props.agenceId
                : editMode && props.utilisateur!.agenceAppartenance
                ? props.utilisateur!.agenceAppartenance
                : '',
            role:
                editMode && props.utilisateur!.role
                    ? props.utilisateur!.role
                    : '',
            accessVeloAgence:
                editMode && props.utilisateur!.accessVeloAgence
                    ? props.utilisateur!.accessVeloAgence
                    : false,
        },
        onSubmit: (values: AddUtilisateurValue, actions) => {
            if (editMode) {
                updateDoc(doc(db, 'utilisateurs/' + props.utilisateurId), {
                    ...values,
                })
                    .then((result) => {
                        actions.setSubmitting(false)
                        actions.resetForm()
                        if (props.onSucess) props.onSucess()
                        toast({
                            title: 'Agence mise a jour!',
                            description: '',
                            status: 'success',
                            duration: 2000,
                            isClosable: true,
                        })
                    })
                    .catch((e) => {
                        console.log('error on edit ' + e)
                        actions.setSubmitting(false)
                    })
            } else {
                CallFunctionAdminCreateUser(values as Utilisateur)
                    .then((result: any) => {
                        actions.setSubmitting(false)
                        actions.resetForm()
                        if (props.onSucess) props.onSucess()
                        toast({
                            title: 'Utilisateur créé!',
                            description: '',
                            status: 'success',
                            duration: 2000,
                            isClosable: true,
                        })
                    })
                    .catch((e) => {
                        console.log('error on add User ' + e)
                        actions.setSubmitting(false)
                        toast({
                            title: "Échec création d'utilisateur!",
                            description: '',
                            status: 'error',
                            duration: 2000,
                            isClosable: true,
                        })
                    })
            }
        },
    })

    return (
        <FormikProvider value={formikAddUtilisateur}>
            <FormControl id="nom" mt={4}>
                <InputGroup>
                    <InputLeftAddon>
                        Nom <Text color={'red'}>*</Text>
                    </InputLeftAddon>
                    <Input
                        name="nom"
                        type="text"
                        onChange={formikAddUtilisateur.handleChange}
                        value={formikAddUtilisateur.values.nom}
                    />
                </InputGroup>
            </FormControl>
            <FormControl id="prenom" mt={4}>
                <InputGroup>
                    <InputLeftAddon>
                        Prénom <Text color={'red'}>*</Text>
                    </InputLeftAddon>
                    <Input
                        name="prenom"
                        type="text"
                        onChange={formikAddUtilisateur.handleChange}
                        value={formikAddUtilisateur.values.prenom}
                    />
                </InputGroup>
            </FormControl>
            <FormControl id="mailContact" mt={4}>
                <InputGroup>
                    <InputLeftAddon>
                        Mail de contact <Text color={'red'}>*</Text>
                    </InputLeftAddon>
                    <Input
                        disabled={editMode}
                        name="mailContact"
                        type="text"
                        onChange={formikAddUtilisateur.handleChange}
                        value={formikAddUtilisateur.values.mailContact}
                    />
                </InputGroup>
            </FormControl>
            <FormControl id="telContact" mt={4}>
                <InputGroup>
                    <InputLeftAddon children="Téléphone de contact" />{' '}
                    <Input
                        name="telContact"
                        type="text"
                        onChange={formikAddUtilisateur.handleChange}
                        value={formikAddUtilisateur.values.telContact}
                    />
                </InputGroup>
            </FormControl>
            <Box>
                {props.entrepriseEditable ? (
                    <FormControl id="entrepriseAppartenance" mt={4}>
                        <InputGroup>
                            <InputLeftAddon>
                                Entreprise d'appartenance
                                <Text color={'red'}>*</Text>
                            </InputLeftAddon>
                            <Input
                                name="entrepriseAppartenance"
                                type="text"
                                onChange={formikAddUtilisateur.handleChange}
                                value={
                                    formikAddUtilisateur.values
                                        .entrepriseAppartenance
                                }
                            />
                        </InputGroup>
                    </FormControl>
                ) : (
                    <></>
                )}
            </Box>
            <Box>
                {props.agenceEditable ? (
                    <FormControl id="agenceAppartenance" mt={4}>
                        <InputGroup>
                            <InputLeftAddon>
                                Agence d'appartenance
                                <Text color={'red'}>*</Text>
                            </InputLeftAddon>
                            <Input
                                name="agenceAppartenance"
                                type="text"
                                onChange={formikAddUtilisateur.handleChange}
                                value={
                                    formikAddUtilisateur.values
                                        .agenceAppartenance
                                }
                            />
                        </InputGroup>
                    </FormControl>
                ) : (
                    <></>
                )}
            </Box>

            {props.roleEditable ? (
                <>
                    <FormControl id="role" mt={4}>
                        <InputGroup>
                            <InputLeftAddon>
                                Rôle de l'utilisateur
                                <Text color={'red'}>*</Text>
                            </InputLeftAddon>
                            <Select
                                placeholder="Selectionner un role"
                                onChange={formikAddUtilisateur.handleChange}
                                value={formikAddUtilisateur.values.role}
                            >
                                {Object.keys(Role).map(function (role, idx) {
                                    if (role !== 'Admin') {
                                        return (
                                            <option key={idx} value={role}>
                                                {ShowRole(role)}
                                            </option>
                                        )
                                    }
                                })}
                            </Select>
                        </InputGroup>
                    </FormControl>
                </>
            ) : (
                <></>
            )}
            <FormControl id="accessVeloAgence" mt={4}>
                <InputGroup>
                    <InputLeftAddon borderRightRadius={'5px'} mr={'10px'}>
                        Accès vélo Agence ?
                    </InputLeftAddon>
                    <Checkbox
                        onChange={(e) =>
                            formikAddUtilisateur.setFieldValue(
                                'accessVeloAgence',
                                e.target.checked
                            )
                        }
                        isChecked={formikAddUtilisateur.values.accessVeloAgence}
                    ></Checkbox>
                </InputGroup>
            </FormControl>
            <Center>
                <Button
                    mt={4}
                    mr={3}
                    onClick={formikAddUtilisateur.submitForm}
                    isDisabled={
                        !formikAddUtilisateur.isValid ||
                        !formikAddUtilisateur.dirty
                    }
                    isLoading={formikAddUtilisateur.isSubmitting}
                >
                    {editMode ? <>Editer</> : <>Ajouter</>}
                </Button>
            </Center>
        </FormikProvider>
    )
}
