/*
 * Page dédiée a l'édition d'un équipement en particulier
 */
import {
    Box,
    Text,
    Container,
    Center,
    Spinner,
    Wrap,
    useDisclosure,
    Heading,
    Spacer,
} from '@chakra-ui/react'
import {
    Utilisateur,
    Entreprise,
    Agence,
    Equipement,
    CategorieEquipement,
} from '../../model'
import { GetTargetAgence } from '../../firebaseManager/Communicator/AgenceCommunicator'
import { GetTargetEntreprise } from '../../firebaseManager/Communicator/EntrepriseCommunicator'
import { GetTargetUtilisateur } from '../../firebaseManager/Communicator/UtilisateurCommunicator'
import { useContext, useState, useEffect } from 'react'
import { AuthContext } from '../../auth/AuthContextProvider'
import { useSearchParams } from 'react-router-dom'
import { CustomBox } from '../../components/CustomBox'
import { BreadCrumbBuilder } from '../../components/BreadCrumbBuilder'
import { ConditionalConnectAdminComplex } from '../../auth/ConditionalConnectAdminComplex'
import { CloseIcon, EditIcon, ViewIcon } from '@chakra-ui/icons'
import { Link as ReactRouterLink } from 'react-router-dom'
import { GetTargetEquipement } from '../../firebaseManager/Communicator/EquipementCommunicator'
import { CreationEditionEquipement } from '../../components/Formulaire/CreationEditionEquipement'
import {
    AjoutMessageJournal,
    DeclarationKilometriqueJournal,
    DeclarerMaintenanceJournal,
    DeclarerPBJournal,
    DeclarerRepJournal,
} from '../../components/AjoutAuJournal'
import { VoirJournal } from '../../components/VoirJournal'
import moment from 'moment'
import { ModalChangementAssignation } from '../../components/ModalChangementAssignation'
import { ShowRole } from '../../utils/helpers'

export const ManageEditEquipement = () => {
    const [targetEquipement, setTargetEquipement] =
        useState<Equipement | null>()

    const [targetUtilisateur, setTargetUtilisateur] =
        useState<Utilisateur | null>()
    const [targetUtilisateurLoaded, setTargetUtilisateurLoaded] =
        useState<boolean>(false)
    const [targetEntreprise, setTargetEntreprise] =
        useState<Entreprise | null>()
    const [targetEntrepriseLoaded, setTargetEntrepriseLoaded] =
        useState<boolean>(false)
    const [targetAgence, setTargetAgence] = useState<Agence | null>()
    const [targetAgenceLoaded, setTargetAgenceLoaded] = useState<boolean>(false)

    const [searchParams] = useSearchParams()
    const id_equipement: string = searchParams.get('id_equipement')!

    const authentification = useContext(AuthContext)

    const UtilisateurLoading: boolean =
        targetEquipement?.utilisateurAppartenance != null &&
        targetEquipement?.utilisateurAppartenance !== '' &&
        !targetUtilisateurLoaded
    const UtilisateurNotAssigned: boolean =
        targetEquipement?.utilisateurAppartenance == null ||
        targetEquipement?.utilisateurAppartenance === '' ||
        (targetUtilisateurLoaded && !targetUtilisateur)
    const UtilisateurLoaded: boolean =
        targetUtilisateurLoaded && targetUtilisateur != null

    const AgenceLoading: boolean =
        targetEquipement?.agenceAppartenance != null &&
        targetEquipement?.agenceAppartenance !== '' &&
        !targetAgenceLoaded
    const AgenceNotAssigned: boolean =
        targetEquipement?.agenceAppartenance == null ||
        targetEquipement?.agenceAppartenance === '' ||
        (targetAgenceLoaded && !targetAgence)
    const AgenceLoaded: boolean = targetAgenceLoaded && targetAgence != null

    const EntrepriseLoading: boolean =
        targetEquipement?.entrepriseAppartenance != null &&
        targetEquipement?.entrepriseAppartenance !== '' &&
        !targetEntrepriseLoaded
    const EntrepriseNotAssigned: boolean =
        targetEquipement?.entrepriseAppartenance == null ||
        targetEquipement?.entrepriseAppartenance === '' ||
        (targetEntrepriseLoaded && !targetEntreprise)
    const EntrepriseLoaded: boolean =
        targetEntrepriseLoaded && targetEntreprise != null

    const {
        isOpen: isOpenEditEquipement,
        onOpen: onOpenEditEquipement,
        onClose: onCloseEditEquipement,
    } = useDisclosure()

    function refreshTargetEquipement(forcer?: boolean) {
        setTargetEquipement(null)

        GetTargetEquipement(id_equipement)
            .then((result) => {
                setTargetEquipement(result)
                if (
                    result?.entrepriseAppartenance &&
                    result?.entrepriseAppartenance !== '' &&
                    (!targetEntrepriseLoaded || forcer)
                ) {
                    refreshTagetEntreprise(result?.entrepriseAppartenance)
                }
                if (
                    result?.agenceAppartenance &&
                    result?.agenceAppartenance !== '' &&
                    (!targetAgenceLoaded || forcer)
                ) {
                    refreshTargetAgence(result?.agenceAppartenance)
                }
                if (
                    result?.utilisateurAppartenance &&
                    result?.utilisateurAppartenance !== '' &&
                    (!targetUtilisateurLoaded || forcer)
                ) {
                    refreshTargetUser(result?.utilisateurAppartenance)
                }
            })
            .catch((e) => {
                setTargetEquipement(null)
            })
    }
    function refreshTargetUser(id_utilisateur: string) {
        GetTargetUtilisateur(id_utilisateur)
            .then((result) => {
                setTargetUtilisateur(result)
                setTargetUtilisateurLoaded(true)
            })
            .catch((e) => {
                setTargetUtilisateurLoaded(true)
                setTargetUtilisateur(null)
            })
    }

    function refreshTagetEntreprise(id_entreprise: string) {
        GetTargetEntreprise(id_entreprise)
            .then((result) => {
                setTargetEntreprise(result)
                setTargetEntrepriseLoaded(true)
            })
            .catch((e) => {
                setTargetEntreprise(null)
                setTargetEntrepriseLoaded(true)
            })
    }

    function refreshTargetAgence(id_agence: string) {
        GetTargetAgence(id_agence)
            .then((result) => {
                setTargetAgence(result)
                setTargetAgenceLoaded(true)
            })
            .catch((e) => {
                setTargetAgence(null)
                setTargetAgenceLoaded(true)
            })
    }
    useEffect(() => {
        if (authentification.firebaseUser != null) {
            refreshTargetEquipement()
        }
    }, [authentification])

    return (
        <ConditionalConnectAdminComplex>
            <BreadCrumbBuilder
                items={[
                    {
                        name: 'Équipements',
                        url: '/manage_equipements',
                    },
                    {
                        name:
                            targetEquipement?.categorie +
                            ' ' +
                            targetEquipement?.marque +
                            '(' +
                            targetEquipement?.modele +
                            ')',
                        url: '',
                    },
                ]}
            />
            <Container maxW="container.lg">
                {targetEquipement ? (
                    <>
                        <Center>
                            <Heading>
                                {targetEquipement?.categorie +
                                    ' ' +
                                    targetEquipement?.marque +
                                    '(' +
                                    targetEquipement?.modele +
                                    ')'}
                            </Heading>
                        </Center>
                        {!isOpenEditEquipement && (
                            <CustomBox
                                mt={'30px'}
                                hidden={isOpenEditEquipement}
                            >
                                <EditIcon
                                    boxSize={6}
                                    float={'right'}
                                    onClick={onOpenEditEquipement}
                                />
                                <Text ml={'10px'} as="b">
                                    Information de l'équipement{' '}
                                </Text>
                                <Box m={'30px'}>
                                    <Text>
                                        <b>Catégorie</b>:{' '}
                                        {targetEquipement?.categorie}
                                    </Text>
                                    <Text>
                                        <b>Marque</b>:{' '}
                                        {targetEquipement?.marque}
                                    </Text>
                                    <Text>
                                        <b>Modèle</b>:{' '}
                                        {targetEquipement?.modele}
                                    </Text>
                                    <Text>
                                        <b>Taille</b>:{' '}
                                        {targetEquipement?.taille}
                                    </Text>
                                    <Text>
                                        <b>Numéro de Série</b>:{' '}
                                        {targetEquipement?.numeroSerie}
                                    </Text>
                                    <Text>
                                        <b>Numéro de Marquage</b>:{' '}
                                        {targetEquipement?.numeroMarquage}
                                    </Text>
                                    <Text>
                                        <b>Numéro de Clé</b>:{' '}
                                        {targetEquipement?.numeroDeCle}
                                    </Text>
                                    {targetEquipement?.kilometrage && (
                                        <>
                                            {' '}
                                            <Text>
                                                <b>Kilométrage Actuel</b>:{' '}
                                                {targetEquipement?.kilometrage}{' '}
                                                kms
                                            </Text>{' '}
                                            {targetEquipement?.dateKilometrage && (
                                                <Text>
                                                    <b>Dernière déclaration</b>:{' '}
                                                    {moment(
                                                        targetEquipement.dateKilometrage
                                                    ).format('DD/MM/YYYY')}
                                                </Text>
                                            )}
                                        </>
                                    )}

                                    {targetEquipement?.dateDerniereMaintenance && (
                                        <Text>
                                            <b>Dernière maintenance </b>:{' '}
                                            {moment(
                                                targetEquipement.dateDerniereMaintenance
                                            ).format('DD/MM/YYYY')}
                                        </Text>
                                    )}
                                    {!targetEquipement?.dateDerniereMaintenance && (
                                        <Text>
                                            <b>Dernière maintenance </b>: Non
                                            réalisée
                                        </Text>
                                    )}

                                    {targetEquipement?.categorie === 'Velo' && (
                                        <>
                                            {!targetEquipement?.avecDateMiseEnService && (
                                                <Text mt={'20px'} color={'red'}>
                                                    <b>
                                                        L'équipement n'est pas
                                                        encore mis en service.
                                                    </b>
                                                </Text>
                                            )}
                                            {targetEquipement?.avecDateMiseEnService && (
                                                <Text mt={'20px'}>
                                                    <b>
                                                        Mis en service depuis le{' '}
                                                        <Text
                                                            color={'green'}
                                                            as="span"
                                                        >
                                                            {moment(
                                                                targetEquipement?.dateMiseEnService
                                                            ).format(
                                                                'DD MMMM YYYY'
                                                            )}
                                                        </Text>
                                                    </b>
                                                </Text>
                                            )}
                                        </>
                                    )}

                                    {targetEquipement?.hivernage && (
                                        <Text mt={'20px'} color={'red'}>
                                            <b>L'équipement est en hivernage</b>
                                        </Text>
                                    )}

                                    {UtilisateurNotAssigned && (
                                        <Text mt={'20px'}>
                                            <b>C'est un équipement partagé</b>
                                        </Text>
                                    )}
                                    <Wrap mt={'20px'}>
                                        <Spacer />
                                        <Text pt={'10px'} as="b">
                                            Action :
                                        </Text>
                                        {targetEquipement?.categorie ===
                                            CategorieEquipement.Velo && (
                                            <DeclarationKilometriqueJournal
                                                equipementCibleID={
                                                    id_equipement
                                                }
                                                oldKilometrage={
                                                    targetEquipement?.kilometrage
                                                }
                                                OnSuccesAddToJournal={() =>
                                                    refreshTargetEquipement()
                                                }
                                            />
                                        )}
                                        <AjoutMessageJournal
                                            equipementCibleID={id_equipement}
                                        />
                                        <DeclarerPBJournal
                                            equipementCibleID={id_equipement}
                                        />
                                        <DeclarerRepJournal
                                            equipementCibleID={id_equipement}
                                        />
                                        <DeclarerMaintenanceJournal
                                            equipementCibleID={id_equipement}
                                            OnSuccesAddToJournal={() =>
                                                refreshTargetEquipement()
                                            }
                                        />
                                        <ModalChangementAssignation
                                            mode="equipement"
                                            equipementId={id_equipement}
                                            onSuccess={() => {
                                                refreshTargetEquipement(true)
                                            }}
                                        />
                                        <Spacer />
                                    </Wrap>
                                </Box>
                            </CustomBox>
                        )}

                        {isOpenEditEquipement && (
                            <CustomBox mt={'30px'}>
                                <CloseIcon
                                    color="red"
                                    boxSize={6}
                                    float={'right'}
                                    onClick={onCloseEditEquipement}
                                />
                                <Text ml={'10px'} as="b">
                                    Édition de l'équipement
                                </Text>
                                <Box m={'30px'}>
                                    <CreationEditionEquipement
                                        equipement={targetEquipement}
                                        equipementId={id_equipement}
                                        utilisateurEditable={false}
                                        entrepriseEditable={false}
                                        agenceEditable={false}
                                        onSucess={() => {
                                            onCloseEditEquipement()
                                            refreshTargetEquipement()
                                        }}
                                    />
                                </Box>
                            </CustomBox>
                        )}

                        <Center mt={'30px'}>
                            <VoirJournal equipementId={id_equipement} modal />
                        </Center>
                    </>
                ) : (
                    <CustomBox p={'40px'} ml={'50px'} mr={'50px'} mt={'30px'}>
                        <Center>
                            <Spinner
                                thickness="4px"
                                speed="0.65s"
                                emptyColor="gray.200"
                                color="blue.500"
                                size="xl"
                            />
                        </Center>
                        <Center> Chargement de l'équipement</Center>
                    </CustomBox>
                )}

                {UtilisateurLoading && (
                    <CustomBox mt={'30px'}>
                        <Center>
                            <Spinner
                                thickness="4px"
                                speed="0.65s"
                                emptyColor="gray.200"
                                color="blue.500"
                                size="xl"
                            />
                        </Center>
                        <Center>Chargement de l'utilisateur</Center>
                    </CustomBox>
                )}
                {UtilisateurLoaded && (
                    <CustomBox mt={'30px'}>
                        <Box
                            float={'right'}
                            as={ReactRouterLink}
                            to={
                                '/edit_utilisateur?id_utilisateur=' +
                                targetEquipement?.utilisateurAppartenance
                            }
                        >
                            <ViewIcon boxSize={6} />
                        </Box>
                        <Text as="b" ml={'10px'}>
                            Information de l'utilisateur associé{' '}
                        </Text>
                        <Box m={'30px'}>
                            <Text>
                                <b>Nom</b> : {targetUtilisateur?.nom}
                            </Text>
                            <Text>
                                <b>Prénom</b> : {targetUtilisateur?.prenom}
                            </Text>
                            <Text>
                                <b>Email</b> : {targetUtilisateur?.mailContact}
                            </Text>
                            <Text>
                                <b>Téléphone</b> :{' '}
                                {targetUtilisateur?.telContact}
                            </Text>
                            <Text>
                                <b>Rôle</b> :{' '}
                                {ShowRole(targetUtilisateur?.role)}
                            </Text>
                        </Box>
                    </CustomBox>
                )}

                <Wrap mt={'30px'}>
                    <CustomBox w={'calc(50% - 18px)'} mr={'12px'}>
                        {EntrepriseLoading && (
                            <>
                                <Center>
                                    <Spinner
                                        thickness="4px"
                                        speed="0.65s"
                                        emptyColor="gray.200"
                                        color="blue.500"
                                        size="xl"
                                    />
                                </Center>
                                <Center>Chargement de l'entreprise</Center>
                            </>
                        )}
                        {EntrepriseNotAssigned && (
                            <>
                                <Center>
                                    L'équipement n'est pas assigné a une
                                    entreprise
                                </Center>
                            </>
                        )}
                        {EntrepriseLoaded && (
                            <>
                                <Box
                                    float={'right'}
                                    as={ReactRouterLink}
                                    to={
                                        '/edit_enterprise?id_entreprise=' +
                                        targetEquipement?.entrepriseAppartenance
                                    }
                                >
                                    <ViewIcon boxSize={6} />
                                </Box>
                                <Text ml={'10px'} as="b">
                                    Information de l'entreprise associée
                                </Text>
                                <Box m={'30px'}>
                                    <Text>
                                        <b>Nom</b> : {targetEntreprise?.nom}
                                    </Text>
                                    <Text>
                                        <b>Email</b> :{' '}
                                        {targetEntreprise?.mailContact}
                                    </Text>
                                    <Text>
                                        <b>Téléphone</b> :{' '}
                                        {targetEntreprise?.telContact}
                                    </Text>
                                    <Text>
                                        <b>Type du contrat</b> :{' '}
                                        {targetEntreprise?.typeContrat}
                                    </Text>
                                    <Text>
                                        <b>Numéro de contrat</b> :{' '}
                                        {targetEntreprise?.noContrat}
                                    </Text>
                                </Box>
                            </>
                        )}
                    </CustomBox>

                    <CustomBox w={'calc(50% - 18px)'} ml={'12px'}>
                        {AgenceLoading && (
                            <>
                                <Center>
                                    <Spinner
                                        thickness="4px"
                                        speed="0.65s"
                                        emptyColor="gray.200"
                                        color="blue.500"
                                        size="xl"
                                    />
                                </Center>
                                <Center> Chargement de l'agence</Center>
                            </>
                        )}
                        {AgenceNotAssigned && (
                            <>
                                <Center>
                                    L'équipement n'est pas assigné a une agence
                                </Center>
                            </>
                        )}
                        {AgenceLoaded && (
                            <>
                                <Box
                                    float={'right'}
                                    as={ReactRouterLink}
                                    to={
                                        '/edit_agence?id_entreprise=' +
                                        targetEquipement?.entrepriseAppartenance +
                                        '&id_agence=' +
                                        targetEquipement?.agenceAppartenance
                                    }
                                >
                                    <ViewIcon boxSize={6} />
                                </Box>
                                <Text ml={'10px'} as="b">
                                    Information de l'agence associée
                                </Text>
                                <Box m={'30px'}>
                                    <Text>
                                        <b>Nom</b>: {targetAgence?.nom}
                                    </Text>
                                    <Text>
                                        <b>Email</b> :{' '}
                                        {targetAgence?.mailContact}
                                    </Text>
                                    <Text>
                                        <b>Téléphone</b> :{' '}
                                        {targetAgence?.telContact}
                                    </Text>
                                </Box>
                            </>
                        )}
                    </CustomBox>
                </Wrap>
            </Container>
        </ConditionalConnectAdminComplex>
    )
}
