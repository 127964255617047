/*
 * Page dédiée au listing des équipement (avec filtres)
 */
import {
    Container,
    Center,
    Spinner,
    Text,
    Box,
    Wrap,
    FormControl,
    InputGroup,
    InputLeftAddon,
    Input,
    Select,
    Badge,
    Checkbox,
} from '@chakra-ui/react'
import {
    Agence,
    CategorieEquipement,
    Entreprise,
    Equipement,
} from '../../model'
import { ViewIcon } from '@chakra-ui/icons'
import { Link as ReactRouterLink } from 'react-router-dom'
import { useContext, useState, useEffect } from 'react'
import { AuthContext } from '../../auth/AuthContextProvider'
import { GetAllFilteredEquipements } from '../../firebaseManager/Communicator/EquipementCommunicator'
import { BreadCrumbBuilder } from '../../components/BreadCrumbBuilder'
import { ConditionalConnectAdminComplex } from '../../auth/ConditionalConnectAdminComplex'
import { CustomBox } from '../../components/CustomBox'
import { FormikProvider, useFormik } from 'formik'
import { GetAllEntreprises } from '../../firebaseManager/Communicator/EntrepriseCommunicator'
import { GetAllAgencesForTargetEntreprise } from '../../firebaseManager/Communicator/AgenceCommunicator'
import { Button } from '../../components/Button'
import { DocumentData, QueryDocumentSnapshot } from 'firebase/firestore'
import { ExportActionButton } from '../../components/ExportActionButton'

export type FilterEquipementValue = {
    categorie: string
    marque: string
    modele: string
    numeroSerie: string
    numeroMarquage: string
    entrepriseAppartenance: string
    agenceAppartenance: string
    hivernage: boolean
    partager: boolean
}

export const ManageEquipements = () => {
    const [allEquipements, setAllEquipements] = useState<Equipement[] | null>()
    const [allEntreprises, setAllEntreprises] = useState<Entreprise[] | null>()
    const [
        allAgencesForSelectedEntreprise,
        setAllAgencesForSelectedEntreprise,
    ] = useState<Agence[] | null>()
    const [lastDoc, setLastDoc] = useState<QueryDocumentSnapshot<
        DocumentData,
        DocumentData
    > | null>()

    const authentification = useContext(AuthContext)

    function refreshAllEquipements(suivant: boolean) {
        GetAllFilteredEquipements(
            formikFilterEquipement.values.categorie,
            formikFilterEquipement.values.marque,
            formikFilterEquipement.values.modele,
            formikFilterEquipement.values.numeroSerie,
            formikFilterEquipement.values.numeroMarquage,
            formikFilterEquipement.values.entrepriseAppartenance,
            formikFilterEquipement.values.agenceAppartenance,
            formikFilterEquipement.values.partager,
            formikFilterEquipement.values.hivernage,
            suivant && lastDoc ? lastDoc : null
        )
            .then((result) => {
                setAllEquipements(result.list)
                setLastDoc(result.last)
            })
            .catch((e) => setAllEquipements(null))
    }

    function RefreshAllEntreprises() {
        GetAllEntreprises()
            .then((result) => {
                setAllEntreprises(result)
            })
            .catch((e) => setAllEntreprises(null))
    }

    function RefreshAllAgencesForTargetEntreprise(entrepriseId: string) {
        GetAllAgencesForTargetEntreprise(entrepriseId)
            .then((result) => {
                setAllAgencesForSelectedEntreprise(result)
            })
            .catch((e) => setAllAgencesForSelectedEntreprise(null))
    }

    useEffect(() => {
        if (authentification.firebaseUser != null) {
            refreshAllEquipements(false)
            RefreshAllEntreprises()
        }
    }, [authentification])

    const formikFilterEquipement = useFormik({
        initialValues: {
            categorie: '',
            marque: '',
            modele: '',
            numeroSerie: '',
            numeroMarquage: '',
            entrepriseAppartenance: '',
            agenceAppartenance: '',
            hivernage: false,
            partager: false,
        },
        onSubmit: (values: FilterEquipementValue, actions) => {},
    })

    return (
        <ConditionalConnectAdminComplex>
            <BreadCrumbBuilder
                items={[
                    {
                        name: 'Equipements',
                        url: '',
                    },
                ]}
            />
            <ExportActionButton equipements />
            <Container maxW="container.lg">
                <Center>
                    <CustomBox
                        mb={'30px'}
                        borderRadius={'15px'}
                        padding={'30px'}
                        w={{
                            base: '100%',
                            md: 'calc(100% - 210px)',
                        }}
                    >
                        <Text as="b">Filtrage des équipements</Text>
                        <FormikProvider value={formikFilterEquipement}>
                            <Wrap p={'10px'}>
                                <Box width={'100%'}>
                                    <FormControl id="categorie">
                                        <InputGroup>
                                            <InputLeftAddon children="Catégorie" />
                                            <Select
                                                placeholder="Choisir catégorie"
                                                onChange={(e) => {
                                                    formikFilterEquipement.handleChange(
                                                        e
                                                    )
                                                }}
                                                value={
                                                    formikFilterEquipement
                                                        .values.categorie
                                                }
                                            >
                                                {Object.keys(
                                                    CategorieEquipement
                                                ).map(function (cat, idx) {
                                                    return (
                                                        <option
                                                            key={idx}
                                                            value={cat}
                                                        >
                                                            {cat}
                                                        </option>
                                                    )
                                                })}
                                            </Select>
                                        </InputGroup>
                                    </FormControl>
                                </Box>
                                <Box width={'calc(50% - 4px)'}>
                                    <FormControl id="marque">
                                        <InputGroup>
                                            <InputLeftAddon
                                                children="Marque"
                                                color={'#2d7bb6'}
                                            />
                                            <Input
                                                name="marque"
                                                type="text"
                                                onChange={(e) => {
                                                    formikFilterEquipement.handleChange(
                                                        e
                                                    )

                                                    formikFilterEquipement.setFieldValue(
                                                        'modele',
                                                        ''
                                                    )
                                                    formikFilterEquipement.setFieldValue(
                                                        'numeroSerie',
                                                        ''
                                                    )
                                                    formikFilterEquipement.setFieldValue(
                                                        'numeroMarquage',
                                                        ''
                                                    )
                                                }}
                                                value={
                                                    formikFilterEquipement
                                                        .values.marque
                                                }
                                            />
                                        </InputGroup>
                                    </FormControl>
                                </Box>
                                <Box width={'calc(50% - 4px)'}>
                                    <FormControl id="modele">
                                        <InputGroup>
                                            <InputLeftAddon
                                                children="Modèle"
                                                color={'#2d7bb6'}
                                            />
                                            <Input
                                                name="modele"
                                                type="text"
                                                onChange={(e) => {
                                                    formikFilterEquipement.handleChange(
                                                        e
                                                    )
                                                    formikFilterEquipement.setFieldValue(
                                                        'marque',
                                                        ''
                                                    )
                                                    formikFilterEquipement.setFieldValue(
                                                        'numeroSerie',
                                                        ''
                                                    )
                                                    formikFilterEquipement.setFieldValue(
                                                        'numeroMarquage',
                                                        ''
                                                    )
                                                }}
                                                value={
                                                    formikFilterEquipement
                                                        .values.modele
                                                }
                                            />
                                        </InputGroup>
                                    </FormControl>
                                </Box>
                                <Box width={'calc(50% - 4px)'}>
                                    <FormControl id="numeroSerie">
                                        <InputGroup>
                                            <InputLeftAddon
                                                children="N° série"
                                                color={'#2d7bb6'}
                                            />
                                            <Input
                                                name="numeroSerie"
                                                type="text"
                                                onChange={(e) => {
                                                    formikFilterEquipement.handleChange(
                                                        e
                                                    )
                                                    formikFilterEquipement.setFieldValue(
                                                        'marque',
                                                        ''
                                                    )
                                                    formikFilterEquipement.setFieldValue(
                                                        'modele',
                                                        ''
                                                    )
                                                    formikFilterEquipement.setFieldValue(
                                                        'numeroMarquage',
                                                        ''
                                                    )
                                                }}
                                                value={
                                                    formikFilterEquipement
                                                        .values.numeroSerie
                                                }
                                            />
                                        </InputGroup>
                                    </FormControl>
                                </Box>
                                <Box width={'calc(50% - 4px)'}>
                                    <FormControl id="numeroMarquage">
                                        <InputGroup>
                                            <InputLeftAddon
                                                children="N° marquage"
                                                color={'#2d7bb6'}
                                            />
                                            <Input
                                                name="numeroMarquage"
                                                type="text"
                                                onChange={(e) => {
                                                    formikFilterEquipement.handleChange(
                                                        e
                                                    )
                                                    formikFilterEquipement.setFieldValue(
                                                        'marque',
                                                        ''
                                                    )
                                                    formikFilterEquipement.setFieldValue(
                                                        'modele',
                                                        ''
                                                    )
                                                    formikFilterEquipement.setFieldValue(
                                                        'numeroSerie',
                                                        ''
                                                    )
                                                }}
                                                value={
                                                    formikFilterEquipement
                                                        .values.numeroMarquage
                                                }
                                            />
                                        </InputGroup>
                                    </FormControl>
                                </Box>
                                <Box width={'calc(50% - 4px)'}>
                                    <FormControl id="partager">
                                        <InputGroup>
                                            <InputLeftAddon
                                                children="Partagé ?"
                                                borderRadius={'5px'}
                                            />
                                            <Checkbox
                                                ml={'8px'}
                                                size="lg"
                                                name="partager"
                                                onChange={
                                                    formikFilterEquipement.handleChange
                                                }
                                                isChecked={
                                                    formikFilterEquipement
                                                        .values.partager
                                                }
                                            />
                                        </InputGroup>
                                    </FormControl>
                                </Box>
                                <Box width={'calc(50% - 4px)'}>
                                    <FormControl id="hivernage">
                                        <InputGroup>
                                            <InputLeftAddon
                                                children="Hivernage ?"
                                                borderRadius={'5px'}
                                            />
                                            <Checkbox
                                                ml={'8px'}
                                                size="lg"
                                                name="hivernage"
                                                onChange={
                                                    formikFilterEquipement.handleChange
                                                }
                                                isChecked={
                                                    formikFilterEquipement
                                                        .values.hivernage
                                                }
                                            />
                                        </InputGroup>
                                    </FormControl>
                                </Box>
                                <Box width={'100%'}>
                                    <FormControl id="entrepriseAppartenance">
                                        <InputGroup>
                                            <InputLeftAddon children="Entreprise" />{' '}
                                            <Select
                                                placeholder="Filtre Entreprise"
                                                onChange={(e) => {
                                                    formikFilterEquipement.handleChange(
                                                        e
                                                    )
                                                    formikFilterEquipement.setFieldValue(
                                                        'agenceAppartenance',
                                                        ''
                                                    )
                                                    RefreshAllAgencesForTargetEntreprise(
                                                        e.currentTarget.value
                                                    )
                                                }}
                                                value={
                                                    formikFilterEquipement
                                                        .values
                                                        .entrepriseAppartenance
                                                }
                                            >
                                                {allEntreprises?.map(function (
                                                    entreprise,
                                                    idx
                                                ) {
                                                    return (
                                                        <option
                                                            key={idx}
                                                            value={
                                                                entreprise.id
                                                            }
                                                        >
                                                            {entreprise.nom}
                                                        </option>
                                                    )
                                                })}
                                            </Select>
                                        </InputGroup>
                                    </FormControl>
                                    {formikFilterEquipement.values
                                        .entrepriseAppartenance !== '' &&
                                        allAgencesForSelectedEntreprise &&
                                        allAgencesForSelectedEntreprise.length >
                                            0 && (
                                            <FormControl
                                                id="agenceAppartenance"
                                                mt={'5px'}
                                            >
                                                <InputGroup>
                                                    <InputLeftAddon children="Agence" />{' '}
                                                    <Select
                                                        placeholder="Filtre Agence"
                                                        w={'300px'}
                                                        onChange={
                                                            formikFilterEquipement.handleChange
                                                        }
                                                        value={
                                                            formikFilterEquipement
                                                                .values
                                                                .agenceAppartenance
                                                        }
                                                    >
                                                        {allAgencesForSelectedEntreprise?.map(
                                                            function (
                                                                agence,
                                                                idx
                                                            ) {
                                                                return (
                                                                    <option
                                                                        key={
                                                                            idx
                                                                        }
                                                                        value={
                                                                            agence.id
                                                                        }
                                                                    >
                                                                        {
                                                                            agence.nom
                                                                        }
                                                                    </option>
                                                                )
                                                            }
                                                        )}
                                                    </Select>{' '}
                                                </InputGroup>
                                            </FormControl>
                                        )}
                                    {formikFilterEquipement.values
                                        .entrepriseAppartenance !== '' &&
                                        (!allAgencesForSelectedEntreprise ||
                                            allAgencesForSelectedEntreprise.length ===
                                                0) && (
                                            <Text color={'red'}>
                                                Pas d'agence pour cette
                                                entreprise
                                            </Text>
                                        )}
                                </Box>
                            </Wrap>
                            <Center>
                                <Button
                                    mt={'10px'}
                                    onClick={(e) =>
                                        refreshAllEquipements(false)
                                    }
                                >
                                    Filtrer
                                </Button>
                                <Button
                                    mt={'10px'}
                                    ml={'20px'}
                                    onClick={(e) => {
                                        formikFilterEquipement.resetForm()
                                    }}
                                    backgroundColor={'blue.800'}
                                >
                                    Reset
                                </Button>
                            </Center>
                        </FormikProvider>
                    </CustomBox>
                </Center>
                {allEquipements == null && (
                    <CustomBox p={'40px'} ml={'50px'} mr={'50px'}>
                        <Center>
                            <Spinner
                                thickness="4px"
                                speed="0.65s"
                                emptyColor="gray.200"
                                color="blue.500"
                                size="xl"
                            />
                        </Center>
                        <Center>Chargement des équipements</Center>
                    </CustomBox>
                )}
                {allEquipements && allEquipements?.length === 0 && (
                    <CustomBox p={'40px'} ml={'50px'} mr={'50px'}>
                        <Center>Il n'existe pas encore d'équipements</Center>
                    </CustomBox>
                )}
                {allEquipements && allEquipements?.length > 0 && (
                    <Wrap>
                        {allEquipements?.map(function (equipement, idx) {
                            return (
                                <CustomBox
                                    pl={'25px'}
                                    pr={'25px'}
                                    pt={'10px'}
                                    pb={'10px'}
                                    ml={'5px'}
                                    mr={'5px'}
                                    mb={'15px'}
                                    w={'calc(33% - 15px)'}
                                    key={idx}
                                >
                                    <Box
                                        float={'right'}
                                        as={ReactRouterLink}
                                        to={
                                            '/edit_equipement?id_equipement=' +
                                            equipement.id
                                        }
                                    >
                                        <ViewIcon boxSize={6} />
                                    </Box>

                                    <Text as="b" fontSize="lg">
                                        {equipement.categorie}
                                        {equipement.hivernage && (
                                            <Badge colorScheme="red" ml={'5px'}>
                                                HIV
                                            </Badge>
                                        )}
                                        {!equipement.utilisateurAppartenance && (
                                            <Badge
                                                colorScheme="green"
                                                ml={'5px'}
                                            >
                                                PART
                                            </Badge>
                                        )}
                                    </Text>

                                    <Text fontSize="sm">
                                        <b>Marque :</b> {equipement.marque}
                                    </Text>
                                    <Text fontSize="sm">
                                        <b>Modèle :</b> {equipement.modele}
                                    </Text>
                                    <Text fontSize="sm">
                                        <b>N° Série :</b>{' '}
                                        {equipement.numeroSerie}
                                    </Text>
                                    <Text fontSize="sm">
                                        <b>N° Marquage :</b>{' '}
                                        {equipement.numeroMarquage}
                                    </Text>
                                </CustomBox>
                            )
                        })}
                    </Wrap>
                )}
            </Container>
        </ConditionalConnectAdminComplex>
    )
}
