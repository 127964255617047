/*
 * Page dédiée aux reparateurs
 */

import { ConditionalConnect } from '../../auth/ConditionalConnect'
import { Login } from '../Authentification/Login'
import { GetTargetUtilisateur } from '../../firebaseManager/Communicator/UtilisateurCommunicator'
import { useState } from 'react'
import { Button } from '../../components/Button'
import {
    CategorieEquipement,
    Equipement,
    Utilisateur,
    Entreprise,
    Agence,
} from '../../model'
import { GetTargetEquipementWithNumeroMarquage } from '../../firebaseManager/Communicator/EquipementCommunicator'
import {
    Box,
    Text,
    Container,
    Center,
    Spinner,
    Wrap,
    Heading,
    Spacer,
    InputGroup,
    InputLeftAddon,
    Input,
} from '@chakra-ui/react'
import { CustomBox } from '../../components/CustomBox'
import {
    AjoutMessageJournal,
    DeclarationKilometriqueJournal,
    DeclarerMaintenanceJournal,
    DeclarerPBJournal,
    DeclarerRepJournal,
} from '../../components/AjoutAuJournal'
import { VoirJournal } from '../../components/VoirJournal'
import moment from 'moment'
import { GetTargetEntreprise } from '../../firebaseManager/Communicator/EntrepriseCommunicator'
import { ModalPDF } from '../../components/ModalPDF'
import { GetTargetAgence } from '../../firebaseManager/Communicator/AgenceCommunicator'

export const ReparateurView = () => {
    const [targetEquipementMarquage, setTargetEquipementMarquage] =
        useState<string>('')
    const [targetEquipement, setTargetEquipement] = useState<
        Equipement | undefined
    >()
    const [targetUtilisateur, setTargetUtilisateur] =
        useState<Utilisateur | null>()
    const [targetUtilisateurLoaded, setTargetUtilisateurLoaded] =
        useState<boolean>(false)

    const [targetEntreprise, setTargetEntreprise] =
        useState<Entreprise | null>()
    const [targetEntrepriseLoaded, setTargetEntrepriseLoaded] =
        useState<boolean>(false)

    const [targetAgence, setTargetAgence] = useState<Agence | null>()
    const [targetAgenceLoaded, setTargetAgenceLoaded] = useState<boolean>(false)

    const [isLoading, setIsLoading] = useState<boolean>()
    const [isError, setIsError] = useState<boolean>()
    const [errorText, setErrorText] = useState<String>()

    function refreshTargetUser(id_utilisateur: string) {
        GetTargetUtilisateur(id_utilisateur)
            .then((result) => {
                setTargetUtilisateur(result)
                setTargetUtilisateurLoaded(true)
            })
            .catch((e) => {
                setTargetUtilisateurLoaded(true)
                setTargetUtilisateur(null)
            })
    }

    function refreshTagetEntreprise(id_entreprise: string) {
        GetTargetEntreprise(id_entreprise)
            .then((result) => {
                setTargetEntreprise(result)
                setTargetEntrepriseLoaded(true)
            })
            .catch((e) => {
                setTargetEntreprise(null)
                setTargetEntrepriseLoaded(true)
            })
    }

    function refreshTagetAgence(id_agence: string) {
        GetTargetAgence(id_agence)
            .then((result) => {
                setTargetAgence(result)
                setTargetAgenceLoaded(true)
            })
            .catch((e) => {
                setTargetAgence(null)
                setTargetAgenceLoaded(true)
            })
    }

    function getEquipement(marquage: string) {
        if (marquage !== '') {
            setIsLoading(true)
            setIsError(false)
            setErrorText('')
            setTargetEquipement(undefined)

            GetTargetEquipementWithNumeroMarquage(marquage)
                .then((result) => {
                    setIsLoading(false)
                    if (result) {
                        setTargetEquipement(result)
                        if (result.utilisateurAppartenance)
                            refreshTargetUser(result.utilisateurAppartenance)
                        if (result.entrepriseAppartenance)
                            refreshTagetEntreprise(
                                result.entrepriseAppartenance
                            )
                        if (result.agenceAppartenance) {
                            refreshTagetAgence(result.agenceAppartenance)
                        }
                    } else {
                        setIsError(true)
                        setErrorText(
                            'Aucun équipements ne correspond a ce numéro de marquage'
                        )
                    }
                })
                .catch((e) => {
                    setIsLoading(false)
                    setIsError(true)
                    setErrorText(
                        "Erreur lors de la récupération de l'équipement"
                    )
                })
        } else {
            setIsError(true)
            setErrorText(
                "Utilisez le champ 'numéro de marquage' pour réaliser une recherche d'équipement"
            )
        }
    }

    return (
        <>
            <ConditionalConnect showIfConnected>
                <Container maxW="container.md">
                    <Center mt={'25px'}>
                        <Heading fontSize={'3xl'} textAlign={'center'}>
                            Espace réparateur
                        </Heading>
                    </Center>
                    <CustomBox p={'40px'} mt={'30px'}>
                        <Center px={'30px'} textAlign={'center'}>
                            Retrouvez le vélo à réparer en renseignant son
                            numéro de marquage.
                        </Center>
                        <Center mt={'30px'}>
                            <InputGroup>
                                <InputLeftAddon children="Numéro de marquage" />
                                <Input
                                    name="mailContact"
                                    type="text"
                                    onChange={(e) =>
                                        setTargetEquipementMarquage(
                                            e.target.value
                                        )
                                    }
                                    value={targetEquipementMarquage}
                                />
                            </InputGroup>
                        </Center>
                        <Center mt={'30px'}>
                            <Button
                                onClick={(e) =>
                                    getEquipement(targetEquipementMarquage)
                                }
                                backgroundColor={'grey.900'}
                                disabled={!targetEquipementMarquage}
                            >
                                Rechercher
                            </Button>
                        </Center>
                    </CustomBox>
                    {isError && (
                        <>
                            <CustomBox
                                p={'10px'}
                                ml={'50px'}
                                mr={'50px'}
                                mt={'20px'}
                            >
                                <Center color={'red'} textAlign={'center'}>
                                    {errorText}
                                </Center>
                            </CustomBox>
                        </>
                    )}
                    {isLoading && (
                        <CustomBox
                            p={'40px'}
                            ml={'50px'}
                            mr={'50px'}
                            mt={'30px'}
                        >
                            <Center>
                                <Spinner
                                    thickness="4px"
                                    speed="0.65s"
                                    emptyColor="gray.200"
                                    color="blue.500"
                                    size="xl"
                                />
                            </Center>
                            <Center textAlign={'center'}>
                                Chargement de l'équipement
                            </Center>
                        </CustomBox>
                    )}
                    {targetEquipement && (
                        <>
                            <CustomBox mt={'30px'}>
                                <Center>
                                    <Heading fontSize={'xl'}>
                                        {targetEquipement?.categorie +
                                            ' ' +
                                            targetEquipement?.marque +
                                            '(' +
                                            targetEquipement?.modele +
                                            ')'}
                                    </Heading>
                                </Center>
                                <Box m={'30px'}>
                                    {targetEquipement.entrepriseAppartenance && (
                                        <>
                                            <Text>
                                                <b>
                                                    Appartient à l'entreprise{' '}
                                                </b>
                                                : {targetEntreprise?.nom}
                                            </Text>
                                            {targetEquipement.agenceAppartenance && (
                                                <>
                                                    <Text>
                                                        <b>
                                                            Appartient à
                                                            l'agence{' '}
                                                        </b>
                                                        : {targetAgence?.nom}
                                                    </Text>
                                                    <Text>
                                                        <b>
                                                            Adresse de l'agence
                                                            :{' '}
                                                        </b>
                                                        :{' '}
                                                        {targetAgence?.adresse}
                                                    </Text>
                                                </>
                                            )}
                                            <Text>
                                                <b>URL Contrat d'entretien</b> :{' '}
                                                {targetEntreprise?.contratEntretien && (
                                                    <ModalPDF
                                                        url={
                                                            targetEntreprise?.contratEntretien
                                                        }
                                                        lien
                                                        titre="Contrat d'entretien de votre entreprise"
                                                    />
                                                )}
                                                {!targetEntreprise?.contratEntretien && (
                                                    <>Non renseigné</>
                                                )}
                                            </Text>
                                        </>
                                    )}
                                    {!targetEquipement.utilisateurAppartenance && (
                                        <Text>
                                            <b>Équipement partagé</b>
                                        </Text>
                                    )}
                                    {targetEquipement.utilisateurAppartenance && (
                                        <Text>
                                            <b>Utilisateur</b>:{' '}
                                            {targetUtilisateur?.nom}{' '}
                                            {targetUtilisateur?.prenom}
                                        </Text>
                                    )}

                                    <Text mt={'20px'}>
                                        <b>Catégorie</b>:{' '}
                                        {targetEquipement?.categorie}
                                    </Text>
                                    <Text>
                                        <b>Marque</b>:{' '}
                                        {targetEquipement?.marque}
                                    </Text>
                                    <Text>
                                        <b>Modèle</b>:{' '}
                                        {targetEquipement?.modele}
                                    </Text>
                                    <Text>
                                        <b>Taille</b>:{' '}
                                        {targetEquipement?.taille}
                                    </Text>
                                    <Text>
                                        <b>Numéro de Série</b>:{' '}
                                        {targetEquipement?.numeroSerie}
                                    </Text>
                                    <Text>
                                        <b>Numéro de Marquage</b>:{' '}
                                        {targetEquipement?.numeroMarquage}
                                    </Text>
                                    <Text>
                                        <b>Numéro de Clé</b>:{' '}
                                        {targetEquipement?.numeroDeCle}
                                    </Text>
                                    {targetEquipement?.kilometrage && (
                                        <>
                                            <Text>
                                                <b>Kilométrage Actuel</b>:{' '}
                                                {targetEquipement?.kilometrage}{' '}
                                                kms
                                            </Text>{' '}
                                            {targetEquipement?.dateKilometrage && (
                                                <Text>
                                                    <b>Dernière déclaration</b>:{' '}
                                                    {moment(
                                                        targetEquipement.dateKilometrage
                                                    ).format('DD/MM/YYYY')}
                                                </Text>
                                            )}
                                        </>
                                    )}
                                    {targetEquipement?.dateDerniereMaintenance && (
                                        <Text>
                                            <b>Dernière maintenance </b>:{' '}
                                            {moment(
                                                targetEquipement.dateDerniereMaintenance
                                            ).format('DD/MM/YYYY')}
                                        </Text>
                                    )}
                                    {!targetEquipement?.dateDerniereMaintenance && (
                                        <Text>
                                            <b>Dernière maintenance </b>: Non
                                            réalisée
                                        </Text>
                                    )}

                                    {targetEquipement?.categorie === 'Velo' && (
                                        <>
                                            {!targetEquipement?.avecDateMiseEnService && (
                                                <Text mt={'20px'} color={'red'}>
                                                    <b>
                                                        L'équipement n'est pas
                                                        encore mis en service.
                                                    </b>
                                                </Text>
                                            )}
                                            {targetEquipement?.avecDateMiseEnService && (
                                                <Text mt={'20px'}>
                                                    <b>
                                                        Mis en service depuis le{' '}
                                                        <Text
                                                            color={'green'}
                                                            as="span"
                                                        >
                                                            {moment(
                                                                targetEquipement?.dateMiseEnService
                                                            ).format(
                                                                'DD MMMM YYYY'
                                                            )}
                                                        </Text>
                                                    </b>
                                                </Text>
                                            )}
                                        </>
                                    )}

                                    <Center my={'20px'}>
                                        <VoirJournal
                                            equipementId={targetEquipement.id}
                                            modal
                                            modeReparateur
                                        />
                                    </Center>

                                    <Wrap mt={'20px'}>
                                        <Spacer />
                                        <Text pt={'10px'} as="b">
                                            Action :
                                        </Text>
                                        {targetEquipement?.categorie ===
                                            CategorieEquipement.Velo && (
                                            <DeclarationKilometriqueJournal
                                                equipementCibleID={
                                                    targetEquipement.id
                                                }
                                                oldKilometrage={
                                                    targetEquipement?.kilometrage
                                                }
                                                OnSuccesAddToJournal={() =>
                                                    getEquipement(
                                                        targetEquipement.numeroMarquage!
                                                    )
                                                }
                                            />
                                        )}
                                        <AjoutMessageJournal
                                            equipementCibleID={
                                                targetEquipement.id
                                            }
                                        />
                                        <DeclarerPBJournal
                                            equipementCibleID={
                                                targetEquipement.id
                                            }
                                        />
                                        <DeclarerRepJournal
                                            equipementCibleID={
                                                targetEquipement.id
                                            }
                                        />
                                        <DeclarerMaintenanceJournal
                                            equipementCibleID={
                                                targetEquipement.id
                                            }
                                            OnSuccesAddToJournal={() =>
                                                getEquipement(
                                                    targetEquipement.numeroMarquage!
                                                )
                                            }
                                        />
                                        <Spacer />
                                    </Wrap>
                                </Box>
                            </CustomBox>
                        </>
                    )}
                </Container>
            </ConditionalConnect>
            <ConditionalConnect showIfNotConnected>
                <Login />
            </ConditionalConnect>
        </>
    )
}
