/*
 * Page dédié a l'a déconnexion
 */
import { getAuth, signOut } from 'firebase/auth'
import { Box, Heading, Text, Spinner } from '@chakra-ui/react'

export const Logout = () => {
    const auth = getAuth()
    signOut(auth)
        .then(() => {})
        .catch((error) => {})

    return (
        <Box textAlign="center" py={10} px={6}>
            <Box display="inline-block">
                <Spinner
                    thickness="8px"
                    speed="0.65s"
                    emptyColor="gray.200"
                    color="red.500"
                    size="xl"
                />
            </Box>
            <Heading as="h2" size="xl" mt={6} mb={2}>
                Déconnexion en cours . . .
            </Heading>
            <Text color={'gray.500'}>
                Vous allez étre redirigé sur l'accueil
            </Text>
        </Box>
    )
}
