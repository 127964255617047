/*
 * Formulaire autonome et réutilisable permettant de créer ou d'éditer un objet Utilisateur
 */

import {
    FormControl,
    InputGroup,
    InputLeftAddon,
    useToast,
    Center,
    Select,
    Text,
    Textarea,
    Input,
} from '@chakra-ui/react'
import { Button } from '../../Button'
import { FormikProvider, useFormik } from 'formik'
import * as yup from 'yup'
import { Entreprise, Equipement } from '../../../model'
import { CallFunctionMessageDeContact } from '../../../firebaseManager/Communicator/FunctionCallManager'
import { AgenceContext } from '../../../auth/AgenceContextProvider'
import { useContext } from 'react'

const validationSchema = yup.object().shape({
    message: yup.string().required(),
    objet: yup.string().required(),
})

export type ContactValue = {
    message: string
    objet: string
}

export type Props = {
    entreprise: Entreprise
    equipement: Equipement
    onSucess?: () => void
    onLoadingStart?: () => void
    onLoadingEnd?: () => void
}

export const FormulaireContact = (props: Props) => {
    const toast = useToast()
    const agence = useContext(AgenceContext)

    const formikContact = useFormik({
        validationSchema,
        initialValues: {
            message: '',
            objet: '',
        },
        onSubmit: (values: ContactValue, actions) => {
            if (props.onLoadingStart) props.onLoadingStart()
            CallFunctionMessageDeContact(
                props.entreprise,
                agence,
                props.equipement,
                values.message,
                values.objet
            )
                .then((result: any) => {
                    if (props.onLoadingEnd) props.onLoadingEnd()
                    actions.setSubmitting(false)
                    actions.resetForm()
                    if (props.onSucess) props.onSucess()
                    toast({
                        title: 'Message envoyé!',
                        description: '',
                        status: 'success',
                        duration: 2000,
                        isClosable: true,
                    })
                })
                .catch((e) => {
                    if (props.onLoadingEnd) props.onLoadingEnd()
                    actions.setSubmitting(false)
                    console.log('error on send message ' + e)

                    toast({
                        title: "Échec lors de l'envoi du message",
                        description: '',
                        status: 'error',
                        duration: 4000,
                        isClosable: true,
                    })
                })
        },
    })

    return (
        <FormikProvider value={formikContact}>
            <FormControl id="objet" mt={4}>
                <InputGroup>
                    <InputLeftAddon>
                        Objet de votre demande <Text color={'red'}>*</Text>
                    </InputLeftAddon>
                    <Input
                        name="objet"
                        type="text"
                        onChange={formikContact.handleChange}
                        value={formikContact.values.objet}
                    />
                </InputGroup>
            </FormControl>
            <FormControl id="message" mt={4}>
                <InputGroup>
                    <InputLeftAddon>
                        Votre message <Text color={'red'}>*</Text>
                    </InputLeftAddon>
                    <Textarea
                        name="message"
                        onChange={formikContact.handleChange}
                        value={formikContact.values.message}
                    />
                </InputGroup>
            </FormControl>

            <Center>
                <Button
                    mt={4}
                    mr={3}
                    onClick={formikContact.submitForm}
                    isDisabled={!formikContact.isValid || !formikContact.dirty}
                    isLoading={formikContact.isSubmitting}
                >
                    Envoyer
                </Button>
            </Center>
        </FormikProvider>
    )
}
