/*
 * Pied de page général du site
 */
import { Box, useColorModeValue } from '@chakra-ui/react'

export default function Footer() {
    return (
        <Box
            mt={'50px'}
            bg={useColorModeValue('gray.50', 'gray.900')}
            color={useColorModeValue('gray.700', 'gray.200')}
            textAlign={'center'}
            h={'30px'}
        >
            Application FenX Mobility @2024 |{' '}
            <a
                href="https://www.fenxmobility.com/mentions-legales/"
                style={{ color: '#2d7bb6' }}
                rel="noreferrer"
                target="_blank"
            >
                Mentions légales
            </a>{' '}
            |
            <a
                href="https://www.fenxmobility.com/cgu"
                style={{ color: '#2d7bb6' }}
                rel="noreferrer"
                target="_blank"
            >
                CGU
            </a>
        </Box>
    )
}
