/*
 * Page dédiée au listing des utilisateurs (avec filtres)
 */
import { QueryDocumentSnapshot, DocumentData } from 'firebase/firestore'
import {
    Wrap,
    FormControl,
    InputGroup,
    InputLeftAddon,
    Select,
    Text,
    Container,
    Input,
    Box,
    Center,
    Spinner,
    Flex,
} from '@chakra-ui/react'
import { Utilisateur, Entreprise, Agence } from '../../model'
import { Link as ReactRouterLink } from 'react-router-dom'
import { GetAllFilteredUtilisateurs } from '../../firebaseManager/Communicator/UtilisateurCommunicator'
import { GetAllAgencesForTargetEntreprise } from '../../firebaseManager/Communicator/AgenceCommunicator'
import { GetAllEntreprises } from '../../firebaseManager/Communicator/EntrepriseCommunicator'
import { EmailIcon, PhoneIcon, ViewIcon } from '@chakra-ui/icons'
import { Button } from '../../components/Button'
import { FormikProvider, useFormik } from 'formik'
import { useContext, useState, useEffect } from 'react'
import { AuthContext } from '../../auth/AuthContextProvider'
import { ButtonToolTip } from '../../components/ButtonToolTip'
import { BreadCrumbBuilder } from '../../components/BreadCrumbBuilder'
import { CustomBox } from '../../components/CustomBox'
import { ConditionalConnectAdminComplex } from '../../auth/ConditionalConnectAdminComplex'
import { ShowRole } from '../../utils/helpers'
import { ModalSuppressionUtilisateur } from '../../components/ModalSuppression'
import { ExportActionButton } from '../../components/ExportActionButton'

export type FilterUtilisateurValue = {
    nom: string
    prenom: string
    mailContact: string
    entrepriseAppartenance: string
    agenceAppartenance: string
}

export const ManageUtilisateurs = () => {
    const [allUtilisateurs, setAllUtilisateurs] = useState<
        Utilisateur[] | null
    >()
    const [lastDoc, setLastDoc] = useState<QueryDocumentSnapshot<
        DocumentData,
        DocumentData
    > | null>()

    const [allEntreprises, setAllEntreprises] = useState<Entreprise[] | null>()
    const [
        allAgencesForSelectedEntreprise,
        setAllAgencesForSelectedEntreprise,
    ] = useState<Agence[] | null>()

    const authentification = useContext(AuthContext)

    const formikFilterUtilisateur = useFormik({
        initialValues: {
            nom: '',
            prenom: '',
            mailContact: '',
            entrepriseAppartenance: '',
            agenceAppartenance: '',
        },
        onSubmit: (values: FilterUtilisateurValue, actions) => {},
    })

    function RefreshAllEntreprises() {
        GetAllEntreprises()
            .then((result) => {
                setAllEntreprises(result)
            })
            .catch((e) => setAllEntreprises(null))
    }

    function RefreshAllAgencesForTargetEntreprise(entrepriseId: string) {
        GetAllAgencesForTargetEntreprise(entrepriseId)
            .then((result) => {
                setAllAgencesForSelectedEntreprise(result)
            })
            .catch((e) => setAllAgencesForSelectedEntreprise(null))
    }

    function RefreshAllUtilisateurs(suivant: boolean) {
        GetAllFilteredUtilisateurs(
            formikFilterUtilisateur.values.nom,
            formikFilterUtilisateur.values.prenom,
            formikFilterUtilisateur.values.mailContact,
            formikFilterUtilisateur.values.entrepriseAppartenance,
            formikFilterUtilisateur.values.agenceAppartenance,
            suivant && lastDoc ? lastDoc : null
        )
            .then((result) => {
                setAllUtilisateurs(result.list)
                setLastDoc(result.last)
            })
            .catch((e) => setAllUtilisateurs(null))
    }

    useEffect(() => {
        if (authentification.firebaseUser != null) {
            RefreshAllUtilisateurs(false)
            RefreshAllEntreprises()
        }
    }, [authentification])

    return (
        <ConditionalConnectAdminComplex>
            <BreadCrumbBuilder
                items={[
                    {
                        name: 'Utilisateurs',
                        url: '/manage_utilisateurs',
                    },
                ]}
            />
            <ExportActionButton users />
            <Container maxW="container.lg">
                <Center>
                    <CustomBox
                        mb={'30px'}
                        borderRadius={'15px'}
                        padding={'30px'}
                        w={{
                            base: '100%',
                            md: 'calc(100% - 210px)',
                        }}
                    >
                        <Text as="b">Filtrage des utilisateurs</Text>
                        <FormikProvider value={formikFilterUtilisateur}>
                            <Wrap p={'10px'}>
                                <Box width={'calc(50% - 4px)'}>
                                    <FormControl id="nom">
                                        <InputGroup>
                                            <InputLeftAddon
                                                children="Nom"
                                                color={'#2d7bb6'}
                                            />
                                            <Input
                                                name="nom"
                                                type="text"
                                                onChange={(e) => {
                                                    formikFilterUtilisateur.handleChange(
                                                        e
                                                    )

                                                    formikFilterUtilisateur.setFieldValue(
                                                        'mailContact',
                                                        ''
                                                    )
                                                    formikFilterUtilisateur.setFieldValue(
                                                        'prenom',
                                                        ''
                                                    )
                                                }}
                                                value={
                                                    formikFilterUtilisateur
                                                        .values.nom
                                                }
                                            />
                                        </InputGroup>
                                    </FormControl>
                                </Box>
                                <Box width={'calc(50% - 4px)'}>
                                    <FormControl id="prenom">
                                        <InputGroup>
                                            <InputLeftAddon
                                                children="Prénom"
                                                color={'#2d7bb6'}
                                            />
                                            <Input
                                                name="prenom"
                                                type="text"
                                                onChange={(e) => {
                                                    formikFilterUtilisateur.handleChange(
                                                        e
                                                    )

                                                    formikFilterUtilisateur.setFieldValue(
                                                        'mailContact',
                                                        ''
                                                    )
                                                    formikFilterUtilisateur.setFieldValue(
                                                        'nom',
                                                        ''
                                                    )
                                                }}
                                                value={
                                                    formikFilterUtilisateur
                                                        .values.prenom
                                                }
                                            />
                                        </InputGroup>
                                    </FormControl>
                                </Box>
                                <Box width={'100%'}>
                                    <FormControl id="mailContact">
                                        <InputGroup>
                                            <InputLeftAddon
                                                children="Mail"
                                                color={'#2d7bb6'}
                                            />
                                            <Input
                                                name="mailContact"
                                                type="text"
                                                onChange={(e) => {
                                                    formikFilterUtilisateur.handleChange(
                                                        e
                                                    )

                                                    formikFilterUtilisateur.setFieldValue(
                                                        'prenom',
                                                        ''
                                                    )
                                                    formikFilterUtilisateur.setFieldValue(
                                                        'nom',
                                                        ''
                                                    )
                                                }}
                                                value={
                                                    formikFilterUtilisateur
                                                        .values.mailContact
                                                }
                                            />
                                        </InputGroup>
                                    </FormControl>
                                </Box>
                                <Box width={'100%'}>
                                    <FormControl id="entrepriseAppartenance">
                                        <InputGroup>
                                            <InputLeftAddon children="Entreprise" />{' '}
                                            <Select
                                                placeholder="choisir Entreprise"
                                                onChange={(e) => {
                                                    formikFilterUtilisateur.handleChange(
                                                        e
                                                    )
                                                    formikFilterUtilisateur.setFieldValue(
                                                        'agenceAppartenance',
                                                        ''
                                                    )
                                                    RefreshAllAgencesForTargetEntreprise(
                                                        e.currentTarget.value
                                                    )
                                                }}
                                                value={
                                                    formikFilterUtilisateur
                                                        .values
                                                        .entrepriseAppartenance
                                                }
                                            >
                                                {allEntreprises?.map(function (
                                                    entreprise,
                                                    idx
                                                ) {
                                                    return (
                                                        <option
                                                            key={idx}
                                                            value={
                                                                entreprise.id
                                                            }
                                                        >
                                                            {entreprise.nom}
                                                        </option>
                                                    )
                                                })}
                                            </Select>
                                        </InputGroup>
                                    </FormControl>
                                    {formikFilterUtilisateur.values
                                        .entrepriseAppartenance !== '' &&
                                        allAgencesForSelectedEntreprise &&
                                        allAgencesForSelectedEntreprise.length >
                                            0 && (
                                            <FormControl
                                                id="agenceAppartenance"
                                                mt={'5px'}
                                            >
                                                <InputGroup>
                                                    <InputLeftAddon children="Agence" />{' '}
                                                    <Select
                                                        placeholder="choisir Agence"
                                                        w={'300px'}
                                                        onChange={
                                                            formikFilterUtilisateur.handleChange
                                                        }
                                                        value={
                                                            formikFilterUtilisateur
                                                                .values
                                                                .agenceAppartenance
                                                        }
                                                    >
                                                        {allAgencesForSelectedEntreprise?.map(
                                                            function (
                                                                agence,
                                                                idx
                                                            ) {
                                                                return (
                                                                    <option
                                                                        key={
                                                                            idx
                                                                        }
                                                                        value={
                                                                            agence.id
                                                                        }
                                                                    >
                                                                        {
                                                                            agence.nom
                                                                        }
                                                                    </option>
                                                                )
                                                            }
                                                        )}
                                                    </Select>{' '}
                                                </InputGroup>
                                            </FormControl>
                                        )}
                                    {formikFilterUtilisateur.values
                                        .entrepriseAppartenance !== '' &&
                                        (!allAgencesForSelectedEntreprise ||
                                            allAgencesForSelectedEntreprise.length ===
                                                0) && (
                                            <Text color={'red'}>
                                                Pas d'agence pour cette
                                                entreprise
                                            </Text>
                                        )}
                                </Box>
                            </Wrap>
                            <Center>
                                <Button
                                    mt={'10px'}
                                    onClick={(e) =>
                                        RefreshAllUtilisateurs(false)
                                    }
                                >
                                    Filtrer
                                </Button>
                                <Button
                                    mt={'10px'}
                                    ml={'20px'}
                                    onClick={(e) => {
                                        formikFilterUtilisateur.resetForm()
                                    }}
                                    backgroundColor={'blue.800'}
                                >
                                    Reset
                                </Button>
                            </Center>
                        </FormikProvider>
                    </CustomBox>
                </Center>
                {allUtilisateurs == null && (
                    <CustomBox p={'40px'} ml={'50px'} mr={'50px'}>
                        <Center>
                            <Spinner
                                thickness="4px"
                                speed="0.65s"
                                emptyColor="gray.200"
                                color="blue.500"
                                size="xl"
                            />
                        </Center>
                        <Center>Chargement des utilisateurs</Center>
                    </CustomBox>
                )}
                {allUtilisateurs && allUtilisateurs?.length === 0 && (
                    <CustomBox p={'40px'} ml={'50px'} mr={'50px'}>
                        <Center>Aucun utilisateur à afficher</Center>
                    </CustomBox>
                )}
                {allUtilisateurs && allUtilisateurs?.length > 0 && (
                    <>
                        {allUtilisateurs?.map(function (utilisateur, idx) {
                            return (
                                <CustomBox
                                    pl={'25px'}
                                    pr={'25px'}
                                    pt={'10px'}
                                    pb={'10px'}
                                    h={'68px'}
                                    ml={'50px'}
                                    mr={'50px'}
                                    mb={'10px'}
                                    key={idx}
                                >
                                    <Flex>
                                        <Box
                                            mt={'0px'}
                                            width={'calc( 100% - 190px )'}
                                        >
                                            <Text as="b" fontSize="lg">
                                                {utilisateur.nom}{' '}
                                                {utilisateur.prenom}
                                            </Text>
                                            <br></br>
                                            <Text fontSize="sm">
                                                {ShowRole(utilisateur.role)}{' '}
                                                {utilisateur.role !==
                                                    'admin' && <>de</>}{' '}
                                                {utilisateur.entrepriseAppartenance
                                                    ? allEntreprises?.find(
                                                          (entreprise) =>
                                                              entreprise.id ===
                                                              utilisateur.entrepriseAppartenance
                                                      )?.nom
                                                    : 'NC'}
                                            </Text>
                                        </Box>

                                        <Box w={'190px'}>
                                            <ButtonToolTip
                                                label={utilisateur.mailContact!}
                                                textToCopy={
                                                    utilisateur.mailContact!
                                                }
                                            >
                                                <EmailIcon />
                                            </ButtonToolTip>

                                            <ButtonToolTip
                                                label={utilisateur.telContact!}
                                                textToCopy={
                                                    utilisateur.telContact!
                                                }
                                            >
                                                <PhoneIcon />
                                            </ButtonToolTip>

                                            <Button
                                                colorScheme="blue"
                                                p={0}
                                                as={ReactRouterLink}
                                                to={
                                                    '/edit_utilisateur?id_utilisateur=' +
                                                    utilisateur.id
                                                }
                                            >
                                                <ViewIcon />
                                            </Button>
                                            <ModalSuppressionUtilisateur
                                                targetId={utilisateur.id}
                                                nomEntite={
                                                    utilisateur.nom +
                                                    ' ' +
                                                    utilisateur.prenom
                                                }
                                                onSuccess={() =>
                                                    RefreshAllUtilisateurs(
                                                        false
                                                    )
                                                }
                                            />
                                        </Box>
                                    </Flex>
                                </CustomBox>
                            )
                        })}
                    </>
                )}
                <Center>
                    <Button
                        onClick={(e) => RefreshAllUtilisateurs(true)}
                        p={2}
                        mt={'10px'}
                    >
                        Suivant
                    </Button>
                </Center>
            </Container>
        </ConditionalConnectAdminComplex>
    )
}
