/*
 * bloc simple d'affichage d'une vidéo youtube pour les tutos
 */
import { Box, Text } from '@chakra-ui/react'
import { CustomBox } from '../CustomBox'

interface Props {
    url: string
    text: string
    titre: string
}

export const YouTubePanel = (props: Props) => {
    return (
        <CustomBox
            w={{
                base: '100%',
                md: 'calc(50% - 5px)',
            }}
            borderRadius={'15px'}
            padding={'10px'}
            display={'block'}
            textAlign={'center'}
            p={'10px'}
        >
            <Text as="b" marginBottom={'20px'}>
                {props.titre}
            </Text>
            <Text fontSize={'sm'} marginBottom={'10px'}>
                {props.text}
            </Text>
            <Box style={{ aspectRatio: '16/9' }} width="100%">
                <iframe
                    style={{ aspectRatio: '16/9', borderRadius: '15px' }}
                    width="100%"
                    src={props.url}
                    title="YouTube video player"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowFullScreen
                ></iframe>
            </Box>
        </CustomBox>
    )
}
