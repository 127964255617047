/*
 * Page principale d'un utilisateur client.
 * permet d'afficher ses vélos, ses accessoires et les tutoriels
 */
import { Container, Heading, Text, Wrap } from '@chakra-ui/react'
import { ConditionalConnect } from '../../auth/ConditionalConnect'
import { Login } from '../Authentification/Login'
import { useContext, useState, useEffect } from 'react'
import { AuthContext } from '../../auth/AuthContextProvider'
import { CategorieEquipement, Equipement } from '../../model'
import { GetMesEquipements } from '../../firebaseManager/Communicator/EquipementCommunicator'
import { YouTubePanel } from '../../components/YouTubePanel'
import { BoxCalendrier } from '../../components/CalendrierUsage/BoxCalendrier'
import {
    VeloPanel,
    VeloPanelEmpty,
    VeloPanelLoading,
} from '../../components/VeloPanel'
export const MyEquipments = () => {
    const authentification = useContext(AuthContext)
    const [mesVelos, setMesVelos] = useState<Equipement[] | null>()
    const [mesVelosLoaded, setMesVelosLoaded] = useState<boolean>(false)

    useEffect(() => {
        if (authentification.firebaseUser != null) {
            GetMesEquipements(authentification.firebaseUser.uid)
                .then((result) => {
                    setMesVelos(
                        result.filter(
                            (equipement) =>
                                equipement.categorie ===
                                CategorieEquipement.Velo
                        )
                    )
                    setMesVelosLoaded(true)
                })
                .catch((e) => {
                    console.log(e)
                    setMesVelos(null)
                    setMesVelosLoaded(true)
                })
        }
    }, [authentification])

    return (
        <>
            <ConditionalConnect showIfConnected>
                <Container maxW="container.md">
                    <Heading fontSize="3xl" mt={'20px'} mb={'10px'}>
                        Calendrier Vélotaf
                    </Heading>
                    <BoxCalendrier />

                    <Heading fontSize="3xl" mt={'20px'} mb={'10px'}>
                        {mesVelos && mesVelos?.length > 1 ? (
                            <> Mes vélos</>
                        ) : (
                            <>Mon vélo</>
                        )}
                    </Heading>

                    {!mesVelosLoaded && <VeloPanelLoading />}
                    {mesVelosLoaded && mesVelos?.length === 0 && (
                        <VeloPanelEmpty />
                    )}
                    {mesVelosLoaded && mesVelos && mesVelos?.length > 0 && (
                        <>
                            {mesVelos?.map(function (equipment, idx) {
                                return (
                                    <VeloPanel
                                        key={idx}
                                        equipement={equipment}
                                        withGraph
                                    />
                                )
                            })}
                        </>
                    )}

                    <Heading fontSize="3xl" mt={'20px'} mb={'10px'}>
                        Les bons réflexes entretien
                    </Heading>
                    <Text mt={'20px'} mb={'10px'}>
                        <b>LES BONNES PRATIQUES A ADOPTER 1 FOIS / MOIS :</b>
                    </Text>
                    <Text>
                        <ul>
                            <li>
                                Contrôlez la pression de vos pneus (en règle
                                générale entre 3 et 4 bars selon vélo). les
                                recommandations sont indiquées sur les pneus.
                            </li>
                            <li>Vérifiez vos serrages de roues.</li>
                            <li>
                                Dégraissez et lubrifiez votre chaine (si c’est
                                une courroie vous n’avez rien à faire…). Une
                                chaîne bien entretenue = passage de vitesses
                                plus facile
                            </li>
                        </ul>
                    </Text>
                    <Text mt={'20px'} mb={'10px'}>
                        <b>QUELQUES CONSEILS :</b>
                    </Text>
                    <Text>
                        Pour le nettoyage du vélo :
                        <ul>
                            <li>
                                évitez le jet à haute pression (usure prématurée
                                des pièces, roulements etc….).
                            </li>
                            <li>
                                Privilégiez de l’eau savonneuse, une brosse
                                douce et rincez à l’eau (tuyau d’arrosage).
                            </li>
                            <li>Séchez et lubrifiez ensuite votre chaîne.</li>
                        </ul>
                    </Text>
                    <Text mt={'20px'}>
                        Pour un VAE :
                        <ul>
                            <li>
                                Evitez les températures extrêmes afin de
                                prolonger la durée de vie de la batterie.
                            </li>
                            <li>
                                Stockez votre batterie à une température
                                ambiante.
                            </li>
                            <li>
                                La batterie est équipée d’un système de contrôle
                                de température qui ne permet de la recharger que
                                dans une plage de température comprise entre 0
                                °C et 40 °C.
                            </li>
                        </ul>
                    </Text>
                    <Text mt={'20px'}>
                        Tout savoir sur votre batterie BOSCH en suivant ce lien
                        :
                        <a
                            href="https://www.bosch-ebike.com/fr/service/informations-generales/guide-de-la-batterie"
                            target="_blank"
                            rel="noreferrer"
                        >
                            https://www.bosch-ebike.com/fr/service/informations-generales/guide-de-la-batterie
                        </a>
                    </Text>
                    <Wrap mt={'50px'}>
                        <YouTubePanel
                            titre="Entretenir sa chaîne de vélo"
                            text=""
                            url={
                                'https://www.youtube.com/embed/99v7oNMAXJ8?si=3IB_PHW28ZLw7Cet'
                            }
                        />
                        <YouTubePanel
                            titre="Vérifier la pression de  pneus"
                            text=""
                            url={
                                'https://www.youtube.com/embed/EcwP9vDwO2g?si=WI2WIYy-UxlGxuAr'
                            }
                        />

                        <YouTubePanel
                            titre="Démonter et remonter une roue"
                            text=""
                            url={
                                'https://www.youtube.com/embed/4TVzffIs7pk?si=gM4E-qaHbtP2pdY2'
                            }
                        />

                        <YouTubePanel
                            titre="Remplacer une chambre à air"
                            text=""
                            url={
                                'https://www.youtube.com/embed/sJf9HRqsItw?si=N_lqazceK_eFFM6a'
                            }
                        />
                    </Wrap>
                </Container>
            </ConditionalConnect>
            <ConditionalConnect showIfNotConnected>
                <Login />
            </ConditionalConnect>
        </>
    )
}

/** 
 
import {
    AccessoirePanel,
    AccessoirePanelEmpty,
    AccessoirePanelLoading,
} from '../../components/AccessoirePanel'



// Affichage accessoires
const [mesAccessoires, setMesAccessoires] = useState<Equipement[] | null>()
const [mesAccessoiresLoaded, setMesAccessoiresLoaded] =
    useState<boolean>(false) 

// Affichage accessoire
setMesAccessoires(
    result.filter(
        (equipement) =>
            equipement.categorie !==
            CategorieEquipement.Velo
    )
)
setMesAccessoiresLoaded(true)

// Affichage accessoire
setMesAccessoires(null)
setMesAccessoiresLoaded(true)
// Affichage accessoires
<Heading fontSize="3xl" mt={'20px'} mb={'10px'}>
    Mes accessoires
</Heading>

{!mesAccessoiresLoaded && <AccessoirePanelLoading />}
{mesAccessoiresLoaded && mesAccessoires?.length === 0 && (
    <AccessoirePanelEmpty />
)}
{mesAccessoiresLoaded &&
    mesAccessoires &&
    mesAccessoires?.length > 0 && (
        <Wrap>
            {mesAccessoires?.map(function (equipment, idx) {
                return (
                    <AccessoirePanel
                        key={idx}
                        equipement={equipment}
                    />
                )
            })}
        </Wrap>
    )}

*/
