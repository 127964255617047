/*
 * Page dédié a la recréation de mot de passe, a partir d'un lien mail de réinitialisation
 */
import { getAuth, verifyPasswordResetCode } from 'firebase/auth'
import {
    Flex,
    Heading,
    Box,
    Center,
    Text,
    Stack,
    Link as ChakraLink,
    Image,
    Spinner,
} from '@chakra-ui/react'
import { Button } from '../../../components/Button'
import { Link, useSearchParams } from 'react-router-dom'
import { useState } from 'react'
import { UpdatePasswordClient } from '../../../components/Formulaire/UpdatePasswordClient'
import { Link as ReactRouterLink } from 'react-router-dom'

export type MailValue = {
    email: string
}

export const ActionFirebase = () => {
    const auth = getAuth()
    const [searchParams] = useSearchParams()
    const actionOobCode: string = searchParams.get('oobCode')!

    const [codeChecked, setCodeChecked] = useState<boolean>(false)
    const [codeError, setCodeError] = useState<boolean>(true)
    const [error, setErrorMessage] = useState<string | null>(null)

    verifyPasswordResetCode(auth, actionOobCode)
        .then((mail) => {
            console.log('mail a reset = ' + mail)
            setCodeError(false)
            setCodeChecked(true)
        })
        .catch((error) => {
            console.log('Erreur rencontrée: ' + error.message)
            setCodeChecked(true)
            setErrorMessage(error.message)
            setCodeError(true)
        })

    return (
        <>
            <Stack minH={'80vh'} direction={{ base: 'column', md: 'row' }}>
                <Flex p={8} flex={1} align={'center'} justify={'center'}>
                    <Box
                        backgroundColor={'white'}
                        p={'70px'}
                        borderRadius={'40px'}
                    >
                        <Stack spacing={4} w={'full'} maxW={'md'}>
                            <Center>
                                <ChakraLink href="https://www.fenxmobility.com/">
                                    <Image src="logo.jpg" mb={'60px'} />
                                </ChakraLink>
                            </Center>
                            <Center>
                                <Heading fontSize={'2xl'}>
                                    Définition de votre mot de passe
                                </Heading>
                            </Center>

                            {!codeChecked && (
                                <>
                                    <Center mt={'15px'} mb={'15px'}>
                                        <Spinner
                                            thickness="4px"
                                            speed="0.65s"
                                            emptyColor="gray.200"
                                            color="blue.500"
                                            size="xl"
                                        />
                                    </Center>
                                    <Center>
                                        <Text>
                                            Vérification avant recréation mots
                                            de passe
                                        </Text>
                                    </Center>
                                </>
                            )}
                            {codeChecked && codeError && (
                                <>
                                    <Center>
                                        <Text color={'red'}>
                                            Une erreur a été rencontré <br></br>
                                            {error}
                                        </Text>
                                    </Center>
                                    <Center>
                                        <Text>
                                            Veuillez réaliser une nouvelle
                                            demande de réinitialisation.
                                        </Text>
                                    </Center>
                                    <Center mb={'30px'}>
                                        <Button
                                            as={ReactRouterLink}
                                            to={'/reset_password'}
                                        >
                                            Demander réinitialisation
                                        </Button>
                                    </Center>
                                </>
                            )}
                            {codeChecked && !codeError && (
                                <UpdatePasswordClient withOOB={actionOobCode} />
                            )}

                            <Stack spacing={6}>
                                <Stack
                                    direction={{
                                        base: 'column',
                                        sm: 'row',
                                    }}
                                    align={'start'}
                                    justify={'space-between'}
                                >
                                    <Link to="/login">
                                        <Text color={'blue.500'}>
                                            J'ai déja un compte
                                        </Text>
                                    </Link>
                                </Stack>
                            </Stack>
                        </Stack>
                    </Box>
                </Flex>
            </Stack>
        </>
    )
}
