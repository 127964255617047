/*
 * Page dédiée a l'affichages des informations de compte de utilisateur connecté
 */
import { Center, Container, Text, Wrap } from '@chakra-ui/react'
import { Link as ReactRouterLink } from 'react-router-dom'
import { ConditionalConnect } from '../../auth/ConditionalConnect'
import { Login } from '../Authentification/Login'
import { UtilisateurContext } from '../../auth/UtilisateurContextProvider'
import { useContext } from 'react'
import { AgenceContext } from '../../auth/AgenceContextProvider'
import { EntrepriseContext } from '../../auth/EntrepriseContextProvider'
import { CustomBox } from '../../components/CustomBox'
import { Button } from '../../components/Button'
import { UpdatePasswordClient } from '../../components/Formulaire/UpdatePasswordClient'
import { ShowRole } from '../../utils/helpers'
import { ShowRoleFilter } from '../../auth/ShowRoleFilter'

export const MonCompte = () => {
    const utilisateur = useContext(UtilisateurContext)
    const agence = useContext(AgenceContext)
    const entreprise = useContext(EntrepriseContext)

    return (
        <>
            <ConditionalConnect showIfConnected>
                <Container maxW="container.md">
                    <CustomBox mt={'30px'}>
                        <Text as="b">Information compte </Text>
                        <Text>
                            <b>Nom</b> : {utilisateur?.nom}
                        </Text>
                        <Text>
                            <b>Prenom</b> : {utilisateur?.prenom}
                        </Text>
                        <Text>
                            <b>Rôle</b> : {ShowRole(utilisateur?.role)}
                        </Text>
                        <Text>
                            <b>Adresse Email</b> : {utilisateur?.mailContact}
                        </Text>
                        <Text>
                            <b>Téléphone</b> : {utilisateur?.telContact}
                        </Text>
                    </CustomBox>
                    <ShowRoleFilter forUser>
                        <Wrap spacing={0}>
                            <CustomBox
                                mt={'10px'}
                                mr={{
                                    base: '0px',
                                    md: '5px',
                                }}
                                w={{
                                    base: '100%',
                                    md: 'calc(50% - 5px)',
                                }}
                            >
                                <Text as="b">Information entreprise </Text>
                                <Text>
                                    <b>Nom</b> : {entreprise?.nom}
                                </Text>
                                <Text>
                                    <b>Adresse Email</b> :{' '}
                                    {entreprise?.mailContact}
                                </Text>
                                <Text>
                                    <b>Téléphone</b> : {entreprise?.telContact}
                                </Text>
                                <Text>
                                    <b>Type de contrat</b> :{' '}
                                    {entreprise?.typeContrat}
                                </Text>
                                <Text>
                                    <b>Numéro de contrat</b> :{' '}
                                    {entreprise?.noContrat}
                                </Text>
                            </CustomBox>
                            <CustomBox
                                mt={'10px'}
                                ml={{
                                    base: '0px',
                                    md: '5px',
                                }}
                                w={{
                                    base: '100%',
                                    md: 'calc(50% - 5px)',
                                }}
                            >
                                <Text as="b">Information agence </Text>
                                <Text>
                                    <b>Nom </b>: {agence?.nom}
                                </Text>
                                <Text>
                                    <b>Adresse Email </b>: {agence?.mailContact}
                                </Text>
                                <Text>
                                    <b>Téléphone </b>: {agence?.telContact}
                                </Text>
                            </CustomBox>
                        </Wrap>
                    </ShowRoleFilter>
                    <CustomBox mt={'10px'}>
                        <Text as="b">Modification mon mot de passe</Text>
                        <UpdatePasswordClient />
                    </CustomBox>
                    <Center mt={'30px'}>
                        <Button
                            as={ReactRouterLink}
                            to={'/logout'}
                            backgroundColor={'grey.900'}
                        >
                            Deconnexion
                        </Button>
                    </Center>
                </Container>
            </ConditionalConnect>
            <ConditionalConnect showIfNotConnected>
                <Login />
            </ConditionalConnect>
        </>
    )
}
