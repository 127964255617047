/*
 * Page dédiée au listing des entreprises
 */
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    useDisclosure,
    ModalBody,
    Container,
    ModalCloseButton,
    Box,
    Text,
    Flex,
    Center,
    Spinner,
} from '@chakra-ui/react'
import { Link as ReactRouterLink } from 'react-router-dom'

import { Entreprise, TypeContrat } from '../../model'
import { GetAllEntreprises } from '../../firebaseManager/Communicator/EntrepriseCommunicator'
import { Button } from '../../components/Button'
import { ButtonToolTip } from '../../components/ButtonToolTip'
import { useContext, useState, useEffect } from 'react'
import { AuthContext } from '../../auth/AuthContextProvider'
import { AddIcon, ViewIcon, PhoneIcon, EmailIcon } from '@chakra-ui/icons'
import { BreadCrumbBuilder } from '../../components/BreadCrumbBuilder'
import { CreationEditionEntreprise } from '../../components/Formulaire/CreationEditionEntreprise'
import { ConditionalConnectAdminComplex } from '../../auth/ConditionalConnectAdminComplex'
import { SortEntreprise } from '../../utils/helpers'
import { CustomBox } from '../../components/CustomBox'
import { ModalSuppressionEntreprise } from '../../components/ModalSuppression'
import { ExportActionButton } from '../../components/ExportActionButton'

export const ManageEnterprises = () => {
    const [allEntreprises, setAllEntreprises] = useState<Entreprise[] | null>()

    const {
        isOpen: isOpenModalAddEntreprise,
        onOpen: onOpenModalAddEntreprise,
        onClose: onCloseModalAddEntreprise,
    } = useDisclosure()

    const authentification = useContext(AuthContext)

    function refreshAllEntreprises() {
        GetAllEntreprises()
            .then((result) => {
                setAllEntreprises(SortEntreprise(result))
            })
            .catch((e) => setAllEntreprises(null))
    }

    useEffect(() => {
        if (authentification.firebaseUser != null) {
            refreshAllEntreprises()
        }
    }, [authentification])

    return (
        <ConditionalConnectAdminComplex>
            <BreadCrumbBuilder
                items={[
                    {
                        name: 'Entreprises',
                        url: '',
                    },
                ]}
            />
            <ExportActionButton exportAllVelo />

            <Container maxW="container.lg">
                <Button onClick={onOpenModalAddEntreprise} p={4} mb={'30px'}>
                    <AddIcon /> Créer une Entreprise
                </Button>
                {/**<Button
                    onClick={(e) => CallFunctionAggregateCalendrierStats()}
                    p={4}
                    mb={'30px'}
                    ml={'30px'}
                >
                    Refresh statistique usage
                </Button>*/}

                {allEntreprises == null && (
                    <CustomBox p={'40px'} ml={'50px'} mr={'50px'}>
                        <Center>
                            <Spinner
                                thickness="4px"
                                speed="0.65s"
                                emptyColor="gray.200"
                                color="blue.500"
                                size="xl"
                            />
                        </Center>
                        <Center>Chargement des entreprises</Center>
                    </CustomBox>
                )}
                {allEntreprises && allEntreprises?.length === 0 && (
                    <CustomBox p={'40px'} ml={'50px'} mr={'50px'}>
                        <Center>Il n'existe pas encore d'entreprise</Center>
                    </CustomBox>
                )}
                {allEntreprises && allEntreprises?.length > 0 && (
                    <>
                        {allEntreprises?.map(function (entreprise, idx) {
                            return (
                                <CustomBox
                                    pl={'25px'}
                                    pr={'25px'}
                                    pt={'10px'}
                                    pb={'10px'}
                                    h={'68px'}
                                    ml={'50px'}
                                    mr={'50px'}
                                    mb={'10px'}
                                    key={idx}
                                >
                                    <Flex>
                                        <Box
                                            mt={'0px'}
                                            width={'calc( 100% - 190px )'}
                                        >
                                            <Text as="b" fontSize="lg">
                                                {entreprise.nom}
                                            </Text>
                                            <br></br>
                                            <Text fontSize="sm">
                                                Type :{' '}
                                                {entreprise.typeContrat ===
                                                TypeContrat.Achat ? (
                                                    <>Achat</>
                                                ) : entreprise.typeContrat ===
                                                  TypeContrat.Leasing ? (
                                                    <>Leasing</>
                                                ) : (
                                                    <>
                                                        {entreprise.typeContrat}
                                                    </>
                                                )}
                                            </Text>
                                        </Box>

                                        <Box w={'190px'}>
                                            <ButtonToolTip
                                                label={entreprise.mailContact!}
                                                textToCopy={
                                                    entreprise.mailContact!
                                                }
                                            >
                                                <EmailIcon />
                                            </ButtonToolTip>

                                            <ButtonToolTip
                                                label={entreprise.telContact!}
                                                textToCopy={
                                                    entreprise.telContact!
                                                }
                                            >
                                                <PhoneIcon />
                                            </ButtonToolTip>

                                            <Button
                                                colorScheme="blue"
                                                p={0}
                                                as={ReactRouterLink}
                                                to={
                                                    '/edit_enterprise?id_entreprise=' +
                                                    entreprise.id
                                                }
                                            >
                                                <ViewIcon />
                                            </Button>
                                            <ModalSuppressionEntreprise
                                                targetId={entreprise.id}
                                                nomEntite={entreprise.nom}
                                                onSuccess={() =>
                                                    refreshAllEntreprises()
                                                }
                                            />
                                        </Box>
                                    </Flex>
                                </CustomBox>
                            )
                        })}
                    </>
                )}
            </Container>

            <Modal
                isOpen={isOpenModalAddEntreprise}
                onClose={onCloseModalAddEntreprise}
                size={'xl'}
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Créer une Entreprise</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <CreationEditionEntreprise
                            onSucess={() => {
                                onCloseModalAddEntreprise()
                                refreshAllEntreprises()
                            }}
                        />
                    </ModalBody>
                </ModalContent>
            </Modal>
        </ConditionalConnectAdminComplex>
    )
}
